import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import { formatEventDate } from "../utility/DateUtils"
import EventModal from "./EventModal"
import EventCloneModal from "./modals/EventCloneModal"
import moment from 'moment'
import Cookies from "universal-cookie";
import { isHoldPacked } from "../utility/HoldUtils"
import { isJaguar, isSuperadmin, isClient } from "../utility/UserUtils"

class EventsDashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      events: props.events || [],
      sortBy: "start_date",
      showArchived: false
    }

    this.setSortBy = this.setSortBy.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.addEvent = this.addEvent.bind(this);
    this.toggleArchivedEvents = this.toggleArchivedEvents.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
  }


  renderActionHeaders() {
    const { user } = this.props;
    if(!isBreakpoint('xs') && isJaguar(user)){
      return (
        <React.Fragment>
          <th className="action hidden-xs first-action"></th>
          <th className="action hidden-xs"></th>
          <th className="action hidden-xs"></th>
        </React.Fragment>
      );
    }
  }

  renderActions(event) {
    const { user } = this.props;
    if(!isBreakpoint('xs') && isJaguar(user)){
      return (
        <React.Fragment>
          <td className="action hidden-xs first-action">
          <EventModal
              button={(
                <a href="#" className="">Edit Event</a>
                )}
              renters={this.props.renters}
              updateEvent={this.updateEvent}
              event={event}
              shipping_addresses={this.props.shipping_addresses}
            />
          </td>
          <td className="action hidden-xs">
            <EventCloneModal
              button={(
                <a href="#" className="">Clone Event</a>
              )}
              event={event}
              shipping_addresses={this.props.shipping_addresses}
            />
          </td>
          <td className="action hidden-xs">
            <a onClick={() => this.archiveEvent(event)} href="#">
              {event.archived_at ? "Unarchive" : "Archive" }
            </a>
          </td>
          <td className="action hidden-xs">
            <a onClick={() => this.deleteEvent(event)} href="#">
              Delete
            </a>
          </td>
        </React.Fragment>
      )
    }
  }


  sortedEvents() {
    const { events, sortBy } = this.state;
    return events.sort((a,b) => {
      if(sortBy == "name"){
        const sortA = a["name"];
        const sortB = b["name"];
        if(sortA < sortB) { return -1; }
        if(sortA > sortB) { return 1; }
        return 0;
      }
      else {
        const sortA = moment(a[sortBy]);
        const sortB = moment(b[sortBy]);

        return sortBy == "created_at" ?  (sortB - sortA) : (sortA - sortB);
      }
    });
  }

  setSortBy(sortBy) {
    this.setState({
      sortBy
    });
  }

  getActiveSortByClass(sort) {
    const { sortBy } = this.state;
    if(sort == sortBy) {
      return "active"
    }
  }

  addEvent(event){
    this.setState({
      events: this.state.events.concat([event])
    })
  }

  updateEvent(event){
    this.setState({
      events: this.state.events.filter(event2 => event2.id != event.id).concat([event])
    });
  }

  renderEventRow(event) {
    return (
      <React.Fragment>
        <tr key={`event-${event.id}`}>
          <td className="event-name"><a href={`/events/${event.slug}`}>{event.name}</a></td>
          <td className="date">{formatEventDate(event)}</td>
          <td className="reserved"> {Math.max(event.total_item_count-event.total_packed_count, 0)} </td>
          <td className="packed"> {event.total_packed_count} </td>
          {this.renderActions(event)}
        </tr>
        <tr  key={`buffer-${event.id}`}>
          <td className="buffer-row" colSpan={4} >&nbsp;</td>
        </tr>
      </React.Fragment>
    )
  }

  archiveEvent(event) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/events/${event.slug}/archive`, {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify({archive: !event.archived_at}),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.updateEvent(json.event);
        } else {
          alert(json.error);
        }
      });
  }

  deleteEvent(event) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(confirm("Are you sure you want to delete this event? This cannot be undone.")){
      fetch(`/events/${event.slug}`, {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            this.setState({
              events: this.state.events.filter(event2 => event.id != event2.id)
            })
          } else {
            alert(json.error);
          }
        });
    }
  }

  renderEventsTable(events) {
    const rows = events.map( event => (
      this.renderEventRow(event)
    ));
    return (
      <table className="events-dashboard-table">
        <thead>
          <tr>
            <th className="event-name-header">Event Name</th>
            <th className="address-header">Event Date</th>
            <th className="small-header">Reserved</th>
            <th className="small-header">Shipped</th>
            {this.renderActionHeaders()}
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  toggleArchivedEvents() {
    this.setState({
      showArchived: !this.state.showArchived
    })
  }

  renderArchivedEvents() {
    const { showArchived } = this.state;
    const table = showArchived ? this.renderEventsTable(this.sortedEvents().filter(e=> e.archived_at)) : "";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12 archive-events-header" onClick={this.toggleArchivedEvents}>
            Archive&nbsp;&nbsp;
            {showArchived ?
              (<i class="fas fa-sort-down" />)
              :
              (<i class="fas fa-sort-down archive-arrow-rotated" />)
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {table}
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { children, user } = this.props;
    return (
      <PublicCatalogLayout currentPage="events" {...this.props}  classNames={{container: 'full-body-container '}} className="events-dashboard">
        <div className="events-dashboard">
          <br />
          <br />
          <div className="row">
            <div className="col-xs-12">
              <h1> Events </h1>
            </div>
            {isJaguar(user) ? (
              <div className="col-xs-12 col-sm-6">
                <EventModal
                  button={(
                    <button className="event-dashboard-add-event">Add Event</button>
                    )}
                  renters={this.props.renters}
                  addEvent={this.addEvent}
                  shipping_addresses={this.props.shipping_addresses}
                />
              </div>
            ):""}

            <div className="col-xs-12 col-sm-6 event-dashboard-table-sort-list-container">
              <span style={{float: "right"}} className="event-dashboard-table-sort-list">
                Sort By:
                <a href="#" className={this.getActiveSortByClass("start_date")} onClick={() => this.setSortBy("start_date")}>
                  Date
                </a>
                |
                <a href="#" className={this.getActiveSortByClass("name")}  onClick={() => this.setSortBy("name")}>
                  Name
                </a>
                |
                <a href="#" className={this.getActiveSortByClass("created_at")} onClick={() => this.setSortBy("created_at")}>
                  Recently Added
                </a>
              </span>
            </div>
          </div>
          <br />
          <br />
          {this.renderEventsTable(this.sortedEvents().filter(e=> !e.archived_at))}
          <hr />
          {this.renderArchivedEvents()}
        </div>
      </PublicCatalogLayout>
    );
  }
}

EventsDashboard.propTypes = {

};

export default EventsDashboard;
