import React from "react";
import PropTypes from "prop-types";
import { isJaguar, isSuperadmin, isClient } from "../utility/UserUtils"

class CategorySubheader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  renderCategories() {
    const { categories, group, category } = this.props;
    return categories.map(c => (
      <a
        key={`category-${c.name}-${c.id}`}
        className={c.name == category ? "active category-link" : "category-link"}
        href={`/category/${escape(c.group_name).replace("/","%2F") || "Other"}/${escape(c.name).replace("/","%2F")}`}
      >
        {c.name}
      </a>
    ));
  }

  renderRightContent(){
    const {rightAlignedContent} = this.props;
    if(rightAlignedContent){
      return (
        rightAlignedContent
      )
    }
  }

  render() {
    const { group, category, user } = this.props;
    return (
      <div className="category-subheader full-body-container">
        <a
          href={`/category/${group}`}
          className={category == null ? "active category-link" : "category-link"}
        >
          All {isClient(user) ? group : ""}
        </a>
        {this.renderCategories()}
        {this.renderRightContent()}
      </div>
    );
  }
}

CategorySubheader.propTypes = {
  category: PropTypes.object.optional,
  categories: PropTypes.array.isRequired,
};

export default CategorySubheader;
