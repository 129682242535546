import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Select from 'react-select'
import Cookies from "universal-cookie";
import { isClient, isJaguar } from "../../utility/UserUtils"

class ItemMessageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      messageSent: false,
    }
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  renderForm(formikProps) {
    const { item, size } = this.props;
    const small = size == "small";
    return(
      <Form className=""> 
        <div className="row">
          <div className="col-xs-12">
            Your Message
            <p />  
          </div>
          {this.renderEmailField()}
          <div className="col-xs-12">
            <Field  style={{color: "black"}}  name="warehouse_notification[message]" className="form-control item-message-component-textarea" component="textarea" placeholder="Questions? Please send us a message and let us know."/>
            <br/>
          </div>
          <div className={small ? "col-xs-6" : "col-xs-6 col-sm-4"} style={{paddingRight: "5px"}}> 
            {this.renderSubmitButton(formikProps)}
          </div>
          <div className={small ? "col-xs-6" : "col-xs-6 col-sm-4"} style={{paddingLeft: "5px"}}> 
            {this.renderCancelButton(formikProps)}
          </div>
        </div>
      </Form>
    )
  }

  validateEmail(email)  {
    if (/^\w+([\.-]?\warehouse+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
      return (true)
    }
    return (false)
  }

  renderEmailField(){
    const {user} = this.props;
    if(!user.id){
      return (
       <div className="col-xs-12">
          <Field style={{color: "black"}} name="warehouse_notification[email]" className="form-control" placeholder="Email Address"/>
          <br />
        </div>
      )
    }
  }

  closeMenu(){
    const { onClose } = this.props;
    if(onClose){
      onClose();
    }
    this.setState({
      menuOpen: false,
      messageSent: false,
    })
  }

  openMenu(){
    const { onOpen } = this.props;
    if(onOpen){
      onOpen();
    }
    this.setState({
      menuOpen: true,
      messageSent: false,
    })
  }

  renderCancelButton(){
    return (
      <button className="item-hold-form-cancel-button" type="button" onClick={this.props.onCancel || this.closeMenu}>
        Cancel
      </button>
    )
  }
  renderSubmitButton(formikProps){
    const { user } = this.props;
    return (
      <button
        className="item-hold-form-submit-button"
        disabled={(!user.id && !this.validateEmail(formikProps.values['warehouse_notification']['email'])) || !formikProps.dirty}
        type="submit"
      >
        Send
      </button>
    )
  }


  formMethod() {
    return 'POST'
  }

  formURL() {
    const { item_hold,event,item } = this.props;
    if(item_hold){
      return `/events/${event.slug}/item_holds/${item_hold.id}/notify`
    }else if(item){
      return `/items/${item.id}/notify`
    }
    return `/events/${event.slug}/notify`
  }

  render() {
    const { size } = this.props;
    const small = size == "small";
    if (this.state.messageSent){
      return (
        <div className="row">
          <div className="col-xs-12">
            Thank you. Your message has been sent. Our warehouse staff will be notified immediately.
            <br />
            <br />
          </div>
        </div>
      );
    }
    if(this.state.menuOpen || !small){
      return (
        <Formik
          initialValues={{
            warehouse_notification :{
              message: "",
              email: "",
            }
          }}
          onSubmit={(values, actions) => {
            const cookies = new Cookies();
            const token = cookies.get("X-CSRF-Token");
            fetch(this.formURL(), {
              method: this.formMethod(),
              redirect: "manual",
              body: JSON.stringify(values),
              headers: {
                "X-CSRF-Token": token,
                "Content-Type": 'application/json'
              }
            })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.setState({
                  messageSent:true
                })
              } else {
                alert(json.error);
              }
            });
          }}
          render={(formikProps) => (
            this.renderForm(formikProps)
        )}/>
      );
    }
    return (
      <div className="row">
        {small ? <br /> : ""}
        <div className={small ? "col-xs-12" : "col-xs-12 col-sm-6"}>
          <button
            className="item-hold-form-submit-button"
            onClick={this.openMenu}
          >
          Send Message 
          </button>
        </div>
      </div>
    )
  }
}

ItemMessageComponent.propTypes = {
  item: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default ItemMessageComponent;
