import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import { isJaguar } from "../utility/UserUtils";

class InventoryManagementDashboard extends React.Component {

  constructor(props) {
    super(props);
  }

  renderInventories(){
    const { renters } = this.props;
    return renters.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .map(inventory =>(
      <tr>
        <td>
          <a href={`/inventory/${inventory.id}`}>
            {inventory.name}
          </a>
        </td>
        <td>{this.getFullSquareFootage(inventory)}</td>
        {isJaguar(this.props.user) ? <td> <a href={`/warehouse/reports/renter_full_billing?id=${inventory.id}`} target="_blank" className="">.xls Report</a> </td> : ""}
        <td>${Number(inventory.monthly_cost||0).toFixed(2)}</td>
        <td>${this.getRentersFullPrice(inventory)}</td>
      </tr>
    ))
  }


  getRentersFullPrice(renter){
    const price = Number(renter.monthly_cost||0);
    return (price * 12).toFixed(2);
  }



  renderHeaderRow() {
    return (
      <tr className="users-users-table-header-row">
        <th> Name </th>
        <th> Cubic Footage Volume (to nearest cuft)</th>
        {isJaguar(this.props.user) ? <th> Report </th> : ""}
        <th> Monthly Storage Cost </th>
        <th> Annual Storage Cost </th>
        
      </tr>
    );
  }

  getFullSquareFootage(renter){
    const size = Number(renter.stored_volume||0);
    return Math.round(size/1728)
  }

  render() {
    return (
      <PublicCatalogLayout
        classNames={{container: 'full-body-container inventory-dashboard'}}
        className="inventory-management-dashboard"
        currentPage=""
        {...this.props}
      >
        <div className="row">
          <div className="col-xs-12">
            <h1>Inventories</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="table-responsive">
              <table className=" table">
                <thead>
                  {this.renderHeaderRow()}
                </thead>
                <tbody>
                  {this.renderInventories()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </PublicCatalogLayout>
    );
  }
}

InventoryManagementDashboard.propTypes = {

};

export default InventoryManagementDashboard;
