import React, { Component } from 'react'
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material"
import {  } from '@mui/base';
import Cookies from "universal-cookie";
import ReceiveItemModal from '../modals/ReceiveItemModal'
import { isJaguar, isSuperadmin, isClient } from "../../utility/UserUtils"

export default class OnsiteTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedIn: props.item.checkedIn,
      collected: props.item.collected,
      postEventNotes: props.item.postEventNotes,
      warehouseReceived: props.item.warehouseReceived,
    }
    this.timer = null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.fieldsDidChange(prevProps, prevState)) {
      this.handleChange()
    }
  }
  
  fieldsDidChange(prevProps, prevState) {
    if (prevState.checkedIn !== this.state.checkedIn) {
      return true
    }
    if (prevState.collected !== this.state.collected) {
      return true
    }
    if (prevState.postEventNotes !== this.state.postEventNotes) {
      return true
    }
    return false
  }

  generateLocationText(locations) {
    let text = ""
    locations.forEach(x => {
      text += `(${x.quantity}) ${x.location.name}; `
    })
    return text
  }

  handleChange() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const {item, event} = this.props;
      const {checkedIn, collected, postEventNotes} = this.state
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/events/${event.id}/event_item_fields/${item.eventItemFieldsId}`, {
        method: "PATCH",
        redirect: "manual",
        body: JSON.stringify({
          checked_in: checkedIn,
          collected: collected,
          post_event_notes: postEventNotes,
          item_id: item.id,
        }),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
    }, 2200)
  }

  render() {
    const {item, event, cellStyles} = this.props
    const {checkedIn, collected, postEventNotes, warehouseReceived} = this.state
    return (
      <TableRow>
        <TableCell sx={cellStyles}><img src={item.image_url} style={{width: "60px"}}/></TableCell>
        <TableCell sx={cellStyles}>{item.category}</TableCell>
        <TableCell sx={cellStyles}>{item.name}</TableCell>
        <TableCell sx={cellStyles}>{item.quantity}</TableCell>
        <TableCell sx={cellStyles}>
          {item.infoNotes.join("\n")}
        </TableCell>
        <TableCell>
          <TextField 
            sx={{...cellStyles, width: "75px"}}
            type="number" 
            value={checkedIn} 
            onChange={(e) => this.setState({checkedIn: e.target.value})}
            inputProps={{
              min: 0,
              style: cellStyles
            }}
          />
        </TableCell>
        <TableCell>
          <TextField 
            sx={{...cellStyles, width: "75px"}}
            type="number" 
            value={collected} 
            onChange={(e) => this.setState({collected: e.target.value})}
            inputProps={{
              min: 0,
              style: cellStyles
            }}
          />
        </TableCell>
        <TableCell sx={cellStyles}>
          <TextField 
          sx={cellStyles}
          value={postEventNotes} 
          onChange={(e) => this.setState({postEventNotes: e.target.value})}
          multiline
          fullWidth
          inputProps={{
            min: 0,
            style: cellStyles
          }}
          />
        </TableCell>
        <TableCell sx={{textAlign: "center"}}>
          {
            warehouseReceived === null ?
            <ReceiveItemModal 
              item={item}
              event={event}
              collected={collected}
              setWarehouseReceived={(x) => this.setState({warehouseReceived: x}).bind(this)}
              accessButton={
                <Button
                  variant="contained"
                  sx={{fontSize: "13px", backgroundColor: "rgb(33, 85, 21)"}}
                  disabled={isClient(this.props.user)}
                >
                  Receive
                </Button>
              }
            />
            :
            <div style={{fontSize: "13px"}}>{warehouseReceived}</div>
          }
        </TableCell>
      </TableRow>
    )
  }
}
