import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import EventSubheader from "./partials/EventSubheader"
import EventSortedItemsContainer from "./partials/EventSortedItemsContainer"
class EventShowPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PublicCatalogLayout
        currentPage="events" {...this.props}
        classNames={{container: 'wider-container'}}
        subHeader={
          <EventSubheader {...this.props}/>
        }
      >
        <EventSortedItemsContainer {...this.props}/>
      </PublicCatalogLayout> 
    );
  }
}

EventShowPage.propTypes = {
  event: PropTypes.object.optional,
};

export default EventShowPage;
