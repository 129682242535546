import React, { useState, useEffect } from "react";
import PublicCatalogLayout from "@/layouts/PublicCatalogLayout"
import { fetchAPI } from "@/utility/NetworkUtils";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
import DataGridTableToolbar from "@/utility/DataGridTableToolbar";
import { Link, Button } from "@mui/material";

const InventoryItemsDashboard = ({
  renter,
  
  user
}) => {
  const [ items, setItems ] = useState([]);

  const fetchItems = async () => {
    fetchAPI(`/webapi/renters/${renter ? renter.id : "jaguar"}/items`, (json)=> {
      if (!json.errors) {
        setItems(json.items)
      } else {
        console.log({
          errors: json.errors
        })
      }
    })
  }

  useEffect(() => {
    fetchItems()
  }, [])

  const getItems = () => {
    return items.map(x=> {
      x.category_name = (x.category||{}).name || "Other";
      x.group_name = (x.category||{}).group_name || "Misc";
      x.image_url = x.active_image ? x.active_image.thumbnail_url : "";
      return x;
    })
  }

  const getColumns = () => {
    return [
      {field: "image_url", headerName: "Image", renderCell: (params) => (params.value ? <img src={params.value} style={{width: "60px", height: "60px"}}/> : "")},
      {field: "name", flex: 1, headerName: "Name"},
      {field: "group_name", flex: 1, headerName: "Category"},
      {field: "category_name", flex: 1, headerName: "Sub Category"},
      {field: "owned", flex: 1, headerName: "Owned"},
      {field: "Actions", sorting: false, filtering: false, width: 250, headerName: "Actions", renderCell: renderActions},
    ];
  }

  const renderActions = (params) => {
    return (
      <>
        <Button size="small" variant="contained" color="secondary" href={`/items/${params.row.id}`}>View</Button>
        <Button size="small" variant="contained" color="primary" href={`/inventory/${renter.id}/items/${params.row.id}/edit`}>Edit</Button>
        <Button onClick={()=>deleteItem(params.row.id)} size="small" variant="contained" color="error">Delete</Button>
      </>
    )
  }

  const deleteItem = (id) => {
    if(confirm("Are you sure you want to delete this item? This cannot be undone.")) {
      fetchAPI(`/webapi/renters/${renter ? renter.id : "jaguar"}/items/${id}`, (json)=> {
        if (!json.errors) {
          fetchItems()
        } else {
          alert(json.errors.join("\n"));
        }
      }, {
        method: "DELETE",
      })
    }
  }


  const renderItemsTable = () => {
    return (
      <DataGridPremium
        autoHeight
        unstable_headerFilters
        rows={getItems()}
        columns={getColumns()}
        slots={{
          toolbar: DataGridTableToolbar
        }}
        style={{
          cell: {
            paddingBottom: 0,
            paddingRight: 0,
            whiteSpace: 'normal',
            overflow: 'visible',
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
      />
   )
  }
  return (
    <PublicCatalogLayout
      classNames={{container: 'full-body-container inventory-dashboard'}}
      className="inventory-dashboard"
      renter={renter}
      currentPage={"inventory-"+renter.id}
      user={user}
    >
      <div className="row">
        <div className="col-xs-12">
          <h1>{renter.name} Items </h1>
        </div>
        <div className="col-xs-12 mb-2">
          <Button variant="contained" color="primary" href={`/inventory/${renter.id}/items/new`}
          >Create New Item</Button>
          <br/>
          <br/>
        </div>
        <div className="col-xs-12">
          {renderItemsTable()}
        </div>
      </div>
    </PublicCatalogLayout>
  );
}

export default InventoryItemsDashboard