import React from "react";
import PropTypes from "prop-types";
import { isJaguar, isSuperadmin, isClient } from "../../utility/UserUtils"
import PublicCatalogNavbarSearchMenu from "./PublicCatalogNavbarSearchMenu"
import HoldMessageModal from "../../events/holds/HoldMessageModal"
import Cookies from 'universal-cookie'
import { fetchAPI } from "@/utility/NetworkUtils";
class PublicCatalogNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminMenuOpen: false, //TODO change this to false
      cartNumber: null,
      renters: null,
      groups: null
    }
    this.searchButtonRef = React.createRef();

    this.toggleAdminMenu = this.toggleAdminMenu.bind(this);
  }

  fetchGroups() {
    const { renter } = this.props;
    fetchAPI(`/webapi/renters/${renter ? renter.id : "jaguar"}/groups`, (json)=> {
      if (!json.errors) {
        this.setState({groups: json.groups})
      } else {
        console.log({
          errors: json.errors
        })
      }
    }, {});
  }

  fetchCart() {
    const {user} = this.props;
    fetchAPI(`/users/${user.id}/cart_item_quantity`, (json)=> {
      if (!json.errors) {
        this.setState({cartNumber: json.cart_item_quantity})
      } else {
        alert(json.errors);
      }
    }, {});
  }

  fetchRenters() {
    fetchAPI(`/webapi/renters`, (json)=> {
      if (!json.errors) {
        this.setState({renters: json.renters})
      } else {
        console.log({
          errors: json.errors
        })
      }
    }, {});
  }

  componentDidMount() {
    this.fetchCart();
    this.fetchGroups();
    const {user} = this.props;
    if(user && isClient(user)) {
      this.fetchRenters();
    }
  }

  renderUserInformation() {
    const { user } = this.props;
    if(user && user.id) {
      return (
        <div>
          {/* <HoldMessageModal button={<i class="far fa-envelope nav-bar-message-modal-icon" style={{color: 'white'}}></i>}/> */}
          {this.renderAdminMenu()}
          {user.first_name} {user.last_name}
          <a className="nav-link" rel="nofollow" href="/users/sign_out">
            (Sign Out)
          </a>
        </div>
      )
    }
    return (
      <a href="/users/sign_in">
        Sign In
      </a>
    );
  }

  renderAdminMenu() {
    const { adminMenuOpen } = this.state;
    const { user } = this.props;
    if(isJaguar(user)) {
      const menu = (
        <div onClick={(e) => e.stopPropagation()} className="navbar-admin-menu-container">
          <a href="/events/shipping_addresses">Manage Shipping Locations</a>
          <a href="/tags">Manage Tags</a>
          <a href="/users">Manage Users</a>
          <a href="/inventory">Client Inventories</a>
        </div>
      );
      return (
        <div className="navbar-admin-menu" onClick={(e)=>{e.stopPropagation();this.toggleAdminMenu()}}>
          <span className={`fas ${adminMenuOpen ? 'fa-caret-down' : 'fa-caret-right'}`}/>
          Admin
          {adminMenuOpen ? menu : ""}
        </div>
      )
    }
  }

  toggleAdminMenu(override = undefined) {
    this.setState({
      adminMenuOpen: override == undefined ? !this.state.adminMenuOpen : override
    })
  }

  renderWarehouseLink() {
    const { user } = this.props;
    if(isSuperadmin(user)) {
      return (
        <React.Fragment>
          <div className="divider">&nbsp;</div>
          <a href="/warehouse"><li> Warehouse </li></a>
        </React.Fragment>
      );
    }
  }

  generateGroupLinks() {
    const { user, currentGroup, renter} = this.props;
    const { groups } = this.state;

    const sortedGroups = (groups||[]).sort((a,b)=> this.getSortOrder(a) - this.getSortOrder(b));

    return sortedGroups.filter(x=>x).map(group=>
      <a href={renter ? `/inventory/${renter.id}/category/${group}` : `/category/${group}`}>
        <li
          className={currentGroup == group ? "active" : ""}
        >
          {group}
        </li>
      </a>
    )
  }

  renderEventsLink() {
    const { events, user } = this.props;
    return (
      <div className="navbar-button-anchor-container">
        <a href="/events" className="navbar-events-button-anchor">
          <button className="navbar-events-button">
            Events
          </button>
        </a>
      </div>
    );
  }

  renderOrdersLink() {
    const { events, user } = this.props;
    if (user.id !== null) {
      return (
        <div className="navbar-button-anchor-container">
          <a href={`/orders?user_id=${user.id}`} className="navbar-events-button-anchor">
            <button className="navbar-orders-button">
              Orders
            </button>
          </a>
        </div>
      );
    }
  }

  renderShoppingCart() {
    const {cartNumber} = this.state;
    const {user} = this.props;
    if (user.id !== null) {
      window.addEventListener("addToCart", (e) => {
        this.setState({cartNumber: cartNumber + e.detail.quantity})
      })
      return (
        <div className="shopping-cart-container">
          <a href={`/orders/new`}>
            <img src="/images/cart-shopping-solid.svg"/>
            <div className="shopping-cart-quantity">{cartNumber}</div>
          </a>
        </div>
      )
    }
  }

  renderSearch() {
    return (
      <span className='search-button hidden-xs'>
        <li>
          <a href="/items/search">
            <i class="fas fa-search"></i>
          </a>
        </li>
      </span>
    );
  }

  renderClientInventories() {
    const { user, currentPage, renter } = this.props;

    const { renters } = this.state;
    if(isClient(user) && renters) {
      return renters.map(renter1=>
        <a href={`/inventory/${renter1.id}`}>
          <li
            className={(renter||{}).id == renter1.id ? "active" : ""}
          >
            {renter1.name} Inventory
          </li>
        </a>
      )
    }
    if(isJaguar(user) || isSuperadmin(user)) {
      return (
        <>
          <a href={`/inventory/jaguar`}>
            <li className={renter && renter.jaguar ? "active" : ""}>
              Jaguar Inventory
            </li>
          </a>
          {renter && !renter.jaguar ? (
            <a href={`/inventory/${renter.id}`}>
              <li className="active">
                {renter.name} Inventory
              </li>
            </a>
          ):""}
        </>
      )
    }
  }

  getBackgroundWaveColor(){
    const { subHeader } = this.props;
    if(subHeader && subHeader.type && subHeader.type.getBackgroundColor){
      return subHeader.type.getBackgroundColor()
    }
    return "transparent"
  }

  //TODO Better Solution
  getSortOrder(group){
    switch(group){
      case "Seating":
        return 0;
      case "Tables":
        return 1;
      case "Decor":
        return 2;
      case "Signage":
        return 3;
      case "Electrical":
        return 4 ;
      default:
        1000;
    }
  }

  renderJaguarCatalogLink(){
    const { user, currentPage} = this.props;
    if(isClient(user)){
      return (
        <a href='/catalog'>
          <li className={currentPage == `home` ? "active" : ""} >
            Catalog
          </li>
        </a>
      )
    }
  }

  render() {
    const { currentPage, user } = this.props;
    return (
      <div className="catalog-navbar">
        <div className="navbar-top-spacing"/>
        <div className="main-navbar full-body-container ">
          <div className="main-navbar-inner">
            <a href="/">
              <img className="logo" src="/images/jungle_logo.svg" />
            </a>
            <img className="header-leaves" src="/images/header-leaves.svg" />
          </div>
        </div>
        <div className="container wider-container">
          <div className="navbar-bottom-spacing">
            <div className="user-information">
              <div className="user-options">
                {this.renderUserInformation()}
              </div>
              <div className="navbar-buttons-container">
                <div style={{display: "flex", alignItems: "center"}}>
                  {this.renderOrdersLink()}
                  {this.renderEventsLink()}
                </div>
                {this.renderShoppingCart()}
              </div>
            </div>
          </div>
        </div>

        <div className="sub-navbar container wider-container ">
          <ul className="sub-navbar-categories">
            {this.generateGroupLinks()}
            {this.renderSearch()}
            {this.renderJaguarCatalogLink()}
            {this.renderClientInventories()}
            {this.renderWarehouseLink()}
          </ul>
        </div>
      </div>
    );
  }
}

PublicCatalogNavbar.propTypes = {

};

export default PublicCatalogNavbar;
