import React from "react";
import PropTypes from "prop-types";

class InventoryCategorySubheader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  renderCategories() {
    const { categories, group, category, renter} = this.props;
    return categories.map(c => (
      <a
        key={`category-${c.name}-${c.id}`}
        className={c.name == category ? "active category-link" : "category-link"}
        href={`/inventory/${renter.id}/category/${escape(c.group_name||"Other").replace("/","%2F") || "Other"}/${escape(c.name).replace("/","%2F")}`}
      >
        {c.name}
      </a>
    ));
  }

  renderRightContent(){
    const {rightAlignedContent} = this.props;
    if(rightAlignedContent){
      return (
        rightAlignedContent
      )
    }
  }

  render() {
    const { group, category, user, renter} = this.props;
    return (
      <div className="category-subheader full-body-container">
        <a
          href={`/inventory/${renter.id}/category/${group}`}
          className={category == null ? "active category-link" : "category-link"}
        >
          All {group}
        </a>
        {this.renderCategories()}
        {this.renderRightContent()}
      </div>
    );
  }
}

InventoryCategorySubheader.propTypes = {
  renter: PropTypes.object.isRequired,
  category: PropTypes.object.optional,
  categories: PropTypes.array.isRequired,
};

export default InventoryCategorySubheader;
