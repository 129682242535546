import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import WarehouseSearchableItemTable from "../items/WarehouseSearchableItemTable"

class WarehouseRentersShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }


  render() {
    const { renter } = this.props;
    return (
      <WarehouseLayout currentPage="dashboard">
        <div className="warehouse-card">
          <h1> Renter:  </h1>
          <div className="row">
            <div className="col-xs-12">
              <label>
                Total Volume:
              </label>
              {Math.round(renter.stored_volume/1728)} cubic ft.
            </div>
            <div className="col-xs-12">
              <label>
                Monthly Cost:
              </label>
              ${(Math.round(renter.stored_volume/1728)*0.5).toFixed(2)}
            </div>
            <div className="col-xs-12">
              <label>
                Annual Cost:
              </label>
              ${(Math.round(renter.stored_volume/1728)*6).toFixed(2)}
            </div>
          </div>
          <h2> Options:</h2>
          <div className="row">
            <div className="col-xs-12">
              <a target="_blank" href={`/inventory/${renter.id}`}>
                <button className="btn btn-primary">
                  Go To Client View
                </button>
              </a>
              <p />
              <a target="_blank" href={`/warehouse/reports/renter?id=${renter.id}`}>
                <button className="btn btn-primary">
                  Download {renter.name}s renter Report
                </button>
              </a>
              <a target="_blank" href={`/warehouse/reports/renter_pdf.pdf?id=${renter.id}`}>
                <button className="btn btn-primary">
                  Download {renter.name}s renter PDF Report
                </button>
              </a>

              <a target="_blank" href={`/warehouse/reports/renter_full_billing?id=${renter.id}`}>
                <button className="btn btn-primary">
                  Download Full {renter.name}s Pricing/Size Report
                </button>
              </a>
              <p />
            </div>
          </div>
        </div>
        <div className="warehouse-card">
          <h1> {renter.name}'s Items: </h1>
          <WarehouseSearchableItemTable items={this.props.items} searchText={null} showVariants={true} />
        </div>
      </WarehouseLayout>
    );
  }
}

WarehouseRentersShow.propTypes = {
  renter: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default WarehouseRentersShow;
