import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../../utility/WindowSizeHooks"
import Cookies from "universal-cookie";
import { isHoldShipped } from "../../utility/HoldUtils"
import ReceiveItemModal from '../modals/ReceiveItemModal'

class EventItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      holdStatus: this.props.hold.status
    }

    this.deleteHolds = this.deleteHolds.bind(this);
  }

  changeHoldStatus(status) {
    switch(status) {
      case "reserved":
      case "packed":
      case "shipped":
      case "received":
        this.setState({holdStatus: status});
      default:
        return;
    }
  }

  deleteHolds() {
    const { hold, item, event } = this.props;
    const quantity = hold.total_count;
    if(confirm(`Are you sure you want to delete this hold for these ${quantity} items?`)) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/events/${event.slug}/item_holds/${hold.id}`, {
        method: 'DELETE',
        redirect: "manual",
        body: "",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          window.location.replace(json.url);
        } else {
          alert(json.error);
        }
      });
    }
  }

  renderFloatingHoldInformation() {
    const { hold } = this.props;
    const quantity = hold.total_count
    const showError = !!hold.shipping_address_id
    return (
      <React.Fragment>
        <div className={`item-card-count ${isHoldShipped(hold) ? "packed-item-count" : "unpacked-item-count"}`}>
          {quantity}
        </div>
        {hold.has_error ?
          (
            <div className="error-indicator">
              !
            </div>
          ) : ""
        }
      </React.Fragment>
    )
  }

  getBackgroundImage(){
    const { item, variant } = this.props;
    let backgroundURL = null;
    if(variant) {
      backgroundURL= variant.active_image;
    }
    else {
      backgroundURL = item.active_image;
    }
    if(!backgroundURL){
      //Default URL
      return "/images/item_image_not_found.png"
    }
    return backgroundURL.image;
  }

  renderFlags() {
    //Written with future of adding more flags in mind
    const {hold, received} = this.props;
    const {holdStatus} = this.state;
    let flags = [];

    if (!received) {
      flags.push({text: "Ordered", class: "ordered-flag"})
      //if the item hasn't been received, we don't care about these other status flags
    }
    if (hold.direct_to_site) {
      flags.push({text: "Direct to site", class: "shipped-flag"})
    } 
    if (holdStatus) {
      var flagClass;
      switch(holdStatus) {
        case "reserved":
          flagClass = "reserved-flag";
          break;
        case "in_progress":
          flagClass = "packed-flag";
          break;
        case "packed":
          flagClass = "packed-flag";
          break;
        case "shipped":
          flagClass = "shipped-flag";
          break;
        case "received":
          flagClass = "received-flag";
          break;
      }
      const capitalizedLetter = holdStatus.charAt(0).toUpperCase();
      const remaining = holdStatus.slice(1);
      let status = (capitalizedLetter + remaining).replace(/_/g, " ");
      flags.push({text: status, class: flagClass})
    }

    flags = flags.map(flag => 
      <div className={"item-card-flag " + flag.class}>{flag.text}</div>
    )
    return <div className="item-card-flag-container">{flags}</div>
  }

  renderLockIcon() {
    const { hold } = this.props;
    if(hold.locked) {
      return (
        <img src="/images/items/locked_icon.png"/>
      )
    }
    return (
      <a href="#" onClick={() => this.props.lockHold(hold)}>
        <img src="/images/items/unlocked_icon.png" />
      </a>
    );
  }

  render() {
    const { item, event, variant,hold } = this.props;
    const {holdStatus} = this.state;
    return(
      <div className="item-card event-item-card">
        {this.renderFlags()}
        <div
          className="item-card-image"
          style={{backgroundImage: `url("${this.getBackgroundImage()}")`}}
        >
        </div>
        <div className="item-card-hover-buttons">
          <a href={`/events/${event.slug}/item_holds/${hold.id}`}><img src="/images/items/edit_icon.svg"/></a>
          {this.renderLockIcon()}
          {!hold.locked && <a href="#"><img src="/images/items/delete_icon.svg" onClick={this.deleteHolds}/></a>}
        </div>
        <div className="item-card-hold-information">
          {this.renderFloatingHoldInformation()}
          {hold.locked ? <img src="/images/items/mini_lock_icon.png" className="" /> : ""}
          {hold.item.public ? "" : <img src="/images/internal_view_icon.png" className="hidden-icon pull-right"/>}
        </div>
        <div className="item-card-description">
          <a href={`/items/${item.id}`}>
            <div className="name">
              {item.name} {variant ? ` - ${variant.name}` : ''}
            </div>
          </a>
        </div>
      </div>
    );
  }
}

EventItemCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default EventItemCard;
