import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class ItemAttachmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      file_name: props.attachment ? props.attachment.original_filename : null,
      file_uploaded: null,
      optionalFileName: props.attachment ? props.attachment.filename : ""
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onDrop = this.onDrop.bind(this)
    this.setOptionalFilename = this.setOptionalFilename.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
      file_name: this.props.attachment ? this.props.attachment.original_filename : null,
      file_uploaded: null,
      optionalFileName: this.props.attachment ? this.props.attachment.filename : "",
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { attachment } = this.props;
    if(attachment){
      return (<button type="button" role="button" onClick={this.openModal} className="btn btn-green pull-right">Edit Label</button>)
    }
    return (<button type="button" role="button" onClick={this.openModal} className="btn btn-green">Add An Attachment</button>)
  }

  renderDropzone(){
    const {file_name} = this.state;
    const { attachment } = this.props;
    if(attachment){
      return (
        <h3> File Name: {file_name} </h3>
      )
    }
    return (
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={classNames("dropzone", "person-create-dropzone-header", {
                "dropzone--isActive": isDragActive
              })}
            >
              <input {...getInputProps()} required/>
                {file_name ? (
                    <h3>Uploaded:
                      <br />
                      {file_name}
                    </h3>
                ) 
                : (
                  <div className="item-form-dropzone-fallback">
                    <h3>Add an Attachment&nbsp;</h3>
                    <button type="button" className="form-button person-create-dropzone-button">
                      Choose File
                    </button>
                  </div>
                )}
            </div>
          );
        }}
      </Dropzone>
    )
  }

  onDrop(acceptedFiles) {
    this.setState({
      file_uploaded: acceptedFiles[0],
      file_name: acceptedFiles[0].name
    });
  }


  renderSaveButton(formikProps){
    const {attachment} = this.props;
    if(this.state.file_uploaded || attachment){
      return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Save </button>
    }
    return <button className="btn btn-primary pull-right"type="button"  disabled> Save </button>
  }

  formMethod() {
    const { attachment } = this.props;
    if(attachment){
      return 'PATCH'
    }
    return 'POST'
  }

  formURL() {
    const { attachment, item } = this.props;
    if(attachment){
      return  `/webapi/attachments/${attachment.id}`
    }
    return `/webapi/attachments/`
  }

  setOptionalFilename(e){
    this.setState({
      optionalFileName: e.target.value
    })
  }

  renderForm(){
    return (
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          var data = new FormData();
          const { attachment } = this.props;
          if( !attachment ) {
            data.append('item_attachment[attachment]', this.state.file_uploaded); 
            data.append('item_attachment[item_id]', this.props.item.id);      
          }
          data.append('item_attachment[filename]', this.state.optionalFileName && this.state.optionalFileName.length > 0 ? this.state.optionalFileName : this.state.file_name);
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");

          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: data,
            headers: {
              "X-CSRF-Token": token
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.closeModal();
                this.props.addAttachment(json.item_attachment);
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-attachment-form">
            {this.renderDropzone()}
            <br />
            <div className="row">
              <div className="col-xs-12">
                <label> Label (optional) </label>
                <input className="form-control" value={this.state.optionalFileName} onChange={this.setOptionalFilename} />
              </div>
            </div>
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-attachment-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Attachment"
        >
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default ItemAttachmentModal