import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { Formik, Field, Form } from "formik";
import Cookies from "universal-cookie";
import { dataURItoBlob } from "../../utility/FileUtils";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

class DropzoneInline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropzoneText: "Drag and Drop Your File Here",
      file: null,
      fileDropped: false,
      imagePreviewUrl: null,
      openModal: false,
      cropResult: props.previewImage,
      croppedBlob: null,
      cropImage: false,
    };
    this.onDrop = this.onDrop.bind(this);
    this.exitModal = this.exitModal.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.dropzoneRef = React.createRef()
    this.toggleCropper = this.toggleCropper.bind(this);
  }

  onDrop(acceptedFiles) {
    if (acceptedFiles.length > 0) {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onloadend = () => {
        this.setState({
          dropzoneText: "File attached",
          file: acceptedFiles[0],
          fileDropped: true,
          imagePreviewUrl: reader.result,
          openModal: true,
          cropImage:false,
          croppedBlob: null,
        });
      }
      if(this.props.onDrop){
        this.props.onDrop(acceptedFiles[0])
      }
    } else {
      this.setState({
        dropzoneText: "This file type is not supported."
      });
    }
  }

  exitModal() {
    this.setState({
      dropzoneText: "Drag and Drop Your File Here",
      file: null,
      fileDropped: false,
      imagePreviewUrl: null,
      openModal: false,
      cropResult: this.props.previewImage,
      croppedBlob: null,
      cropImage: false,
    });
  }

  cropImage() {
    const { name, setFiles } = this.props;
    const { file, cropImage } = this.state;
    if(cropImage) {
      if (typeof this.cropper.cropper.getCroppedCanvas() === "undefined") {
        return;
      }
      let dataURL = this.cropper.cropper.getCroppedCanvas().toDataURL()
      let blob = dataURItoBlob(dataURL, file.name);
      this.setState({
        cropResult: dataURL,
        croppedBlob: blob
      });
      return blob;
    }
    return file;
  }

  setDropzoneBeforeDrop() {
    const { setImage } = this.props;
    const { dropzoneText } = this.state;
    if (setImage) {
      return (
        <React.Fragment>
          <img src={setImage} className="drop-section-preview"/>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h4 className="dropzone-text">{dropzoneText}</h4>
      </React.Fragment>
    );
  }

  toggleCropper(){
    const {cropImage} = this.state;
    this.setState({
      cropImage: !cropImage
    })
  }

  renderModal() {
    const { cropImage } = this.state;
    if(cropImage){
      return (
        <div className="modal-style" id="modal-image">
          <div className="modal-cropper">
            <h4>Crop Thumbnail:</h4>
            <Cropper
              style={this.props.style || { height: "250px", width: "100%" }}
              preview=".img-preview"
              guides={false}
              src={this.state.imagePreviewUrl}
              ref={cropper => {
                this.cropper = cropper;
              }}
              viewMode={1}
              dragMode="move"
              cropBoxMovable={true}
            />
            <button onClick={this.exitModal}>Change Image</button>
            <button onClick={this.toggleCropper}> Cancel Cropping</button>
          </div>
        </div>
      );
    }else {
      return (
        <div className="modal-style" id="modal-image">
          <img src={this.state.imagePreviewUrl}  style={{display: "block", maxWidth:"100%", maxHeight:"200px", marginLeft:"auto", marginRight:"auto"}}/>
          <br />
          <button className="btn btn-primary" onClick={this.toggleCropper}> Crop Image</button>
        </div>
      )
    }

  }

  renderPreviewImage(){
    const {imagePreviewUrl} =  this.state;
    if(imagePreviewUrl){
      return (
        <React.Fragment>
            <h4>Image:</h4>
            <img className="inline-dropzone-image-preview" src={imagePreviewUrl}  />
        </React.Fragment>
      )
    }
  }
  render() {
    const { showPreviews } = this.props;
    const { imagePreviewUrl, cropResult, file, fileDropped, dropzoneText, openModal } = this.state;
    if(openModal){
      return (
        <div className="row">
          <div className="col-xs-12 col-md-12">
            {this.renderModal()}
          </div>
        </div>
      )
    }
    return (
      <React.Fragment>
        <Dropzone onDrop={this.onDrop} name="hero_image[image]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className="drop-section-tiles">
                <input {...getInputProps()} id="item-form-attachment-dropzone-input"/>
                {cropResult ? (
                  <React.Fragment>
                    <img src={cropResult} className="drop-section-preview"/>
                    <p>{file ? file.name : ""}</p>
                  </React.Fragment>
                ) : (
                  this.setDropzoneBeforeDrop()
                )}
              </div>
            );
          }}
        </Dropzone>
      </React.Fragment>
    );
   }
}
export default DropzoneInline;
