import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "./WarehouseLayout"
class WarehouseDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    const { children } = this.props;
    return (
      <WarehouseLayout currentPage="dashboard">
        <h1> Work In Progress </h1>
      </WarehouseLayout>
    );
  }
}

WarehouseDashboard.propTypes = {

};

export default WarehouseDashboard;
