import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class EditTagModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    const { category } = this.props;
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { button } = this.props;
    if( button ) {
      return (
        <div style={{display: "inline-block"}} onClick={this.openModal}>
          {button}
        </div>
      );
    }
    return (
      <button
        type="button"
        role="button"
        onClick={this.openModal}
        className="event-dashboard-add-event"
      >
        Add Address
      </button>
    );
  }

  renderSaveButton(formikProps) {
    return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Rename </button>
  }

  formMethod() {
    return 'PATCH'
  }

  formURL() {
    const { tag } = this.props;
    return  `/tags/${tag.tag}`
  }

  renderForm(){
    const { tag } = this.props;
    return (
      <Formik
        initialValues={{
          tag: {
            tag: tag.tag
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.props.updateTags(json.item_tags);
                this.closeModal();
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form id="item-attachment-form">
            <div className="row">
              <div className="col-xs-12">
                <label for="tag[tag]"> Tag Name: </label>
                <Field required="true" className="form-control" name="tag[tag]" />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-md-3">
                <button type="submit" className="item-hold-form-submit-button">Rename</button>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-3">
                <button type="button" onClick={this.closeModal} className="item-hold-form-cancel-button">Cancel</button>
              </div>  
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-attachment-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Attachment"
        >
          <div className="react-modal-close-button" onClick={this.closeModal}> &#10005; </div>
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default EditTagModal