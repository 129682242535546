import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../../utility/WindowSizeHooks"
import PublicCatalogLayout from "../../layouts/PublicCatalogLayout"
import Cookies from "universal-cookie";
import ShippingAddressModal from "./ShippingAddressModal"
class ShippingAddressesDashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      addresses: props.shipping_addresses
    }
    this.addShippingAddress = this.addShippingAddress.bind(this);
    this.updateShippingAddress = this.updateShippingAddress.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
    this.archiveAddress = this.archiveAddress.bind(this);
  }

  renderAddressTable(addresses, archive = false) {
    const rows = addresses.filter(x=> !!x.archived_at == archive).map( address => (
      this.renderAddressRow(address)
    ));
    return (
      <table className="events-dashboard-table">
        <thead>
          <tr>
            <th className="event-name-header">Name</th>
            <th>Address</th>
            <th className="wider-action"></th>
            <th className="wider-action"></th>
            <th className="wider-action"></th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }


  addShippingAddress(address) {
    this.setState({
      addresses: [address].concat(this.state.addresses)
    })
  }

  updateShippingAddress(address) {
    this.setState({
      addresses: this.state.addresses.filter(address2 => address2.id != address.id).concat(address)
    })
  }



  renderAddressRow(address) {
    return (
      <React.Fragment>
        <tr key={`event-${address.id}`}>
          <td >{address.venue_name}</td>
          <td >
            {address.address_line_1? <div>{address.address_line_1}</div> : ""}
            {address.address_line_2 ? <div>{address.address_line_2}</div> : ""}
            {address.city}{address.city && address.state_province ? ", ":""}{address.state_province} {address.postal_code} {address.country}
          </td>
          <td className="action">
            <ShippingAddressModal
              updateShippingAddress={this.updateShippingAddress}
              button={(
                <a href="#" className="">Edit Address</a>
                )}
              address={address}
            />
          </td>
          <td className="action">
            <a href="#" className="" onClick={()=> this.archiveAddress(address)} > {address.archived_at ? "Unarchive" : "Archive"} Address </a>
          </td>
          <td className="action">
            <a href="#" className="" onClick={()=> this.deleteAddress(address)} > Delete Address </a>
          </td>
        </tr>
        <tr  key={`buffer-${address.id}`}>
          <td className="buffer-row" colSpan={3} >&nbsp;</td>
        </tr>
      </React.Fragment>
    )
  }


  archiveAddress(address) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(`/events/shipping_addresses/${address.id}`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify({
        shipping_address: {
          archived_at: address.archived_at ? null : new Date()
        }
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            addresses: this.state.addresses.filter(address2 => address.id != address2.id).concat(json.shipping_address)
          })
        } else {
          alert(json.error);
        }
      });
  }

  deleteAddress(address) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(confirm("Are you sure you want to delete this address? This cannot be undone.")){
      fetch(`/events/shipping_addresses/${address.id}`, {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            this.setState({
              addresses: this.state.addresses.filter(address2 => address.id != address2.id)
            })
          } else {
            alert(json.error);
          }
        });
    }
  }

  render() {
    const { addresses } = this.state;
    return (
      <PublicCatalogLayout  classNames={{container: 'full-body-container '}} currentPage="events" {...this.props} className="events-dashboard">
        <br />
        <div className="row">
          <div className="col-xs-12">
            <a href="/events">{"<"} Back to Events</a>
            <h1> Shipping Locations </h1>
          </div>
          <div className="col-xs-12 col-sm-6">
            <ShippingAddressModal
              addShippingAddress={this.addShippingAddress}
            />
          </div>
          <div className="col-xs-12">
            <br />
            {this.renderAddressTable(addresses)}
          </div>

          <div className="col-xs-12">
            <h1> Archived Addresses </h1>
            {this.renderAddressTable(addresses, true)}
          </div>
        </div>
      </PublicCatalogLayout>
    );
  }
}

ShippingAddressesDashboard.propTypes = {

};

export default ShippingAddressesDashboard;
