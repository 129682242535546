import React from "react";
import KitCardQuickViewModal from "../../kits/KitCardQuickViewModal"
import Cookies from "universal-cookie";

class EventKitCard extends React.Component {
  constructor(props) {
    super(props);
    this.handleDeleteKitHold = this.handleDeleteKitHold.bind(this);
  }

  getItemCount() {
    const {items} = this.props.kit
    let total = 0;
    items.forEach((item) => {
      total += item.item_count
    })
    return total
  }
  //TODO: Change this to the new url when destroy methods get moved over to the KitHoldsController on backend
  handleDeleteKitHold() {
    const {kit, event, hold} = this.props
    const token = new Cookies().get("X-CSRF-Token");
    fetch(`/kits/${kit.id}/destroy_kit_item_holds`, {
      method: "DELETE",
      redirect: "manual",
      body: JSON.stringify({
        event_id: event.id,
        kit_hold_id: hold.id
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then((res) => 
      res.json()
    )
    .then((json) => {
      alert(json.message);
    })
  }

  renderViewItemsIcon() {
    return (
      <a href="#"><div className="event-kit-card-view-items-button">VIEW ITEMS</div></a>
    )
  }

  render() {
    const { kit, count, event, hold } = this.props;
    return (
      <div className="item-card event-item-card">
        <div className="kit-card-flag-container"><div className="kit-card-flag dark-green">{"Kit " + `(${this.getItemCount()} items)`}</div></div>
        <div
          className="item-card-image"
          style={{backgroundImage: `url("${kit.images && kit.images.length > 0 ? kit.images[0].image : ""}")`}}
        >
        </div>
        <div className="item-card-hover-buttons">
          <KitCardQuickViewModal {...this.props} accessButton={this.renderViewItemsIcon()}/>
          <a href={`/events/${event.id}/kit_holds/${hold.id}`}><img src="/images/items/edit_icon.svg"/></a>
          <a href="#"><img src="/images/items/delete_icon.svg" onClick={this.handleDeleteKitHold}/></a>       
        </div>
        <div className="item-card-hold-information">
          <div className="item-card-count unpacked-item-count">
            {count}
          </div>
        </div>
        <div className="item-card-description">
          <a href={`/kits/${kit.id}`}>
            <div className="name">
              {kit.name}
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default EventKitCard;