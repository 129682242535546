import React from "react";
import PropTypes from "prop-types";
import { formatEventDate } from "../../utility/DateUtils"
import EventModal from "../EventModal"
import GenerateEventReportModal from "../reports/GenerateEventReportModal"
import { isJaguar, isSuperadmin, isClient } from "../../utility/UserUtils"
import TemporaryItemFormModal from "../../items/temporary_items/TemporaryItemFormModal"
class EventSubheader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  renderActions() {
    const { event, user } = this.props;
    return (
      <div className="event-header-actions text-right">
        {isJaguar(user) &&
          <>
            <TemporaryItemFormModal
            event={event}
            shipping_addresses={this.props.shipping_addresses}
            >
              Add Temporary Item
            </TemporaryItemFormModal>
            <a href="#">
              <EventModal
                button="Edit Event"
                event={event}
                reload={true}
                renters={this.props.renters}
                shipping_addresses={this.props.shipping_addresses}
                />
            </a>
            <a href={`/events/${event.id}/reports`}>
              Reports
            </a>
          </>
        }
        <a href={`/events/${event.id}/manifest`}>
          Manifest
        </a>
      </div>
    );
  }

  static getBackgroundColor() {
    return "#486ca9"
  }

  render() {
    const { event } = this.props;
    return (
      <div className="event-subheader">
        <div className="event-subheader-inner-container container wider-container">

          <div className="event-subheader-title">
            <a className="event-subheader-header-link" href={`/events/${event.slug}`}><h1>{event.name}</h1></a>
            <div className="event-subheader-dates">
              {formatEventDate(event)}
            </div>
          </div>
          {this.renderActions()}
        </div>
      </div>
    );
  }
}

EventSubheader.propTypes = {
  event: PropTypes.object.optional,
};

export default EventSubheader;
