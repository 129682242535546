import React, { Component } from 'react'
import PublicCatalogLayout from '../../layouts/PublicCatalogLayout'
import EventSubheader from '../partials/EventSubheader'

export default class EventLossesTable extends Component {
  
  renderLog(log) {
    return (
      <tr style={{borderBottom: "1px solid black"}}>
        <td><img src={log.item.active_image ? log.item.active_image.thumbnail_url : ""}/></td>
        <td><a href={`/items/${log.item.id}`}>{log.item.name}</a></td>
        <td>{log.item.category ? log.item.category.name : ""}</td>
        <td>{log.count}</td>
        <td>{log.item.owned}</td>
        <td>{log.reason}</td>
        <td>{log.notes}</td>
      </tr>
    )
  }
  
  render() {
    const {item_logs, event} = this.props;
    return (
      <PublicCatalogLayout
        currentPage="losses" {...this.props}
        groups={[]}
        subHeader={
          <EventSubheader 
            {...this.props}
          />
        }
      >
        <div className="row">
          <div className="col-xs-6">
            <h1>Losses</h1>
          </div>
          <div className="col-xs-6">
            <a href={`/events/${event.id}/reports/losses_download_xls`} target="_blank"><button className="event-losses-download-button pull-right">Download XLS</button></a>
          </div>
          <div className="col-xs-12">
            <table className="event-item-loss-table">
              <tr>
                <th className="image-col" scope="col"></th>
                <th className="item-col" scope="col">Item</th>
                <th className="category-col" scope="col">Category</th>
                <th className="amount-col" scope="col">Amount</th>
                <th className="owned-col" scope="col">Current Inventory</th>
                <th className="reason-col" scope="col">Reason</th>
                <th className="notes-col" scope="col">Notes</th>
              </tr>
              {item_logs.map(log => this.renderLog(log))}
            </table>
          </div>
        </div>
      </PublicCatalogLayout> 
    );
  }
}
