import React,{ useState }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


const OnSiteInformationModal = ({
  open, 
  onClose,
  fullEventInformation,
  updateEventInformation
}) => {
  const renderForm = () => {
    return (
      <div className="row">
        <div className="col-xs-12">
          <h2> On-Site Options </h2>
          Here you can remove on-site options from the event.
          <br/>
          Removing them will remove them from ALL items assigned to that respective pallet, location, or recipient. Use with care.
          <br/>
          <br/>
        </div>
        <div className="col-xs-12">
          <b> Pallets </b>
        </div>
        <div className="col-xs-12">
            {renderItems("pallet", (fullEventInformation||{}).pallets)}  
        </div>

        <div className="col-xs-12">
          <b> Distribution Locations </b>
        </div>
        <div className="col-xs-12">
          {renderItems("event_location", (fullEventInformation||{}).event_locations)}  
        </div>

        <div className="col-xs-12">
          <b> Recipients </b>
        </div>
        <div className="col-xs-12">
          {renderItems("event_recipient", (fullEventInformation||{}).event_recipients)}  
        </div>
      </div>
    )
  }



  const renderItems = (type, items) => {

    return (items||[]).map(item => (
      <Chip
        size="small"
        label={item.name}
        variant="outlined"
        onDelete={()=> deleteOnsiteInformation(type, item.id)}
      />
    ));
  }

  const deleteOnsiteInformation = (type, id) => {
    fetch(`/events/${fullEventInformation.id}/onsite_information`, {
      method: "DELETE",
      redirect: "manual",
      body: JSON.stringify({type: type, id: id}),
      headers: {
        "X-CSRF-Token": new Cookies().get("X-CSRF-Token"),
        "Content-Type": 'application/json'
      }
    }).then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        updateEventInformation();
      }
    });
  }

  const closeModal = () => {
    if(onClose) {
      onClose()
    }
  }

  return (
    <Modal
      className="react-modal add-attachment-modal add-form-modal"
      overlayClassName="react-modal-overlay"
      isOpen={open}
      onRequestClose={closeModal}
      contentLabel=""
    >
      <div className="react-modal-close-button" onClick={closeModal}> &#10005; </div>
      {open ? renderForm(): ""}
    </Modal>
  )
}

export default OnSiteInformationModal