import React, {Component} from "react";
import PropTypes from "prop-types";
import PublicCatalogLayout from "../../layouts/PublicCatalogLayout"
import EventSubheader from "../partials/EventSubheader"
import GenerateEventReportModal from "./GenerateEventReportModal";
import ReportEntry from "./ReportEntry";

export default class ItemReportsShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: this.props.reports || [],
    };
  }

  handleNewReport(newReport) {
    let {reports} = this.state;
    reports.push(newReport);
    this.setState({reports: reports})
  }

  render() {
    const {reports} = this.state
    return (
      <PublicCatalogLayout
        currentPage="reports" {...this.props}
        groups={[]}
        subHeader={
          <EventSubheader 
            {...this.props}
          />
        }
      >
        <div className="row">
          <div className="col-xs-6">
            <h1>Item Reports</h1>
            <p>PDF's will generate in the background once you've created a report, come back later or refresh to see if your report is ready to download.</p>
          </div>
          <div className="col-xs-6 event-reports-create-button-container">
            <GenerateEventReportModal button={<button className="event-reports-create-button">Create Item Report</button>} handleNewReport={this.handleNewReport.bind(this)} {...this.props}/>
          </div>
        </div>
        <div className="event-reports-entry-container">
          {reports.length > 0 ? reports.map(report => <ReportEntry report={report}/>) : <div style={{paddingTop: "12px"}}>There are no reports for this event</div>}
        </div>
      </PublicCatalogLayout> 
    );
  }
}