import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import EventSubheader from "./partials/EventSubheader"
import { Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Link} from "@mui/material"
import DataGridTableToolbar from "@/utility/DataGridTableToolbar";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
import Cookies from "universal-cookie";
import ReceiveItemModal from './modals/ReceiveItemModal'
import { isJaguar, isSuperadmin, isClient } from "../utility/UserUtils"

class EventOnsitePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedRows: [],
    }
    this.renderWarehouseRecieved = this.renderWarehouseRecieved.bind(this);
  }


  createData() {
    const { items, item_holds, event_item_fields } = this.props;
    // Create a map for item holds and event item fields
    const itemHoldsMap = new Map();
    item_holds.forEach(hold => {
      if (!itemHoldsMap.has(hold.item_id)) {
        itemHoldsMap.set(hold.item_id, { quantity: 0, locations: [], infoNotes: [] });
      }
      const item = itemHoldsMap.get(hold.item_id);
      item.pallets = (item.pallet||[]).concat([hold.pallet]);
      item.quantity += hold.total_count;
      if (hold.event_location) {
        item.locations.push(hold.event_location);
      }
      if (hold.notes) {
        item.infoNotes.push(hold.notes);
      }
      item.holds = (item.holds||[]).concat([hold]);
    });

    const eventItemFieldsMap = new Map();
    event_item_fields.forEach(fieldset => {
      eventItemFieldsMap.set(fieldset.item_id, {
        pallet_number: fieldset.pallet_number,
        checked_in: fieldset.checked_in,
        collected: fieldset.collected,
        eventItemFieldsId: fieldset.id,
        warehouseReceived: fieldset.warehouse_received,
        post_event_notes: fieldset.post_event_notes,
      });
    });

    // Create itemData in a single pass
    const itemData = items
      .map(item => {
        const holdData = itemHoldsMap.get(item.id) || { quantity: 0, locations: [], infoNotes: [] };
        const eventFieldData = eventItemFieldsMap.get(item.id) || {};
        return {
          id: item.id,
          image_url: item.active_image ? item.active_image.thumbnail_url : "",
          group: item.category ? item.category.group_name||"Misc  " : "Misc",
          category: item.category ? item.category.name : "Other",
          name: item.name,
          item: item,
          owned: item.owned,
          color: this.getItemColor(item),
          tags: (item.item_tags||[]).map(x=> x.tag).join(", "),
          quantity: holdData.quantity,
          locations: holdData.locations,
          palletNumber: (holdData.pallets||[]).map(x=>(x||{}).name).join(", "),
          locationsString: (holdData.locations||[]).map((x,i,t)=> 

            t.length > 1 ? `${x.name} (${x.quantity})` : x.name
          ).join(", "),
          renter_name: item.renter ? item.renter.name : "",
          shippedCount: this.getShippedCount(holdData.holds),
          infoNotes: holdData.infoNotes.join(","),
          checked_in: eventFieldData.checked_in,
          collected: eventFieldData.collected,
          eventItemFieldsId: eventFieldData.eventItemFieldsId,
          warehouseReceived: eventFieldData.warehouseReceived,
          post_event_notes: eventFieldData.post_event_notes,
          pallet_number: eventFieldData.pallet_number
        };
      });
    return itemData;
  }

  getShippedCount(holds) {
    return holds.filter(x=>
      ["shipped", "returned"].includes(x.status)
    ).reduce((a, b) => a + b.total_count, 0)
  }

  getItemColor(item) {  
    if(item && item.custom_fields) {
      try{
        const fields = JSON.parse(item.custom_fields)['fields']
        return fields.find(x=>x.name=="Color")['value']
      }
      catch(err) {
      }
    }
  } 

  getColumns() {
    return [
      {field: "image_url", headerName: "Image", renderCell: (params) => <img src={params.value} style={{width: "60px"}}/>},
      {field: "renter_name", headerName: "Inventory", flex: 1, renderCell: (params)=>params.row.item.renter ? (
        <Link href={`/inventory/${params.row.item.renter.id}`}>{params.value}</Link>
      ):""},
      {field: "group", headerName: "Category"},
      {field: "category", headerName: "Subcategory"},
      {field: "name", headerName: "Name", flex: 1, renderCell: (params)=>(
        <Link href={`/items/${params.row.item.id}`}>{params.value}</Link>
      )},
      {field: "tags", headerName: "Tags"},
      {field: "color", flex: 1, headerName: "Color"},
      {field: "palletNumber", flex: 1, headerName: "Pallets"},
      {field: "locationsString", flex: 1, headerName: "Locations"},
      {field: "quantity", flex: 1, headerName: "Quantity"},
      {field: "infoNotes", flex: 1, headerName: "Info Notes"},
      {field: "shippedCount", flex: 1, headerName: "Shipped"},
      {field: "checked_in",width: 100, headerName: "Checked In",
        renderCell: (params)=>this.renderEditableNumberField(params.row, 'checked_in', params.row.id, {type: "number"})
      },
      {field: "pallet_number",width: 100, headerName: "Pallet #",
        renderCell: (params)=>this.renderEditableTextField(params.row, 'pallet_number', params.row.id, {})
      },
      {field: "collected",width: 100, headerName: "Collected", renderCell: (params)=>this.renderEditableNumberField(params.row, 'collected', params.row.id, {type: "number"})},
      {field: "post_event_notes", width: 200, headerName: "Post Event Notes", renderCell: (params)=>this.renderEditableTextField(params.row, 'post_event_notes', params.row.id, {type: "text"})},
      {field: "warehouseReceived", flex: 1, headerName: "Warehouse Received", renderCell: (params)=>this.getCurrentValue('warehouseReceived', params.row.id, params.row)},
      {field: "owned", flex: 1, headerName: "Total in Inventory" },
      {field: "actions",actions: true, headerName: "Actions", renderCell: this.renderWarehouseRecieved},

    ];
  }

  renderWarehouseRecieved(params) {
    const { event } = this.props;
    const { warehouseReceived, item, collected } = params.row
    return (
      <ReceiveItemModal 
        item={params.row}
        event={event}
        collected={collected}
        setWarehouseReceived={(x)=>{
          this.changeRow("warehouse_received", params.row.id, params.row.eventItemFieldsId, x, false)
        }}
        accessButton={
          <Button
            variant="contained"
            sx={{fontSize: "13px", backgroundColor: "rgb(33, 85, 21)"}}
            disabled={isClient(this.props.user)}
          >
            Receive
          </Button>
        }
      />
    )
  }

  renderEditableNumberField(row, field_id, row_id, options = {}) {
      const { type } = options
      return (
        <TextField 
        size="small"
        key={`textfield-${field_id}-${row_id}`}
        sx={{...cellStyles, width: "75px", marginTop: "7px"}}
        type="number" 
        value={this.getCurrentValue(
          field_id, row_id, row
        )} 
        onChange={(e) => this.changeRow(
          field_id, row.id, row.eventItemFieldsId, e.target.value
        )}
        inputProps={{
          min: 0,
          style: cellStyles
        }}
      />
    )
  }

  renderEditableTextField(row, field_id, row_id, options = {}) {
    const { type } = options
    return (
      <TextField 
        size="small"
        sx={{
          ...cellStyles,
          marginTop: "7px"
        }}
        key={`textfield-${field_id}-${row_id}`}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        value={this.getCurrentValue(
          field_id, row_id, row
        )} 
        onChange={(e) => this.changeRow(
          field_id, row.id, row.eventItemFieldsId, e.target.value
        )}
        multiline
        fullWidth
        inputProps={{
          min: 0,
          style: cellStyles
        }}
    />
  )
}

  changeRow(field_id, row_id,eventItemFieldsId,  value, attemptFetch = true) {
    const { event } = this.props;
    const { updatedRows } = this.state
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    let updatedRow = updatedRows.find(row => row.id === row_id)
    if (updatedRow) {
      updatedRow[field_id] = value
    } else {
      updatedRows.push({
        id: row_id,
        [field_id]: value
      })
    }
    if(attemptFetch) {
      fetch(`/events/${event.id}/event_item_fields/${eventItemFieldsId}`, {
        method: "PATCH",
        redirect: "manual",
        body: JSON.stringify({
          [field_id]: value
        }),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
    }

    this.setState({ updatedRows })
  }

  getCurrentValue(field_id, row_id, row) {
    const { updatedRows } = this.state
    let updatedRow = updatedRows.find(row => row.id === row_id)
    if (updatedRow && Object.keys(updatedRow).includes(field_id)) {
      return updatedRow[field_id]
    }
    return row[field_id]
  }

  renderManifestTable() {
    return (
      <DataGridPremium
        autoHeight
        unstable_headerFilters
        rows={this.createData()}
        columns={this.getColumns()}
        slots={{
          toolbar: DataGridTableToolbar
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'desc' }],
          },
        }}
        style={{
          cell: {
            paddingBottom: 0,
            paddingRight: 0,
            whiteSpace: 'normal',
            overflow: 'visible',
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
      />
    )
  }

  render() {
    return (
      <PublicCatalogLayout
        currentPage="events" {...this.props}
        classNames={{container: 'wider-container'}}
        subHeader={
          <EventSubheader {...this.props}/>
        }
      >
        <h2>Manifest</h2> 
        {this.renderManifestTable()}

      </PublicCatalogLayout> 
    );
  }
}

const cellStyles = {
  fontSize: "13px"
}

EventOnsitePanel.propTypes = {
  event: PropTypes.object.optional,
};

export default EventOnsitePanel;
