import React, { Component } from 'react'
import Modal from "react-modal"
import Cookies from "universal-cookie"

export default class AddItemToCartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      quantity: null,
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false,
    });
  }

  handleInput(e) {
    this.setState({
      quantity: Number(e.target.value)
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    const {user, item} = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/order_items`, {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify({
        user_id: user.id,
        item_id: item.id,
        quantity: this.state.quantity
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      console.log(json);
      if (json.errors == null) {
        const event = new CustomEvent("addToCart", {bubbles: true, detail: {quantity: this.state.quantity}});
        document.dispatchEvent(event);
        this.closeModal();
      } else {
        alert(json.error);
      }
    });
  }

  renderAccessButton() {
    const { accessButton } = this.props;
    if (accessButton) {
      return (
        <div onClick={this.openModal}>
          {accessButton}
        </div>
      )
    }
    return (
      <div className="add-to-cart-button" onClick={this.openModal}>
        Add To Cart
      </div>
    )
  }

  render() {
    const { item } = this.props;
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal item-card-quick-view-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Item Quickview"
        >
          <div className="react-modal-close-button" onClick={this.closeModal}> &#10005; </div>
          <h1>Add {item.name} to Cart</h1>
          <p>Enter Quantity</p>
          <form onSubmit={this.handleSubmit}>
            <input onChange={this.handleInput} className="form-control" type="number" max={item.owned}/>
            <div style={{display: "flex", paddingTop: "10px", justifyContent: "space-around"}}>
              <button className='item-hold-form-submit-button'>Add to Cart</button>
              <button className='item-hold-form-cancel-button' onClick={this.closeModal}>Cancel</button>
            </div>
          </form>
        </Modal>
      </React.Fragment>
    )
  }
}
