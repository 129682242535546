import React, { Component } from 'react'
import WarehouseLayout from "../WarehouseLayout"
import Cookies from "universal-cookie";

export default class KitsDashboard extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      kitsShowing: this.props.kits,
      kitsDeleted: [],
    }
    this.handleSearchInput = this.handleSearchInput.bind(this);
  }

  handleSearchInput(e) {
    this.setState({searchText: e.target.value})
    const searchText = e.target.value;
    const {kits} = this.props;
    if (searchText.length > 0) {
      let filtered = kits.filter(kit => kit.name.toLowerCase().includes(searchText.toLowerCase()));
      this.setState({kitsShowing: filtered});
    } else {
      this.setState({kitsShowing: this.props.kits})
    }
  }

  handleDeleteKit(kitId) {
    if (confirm("Are you sure you want to delete this kit?")){
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/warehouse/kits/${kitId}`, {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === 204) {
          let deleted = this.state.kitsDeleted.slice();
          deleted.push(kitId)
          this.setState({kitsDeleted: deleted})
        } else {
          alert(json.message);
        }
      })
      .catch(err => {
        console.log(err);
      })
    }
  }

  renderKitTable() {
    let {kitsShowing, kitsDeleted} = this.state;
    kitsShowing = kitsShowing.filter((kit) => !kitsDeleted.includes(kit.id))
    return (
      <table className='kits-table'>
        {
          kitsShowing.map(kit => 
            <tr key={kit.id}>
              <td>
                {kit.name}
              </td>
              <td className='kits-table-button-col'>
                <a href={`/warehouse/kits/${kit.id}/edit`}><button className="kit-edit-button btn btn-green">Edit</button></a>
                <button onClick={(e) => this.handleDeleteKit(kit.id)} className="btn btn-danger">Delete</button>
              </td>
            </tr>  
          )
        }
      </table>
    )
  }
  
  render() {
    return (
      <WarehouseLayout currentPage="kits">
        <h1>Kits</h1>
        <div className="row">
          <div className="col-xs-12">
            <div className="warehouse-card">
              <h3>Search</h3>
              <input className="form-control" value={this.state.searchText} onChange={this.handleSearchInput} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="warehouse-card">
              <a href="/warehouse/kits/new">
                <button className="btn btn-green"> Create </button>
              </a>
              {this.renderKitTable()}
            </div>
          </div>
        </div>
      </WarehouseLayout>
    )
  }
}
