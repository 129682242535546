import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import moment from 'moment'
import Cookies from "universal-cookie";

class NotificationDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: props.notifications,
      page: 1,
      showPerPage: 20,
      selectedNotificationID: null,
      hideRead: false,
      advancedSearch: true,
      searchQuery: "",
    }
    this.changeSelectedPage = this.changeSelectedPage.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.setSelectedNotifiationID = this.setSelectedNotifiationID.bind(this);
    this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this);
    this.toggleReadMessages = this.toggleReadMessages.bind(this);
    this.changeSearchQuery = this.changeSearchQuery.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
  }


  markReadNotification(id) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { notifications } = this.state;
    fetch(`/warehouse/notifications/${id}/mark_read`, {
      method: 'POST',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            notifications: notifications.filter(notification2 => notification2.id != id).concat([json.notification])
          });
        } else {
          //Die Silent
        }
      });
  }

  renderTarget(notification){
    const { item, item_hold, event } = notification;
    if(item_hold || event || item){
      return (
        <a target="_blank" href={this.getTargetLink(notification)}>
          {this.renderTargetMessage(notification)}
          <br />
        </a>
      )
    }
  }

  renderTargetMessage(notification) {
    const { item, item_hold, event } = notification;
    if(item_hold){
      return (`Hold: ${item.name} in ${event.name}`)
    } else if (event){
      return (`Event: ${event.name}`)
    } else if(item){
      return `Item: ${item.name}`
    }
  }

  getTargetLink(notification){
    const { item, item_hold, event } = notification;
    if(item_hold){
      return `/events/${event.slug}/item_holds/${item_hold.id}`
    }
    else if (event){
     return `/events/${event.slug}`
    }
    else if(item){
      return `/items/${item.id}`
    }
  }

  renderExpandedNotification(notification) {
    const { selectedNotificationID } = this.state;
    if(notification.id == selectedNotificationID) {
      return (
        <tr>
          <td className="warehouse-expanded-notification-cell" colspan="5">
            <b>Sender:</b><br/>
            {notification.user ?
              (
                <a href={`mailto:${notification.user.email}`}>
                  {notification.user.first_name} {notification.user.last_name}
                </a>
              )
              :
              "Unspecified"
            }
            <p/><b>Target:</b><br/>
            {this.renderTarget(notification) || "None"}
            <p/><b>Full Message:</b><br/>
            { notification.message}


            <div className="expanded-notification-actions">
              <button onClick={() => this.deleteNotification(notification)} className="btn btn-danger">Delete Message</button>
            </div>
          </td>
        </tr>
      )
    }
  }

  deleteNotification(notification){
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { notifications } = this.state;
    if(confirm(" Are you sure you want to delete this message? This cannot be undone.")) {
      fetch(`/warehouse/notifications/${notification.id}`, {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            notifications: notifications.filter(notification2 => notification2.id != notification.id)
          });
        } else {
          alert(`Unable To Delete Notification. Error: ${json.error}`);
        }
      });
    }
  }

  setSelectedNotifiationID(id){
    this.setState({
      selectedNotificationID: id
    })
    this.markReadNotification(id);
  }

  renderNotificationTable(){
    const { notifications } = this.state;
    if(notifications) {
      const rows = this.filterNotifications(this.sortNotifications(notifications)).map(notification => (
        <React.Fragment>
          <tr onClick={() => this.setSelectedNotifiationID(notification.id)}>
              <td>
                <i className={notification.read_at ? "fas fa-envelope-open" : "fas fa-envelope"}/>
              </td>
              <td>{moment(notification.created_at).format("MMM DD YYYY HH:mm")}</td>
              <td>
                {notification.user ?
                  (
                    <a href={`mailto:${notification.user.email}`}>
                      {notification.user.first_name} {notification.user.last_name}
                    </a>
                  )
                  :
                  "Unspecified"
                }
              </td>
              <td>
                {this.getTargetLink(notification) ?
                  <a href={this.getTargetLink(notification)} target="_blank">Link</a>
                  :
                  ""
                }
              </td>
              <td>
                {notification.message.length > 45 ? `${notification.message.substring(0,40)}...` : notification.message}
              </td>
          </tr>
          {this.renderExpandedNotification(notification)}
        </React.Fragment>
      ))
      return (
        <table className="warehouse-basic-table ">
          <thead>
            <tr>
              <th style={{width:"12px"}}></th>
              <th>Date</th>
              <th>From</th>
              <th>Target</th>
              <th style={{width:"60%"}}>Message</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )
    }
    return (
      <h2 className="text-center">
        No Notifications Currently Exist
      </h2>
    );
  }

  filterNotifications(notifications){
    const { page, showPerPage, hideRead, searchQuery } = this.state;
    notifications =  notifications.filter((notification,index) =>
      index < ((page)*showPerPage) && index >= ((page-1) * showPerPage)
    ).filter(notification => (!hideRead || !notification.read_at))

    if(searchQuery){
      const lowerCaseQuery = searchQuery.toLowerCase()
      notifications = notifications.filter(notification =>
        (""||notification.message).toLowerCase().includes(lowerCaseQuery) ||
        (notification.user && `${notification.user.first_name} ${notification.user.last_name}`.toLowerCase().includes(lowerCaseQuery)) ||
        (this.renderTargetMessage(notification)||"").toLowerCase().includes(lowerCaseQuery)
      )
    }
    return notifications;
  }

  sortNotifications(notifications){
    return notifications.sort((a,b)=>
      moment(b.created_at) - moment(a.created_at)
    )
  }

  updatePage(ammount) {
    let { page } = this.state;
    page += ammount;
    page = Math.max(page, 0);
    page = Math.min(page, this.getTotalPages());
    this.setState({
      page
    })
  }

  renderTableControls(){
    const { notifications, page, showPerPage } = this.state;
    return (
      <React.Fragment>
        <div className="col-xs-2 pull-right">
          <button className="btn form-control btn-primary" onClick={() => this.updatePage(1)}> Next Page</button>
        </div>
        <div className="col-xs-2 pull-right">
          <select onChange={this.changeSelectedPage} value={page} className="form-control">
            {this.renderPageOptions()}
          </select>
        </div>
        <div className="col-xs-2 pull-right">
          <button className="btn form-control btn-primary" onClick={() => this.updatePage(-1)}> Previous Page</button>
        </div>

      </React.Fragment>
    )
  }

  changeSelectedPage(e){
    this.setState({
      page: parseInt(e.target.value)
    })
  }

  getTotalPages() {
    const { notifications, showPerPage } = this.state;
    return Math.ceil(notifications.length / showPerPage)
  }

  renderPageOptions(){
    const array = new Array(this.getTotalPages()).fill(null);
    return array.map((empty, index)=>(
      <option value={index+1}>{index+1}</option>
    ));
  }

  renderAdvancedSearch(){
    const { advancedSearch, show, hideRead, searchQuery } = this.state;
    if( advancedSearch ) {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-xs-12">
              <a href="#" onClick={this.toggleAdvancedSearch}>
                Close Advanced Search
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8">
              <label>Search</label>
              <br/>
              <input value={searchQuery} onChange={this.changeSearchQuery} className="form-control" />
            </div>
            <div className="col-xs-12 col-sm-4">
              <label>Hide Read Messages</label>
              <br/>
              <input onChange={this.toggleReadMessages} type="checkbox" value={hideRead} />
            </div>
          </div>
        </React.Fragment>
      )
    }
    return (
      <div className="row">
        <div className="col-xs-12">
          <a href="#" onClick={this.toggleAdvancedSearch}>
            Open Advanced Search
          </a>
        </div>
      </div>
    )
  }

  changeSearchQuery(e){
    this.setState({
      searchQuery: e.target.value
    })
  }

  toggleReadMessages(){
    const { hideRead } = this.state;
    this.setState({
      hideRead: !hideRead
    })
  }

  toggleAdvancedSearch(){
    const { advancedSearch } = this.state;
    this.setState({
      advancedSearch: !advancedSearch
    })
  }

  render() {
    const { notifications } = this.state;
    return (
      <WarehouseLayout currentPage="dashboard" unreadNotifications={notifications.filter(x=>!x.read_at)}>
        <div className="warehouse-card warehouse-notifications-panel">
          <h1> Notifications </h1>
          {this.renderAdvancedSearch()}
          <br/>
          <div className="row">
            <div className="col-xs-12">
              {this.renderNotificationTable()}
            </div>
          </div>
          <div className="row text-right">
            <br />
            {this.renderTableControls()}
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

NotificationDashboard.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default NotificationDashboard;
