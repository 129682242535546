import React, { Component } from 'react'
import Cookies from 'universal-cookie'
import {isJaguar, isSuperadmin} from '../../utility/UserUtils'

export default class OrderDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      shipped: this.props.order.shipped,
      billed: this.props.order.billed,
      trackingNumberInput: null,
      savedTrackingNumber: false,
    }
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.handleArchiveOrder = this.handleArchiveOrder.bind(this);
  }

  saveOrder(e, success, error) {
    e.preventDefault();
    const {order} = this.props
    const {shipped, billed, trackingNumberInput} = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/orders/${order.id}`, {
      method: "PATCH",
      redirect: "manual",
      body: JSON.stringify({
        shipped: shipped,
        billed: billed,
        tracking_number: trackingNumberInput && trackingNumberInput.length > 0 ? trackingNumberInput : order.tracking_number
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      console.log(json);
      if (!json.errors) {
        if (billed) {
          this.handleArchiveOrder();
        }
        success(json)
      } else {
        error(json)
      }
    });
  }

  handleArchiveOrder() {
    const {archiveOrder, order} = this.props
    archiveOrder(order.id).then(() => this.toggleDropdown())
  }

  redirectToTracking() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch("/orders", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify(values),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.errors == null) {
        window.location = `/orders?user_id=${user.id}`
      } else {
        alert(json.errors);
      }
    });
  }

  toggleDropdown() {
    const {open} = this.state;
    this.setState({
      open: !open
    })
  }

  renderShippingInfo() {
    const {order} = this.props
    const {shipping_address} = order
    return (
      <div>
        Shipping:
        <br />
        <br />
        {shipping_address.venue_name}
        <br />
        {shipping_address.address_line_1}
        {shipping_address.address_line_2.length > 0 && <><br />{shipping_address.address_line_2}</>}
        <br />
        {shipping_address.city + ", " + shipping_address.state_province}
        <br />
        {shipping_address.country + " " + shipping_address.postal_code}
        {shipping_address.first_name.length > 0 && <><br /><br />Address to: {shipping_address.first_name + " " + shipping_address.last_name}</>}
        {shipping_address.email.length > 0 && <><br/>Email: {shipping_address.email}</>}
        {shipping_address.phone.length > 0 && <><br/>Phone: {shipping_address.phone}</>}
        {order.arrive_by && order.arrive_by.length > 0 && <><br />Arrive by: {new Date(order.arrive_by).toLocaleDateString("en-US")}</>}
      </div>
    )
  }

  renderAdminActions() {
    const {user, order, deleteOrder} = this.props
    const {shipped, billed} = this.state
    if (isJaguar(user) || isSuperadmin(user)) {
      return (
        <>
          <form onSubmit={(e) => this.saveOrder(e, this.toggleDropdown, (json) => alert(json.errors))}>
            <div>Admin</div>      
            <table>
              <tr>
                <td>Shipped</td>
                <td><input type="checkbox" checked={shipped} onChange={(e) => this.setState({shipped: e.target.checked})}/></td>
              </tr>
              <tr>
                <td>Billed</td>
                <td><input type="checkbox" checked={billed} onChange={(e) => this.setState({billed: e.target.checked})}/></td>
              </tr>
            </table>     
            <br />
            {!order.archived && <><button type="button" onClick={this.handleArchiveOrder}>Archive</button><br /><br /></>}
            <button type="button" onClick={() => deleteOrder(order.id)}>Delete</button>
            <button type="submit">Save</button>
          </form>
        </>
      )
    }
  }

  renderTracking() {
    const {order, user} = this.props
    const {savedTrackingNumber, trackingNumberInput} = this.state;
    return (
      <>
        Tracking: 
        { 
          order.tracking_number || savedTrackingNumber
          ?
          <a target="_blank" href={`/orders/${order.id}/get_tracking_link?tracking_number=${order.tracking_number ? order.tracking_number : trackingNumberInput}`}>
            {order.tracking_number ? order.tracking_number : trackingNumberInput}
          </a> 
          :
          ( 
            isJaguar(user) || isSuperadmin(user) 
            ? 
            <>
              <input type="text" onChange={(e) => this.setState({trackingNumberInput: e.target.value})}/>
              <button onClick={(e) => this.saveOrder(e, () => {this.setState({savedTrackingNumber: true})}, (json) => alert(json.errors))}>
                Save
              </button>
            </> 
            : 
            "Unavailable" 
          )
        } 
        
      </>
    )
  }

  renderOrderShippingStatus() {
    const {shipped} = this.state;
    if (shipped) {
      return (
        <div className='order-dropdown-status-shipped'>
          Shipped
        </div>
      )
    } else {
      return (
        <div className='order-dropdown-status-in-progress'>
          In Progress
        </div>
      )
    }
  }

  render() {
    const {open} = this.state;
    const {order, user} = this.props;
    const {order_items, renter} = order;
    const created_by = order_items[0] ? order_items[0].user : {user: {first_name: "N/A"}};
    return (
      <div className="order-dropdown-container">
        <div className='order-dropdown-row' onClick={this.toggleDropdown}>
          <div className='order-dropdown-number'>
            { !order.archived ? <>Order #{order.id}</> : <i>Archived - Order#{order.id}</i> }
          </div>
          <div className="order-dropdown-row-right">
            <div className='order-dropdown-status-container'>
              <div className='order-dropdown-status'>
                {this.renderOrderShippingStatus()}
              </div>
            </div>
            {user.role !== 'client' &&
              <div className='order-user-renter-info'>
                Renter: {renter ? renter.name : "N/A"}
                <br />
                Created by: {created_by.first_name + " " + created_by.last_name}
              </div>
            }
            <div>
              <span class={`fas fa-caret-${open ? "down" : "left"}`}></span>
            </div>
          </div>
        </div>
        {open &&
          <div className='order-dropdown'>
            <div className='order-dropdown-items'>
              <label>
                Order Items:
              </label>
              <ul>
                {order_items.map(order_item => (<li><a href={`/items/${order_item.item.id}`}>{order_item.item.name}</a> x {order_item.quantity}</li>))}  
              </ul>
              <br />
              <div>
                Created: {new Date(order.created_at).toLocaleString('en-US')}
              </div>
              <br />
              {this.renderAdminActions()}
            </div>  
            <div className='order-dropdown-tracking'>
              {this.renderShippingInfo()}
              <br />
              {this.renderTracking()}
            </div>
          </div>
        }
      </div>
    )
  }
}
