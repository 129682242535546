import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import { isJaguar, isSuperadmin, isClient } from "../../utility/UserUtils"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class ReceiveItemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      returned: this.props.item.quantity,
      damaged: null,
      notes: "",
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.receiveItem = this.receiveItem.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  renderAccessButton() {
    const { accessButton, collected } = this.props;
    if (accessButton) {
      return (
        <a onClick={!accessButton.props.disabled ? this.openModal : ""}>
          {accessButton}
        </a>
      )
    }
    return (
      <div className="item-preview-button" onClick={this.openModal}>
        Receive
      </div>
    )
  }

  receiveItem() {
    const { event, item, setWarehouseReceived } = this.props;
    const { damaged, returned, notes } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/events/${event.id}/event_item_fields/${item.eventItemFieldsId}/receive`, {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify({
        damaged: damaged,
        lost_or_consumed: item.quantity - returned,
        item_id: item.id,
        warehouse_received: returned - damaged,
        notes: notes
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      if (!response.error) {
        setWarehouseReceived(returned - damaged)
      } else {
        alert(response.error);
      }
      this.closeModal();
    })
    .catch(err => {
      console.error(err);
    })
  }

  render() {
    const { item, event, collected } = this.props;
    const { returned, damaged, notes } = this.state;
    return(
      <>
        {this.renderAccessButton()}
        <Modal
          className="react-modal item-card-quick-view-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Receive Items"
        >
          <h2><strong>Receive item: {item.name}</strong></h2>
          <div >
            <table style={{margin: "20px auto", width: "100%"}}>
              <tr>
                <td>
                  Shipped:
                </td>
                <td>
                  {item.quantity}
                </td>
              </tr>
              <tr>
                <td style={{paddingTop: "10px"}}>
                  Collected:
                </td>
                <td style={{paddingTop: "10px"}}>
                  {collected}
                </td>
              </tr>
              <tr>
                <td style={{borderBottom: "1px solid black"}}></td>
                <td style={{borderBottom: "1px solid black", padding: "5px 0"}}></td>
              </tr>
              <tr>
                <td style={{paddingTop: "10px"}}>
                  Returned:
                </td>
                <td style={{paddingTop: "10px"}}>
                  <input className="form-control" value={returned} max={item.quantity} min={0} onChange={(e) => this.setState({returned: e.target.value})} type="number"/>
                </td>
              </tr>
              <tr>
                <td style={{paddingTop: "10px"}}>
                  Damaged (of those returned):
                </td>
                <td style={{paddingTop: "10px"}}>
                  <input className="form-control" value={damaged} max={returned} min={0} onChange={(e) => this.setState({damaged: e.target.value})} type="number"/>
                </td>
              </tr>
              <tr>
                <td style={{borderBottom: "1px solid black"}}></td>
                <td style={{borderBottom: "1px solid black", padding: "5px 0"}}></td>
              </tr>
              <tr>
                <td style={{paddingRight: "20px", paddingTop: "15px"}}>
                  Lost/Consumed:
                </td>
                <td style={{paddingTop: "15px"}}>
                  {item.quantity - returned}
                </td>
              </tr>
              <tr>
                <td style={{paddingTop: "15px"}}>
                  Remaining:
                </td>
                <td style={{paddingTop: "15px"}}>
                  {returned - damaged}
                </td>
              </tr>
            </table>
            <label>Notes:</label>
            <textarea className="form-control" value={notes} onChange={e => this.setState({notes: e.target.value})}/>
          </div>
          <button className="btn pull-right" onClick={this.receiveItem} disabled={returned - damaged < 0 || returned > item.quantity} style={{marginTop: "25px"}}>
            Confirm
          </button>
        </Modal>
      </>
    )
  }
}

export default ReceiveItemModal
