import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import ItemImageComponent from "./components/ItemImageComponent"
import ItemHoldForm from "../events/holds/ItemHoldForm"
import ItemMessageComponent from "./components/ItemMessageComponent"
import { isJaguar, isSuperadmin, isClient } from "../utility/UserUtils"
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class ItemCardQuickViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      item: null, //State item is used for everything in this modal except for name and id.
      activeImage: props.item.active_image,
      events: [],
      messageMenuOpen: false,
    }
    this.imageComponentReference = React.createRef();
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeMessageMenu = this.closeMessageMenu.bind(this);
    this.openMessageMenu = this.openMessageMenu.bind(this);
  }

  openModal(e) {
    const { item } = this.props;
    e.stopPropagation();
    this.setState({
      modalOpen: true,
    });

    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/items/${item.id}/quickview`, {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({item: json.item, events: json.events});
        } else {
          alert(json.error);
        }
      });
  }

  closeModal() {
    this.setState({
      modalOpen:false,
      messageMenuOpen: false
    });
  }

  renderAccessButton() {
    const { accessButton } = this.props;
    if (accessButton) {
      return (
        <div onClick={this.openModal}>
          {accessButton}
        </div>
      )
    }
    return (
      <div className="item-preview-button" onClick={this.openModal}>
        Quickview
      </div>
    )
  }

  renderEditLink() {
    const { user, item } = this.props;
    if(isSuperadmin(user)) {
      return (
        <a href={`/warehouse/items/${item.id}/edit`}>
          Edit {item.name}
        </a>
      )
    }
  }

  renderItemDetails() {
    const { item } = this.state;
    const { user } = this.props;
    return (
      <div className="item-information-container">
        {item.rental_cost ?
          <div className="quickview-field rental-price">
            Rental Price: <span> ${parseFloat(item.rental_cost).toFixed(2)} </span>
          </div>
        : "" }
        <div className="quickview-field inventory">
          {item.renter_id ? "In Storage:" : "Own:"}<span> {item.owned_including_variants} </span>
          <br/>
          Currently Available: <span> {item.current_available_including_variants} </span>
        </div>
        {this.renderEditLink()}
        <hr />
        <b>Product Information</b>
        {this.renderDimensions()}
        {this.renderCustomFields()}
        <div>
          <a href={`/items/${item.id}`}>See Full Details</a>
        </div>
        {this.renderEventForm()}
      </div>
    )
  }

  renderCustomFields() {
    const { item } = this.state;
    const { user } = this.props;
    if(isJaguar(user) && item.custom_fields) {
      const customFields = JSON.parse(item.custom_fields).fields
      if(customFields) {
        return customFields.map(field => (
          <div className="quickview-field custom-price">
            {field.name}: <span> {field.value} </span>
          </div>
        ));
      }
    }
  }

  renderEventForm() {

  }

  renderDimensions() {
    const { item } = this.props;
    const dimensions = [
      {label: "H", value:item.product_height},
      {label: "W", value:item.product_width},
      {label: "L", value: item.product_length}
    ].filter(x=> x.value)
    if( dimensions.length > 0) {
      return (
        <div className="quickview-field product_dimensions">
          Dimensions: <span> {dimensions.map(d => `${d.value}"${d.label}`).join(" x ")} </span>
        </div>
      )
    }
  }

  openMessageMenu(){
    this.setState({
      messageMenuOpen: true
    })
  }

  closeMessageMenu(){
    this.setState({
      messageMenuOpen: false
    })
  }

  renderItemSections() {
    const { item, messageMenuOpen, events} = this.state;
    const { user } = this.props;
    if(item) {
      return (
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ItemImageComponent ref={this.imageComponentReference} item={this.state.item} />
          </div>
          <div className="col-xs-12 col-sm-6">
            {messageMenuOpen ? "":
                <React.Fragment>
                {this.renderItemDetails()}
                {events.filter(x=>x.archived_at == null).length > 0 ? (
                  <React.Fragment>
                  <hr />
                  <ItemHoldForm imageComponentReference={this.imageComponentReference} size="small" item={this.state.item} user={user} events={this.state.events}/>
                  </React.Fragment>
                ) : ""}
              </React.Fragment>
            }
            {isClient(user) || !user.id ? (
              <React.Fragment>
                <ItemMessageComponent size="small" onOpen={this.openMessageMenu} onClose={this.closeMessageMenu} key="item-message-component"user={user} item={item}/>
              </React.Fragment>
            ):""}
          </div>
        </div>
      )
    }
    return (
      <h1 className="text-center"> Loading </h1>
    );
  }

  render() {
    const { item } = this.props;
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal item-card-quick-view-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Item Quickview"
        >
          <div className="react-modal-close-button" onClick={this.closeModal}> &#10005; </div>
          <h1> {item.name} </h1>
          {this.renderItemSections()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default ItemCardQuickViewModal
