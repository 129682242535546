import Cookies from "universal-cookie";

export async function fetchAPI(url, callback, options = {}) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  const method = options.method || "GET";
  const body = JSON.stringify(options.body ? options.body : {});

  const fetchJSON = await fetch(url, {
    method: method,
    redirect: "manual",
    body: method == "GET" ? null : body,
    headers: {
      "X-CSRF-Token": token,
      "Content-Type": 'application/json'
    }
  }).then(response => {
    return response.json();
  }).then((json) => {
    if(callback) {
      callback(json);
    }
    return json;
  });

  return fetchJSON;
}
