import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';

class EventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedAddresses: props.event ? props.event.shipping_addresses.map(address =>{return {value: address.id, label: address.venue_name}}) : [],
      selectedRenters: props.event ? props.event.renters.map(renter =>{return {value: renter.id, label: renter.name, editAccess: renter.event_inventories.find(i => i.event_id == this.props.event.id).edit_access}}) : [],
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.updateRenter = this.updateRenter.bind(this);
    this.toggleRenterAccess = this.toggleRenterAccess.bind(this);
    this.addAddress = this.addAddress.bind(this);
    this.addRenter = this.addRenter.bind(this);
    this.getAddressOptions = this.getAddressOptions.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    const { event } = this.props;
    this.setState({
      modalOpen: true,
      selectedAddresses: event ? event.shipping_addresses.map(address =>{return {value: address.id, label: address.venue_name}}) : []
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false,
    });
  }

  addAddress() {
    const { selectedAddresses } = this.state;
    this.setState({
      selectedAddresses: selectedAddresses.concat([{value: null, label: ""}])
    })
  }

  addRenter() {
    const { selectedRenters } = this.state;
    this.setState({
      selectedRenters: selectedRenters.concat([{value: null, label: "", editAccess: true}])
    })
  }

  getAddressOptions() {
    const { shipping_addresses } = this.props;
    const { selectedAddresses } = this.state;
    return shipping_addresses.filter(address=>
      !selectedAddresses.map(address2=> address2.value).includes(address.id)
    ).filter(x=> !x.archived_at).map(address => {
      return {label: address.venue_name, value: address.id}
    })
  }

  getRenterOptions() {
    const { renters } = this.props;
    const { selectedRenters } = this.state;
    return (renters||[]).filter(renter=>
      !selectedRenters.map(renter2=> renter2.value).includes(renter.id) && !renter.jaguar
    ).map(renter => {
      return {label: renter.name, value: renter.id}
    })
  }

  handleStartDateChange(e, formikProps) {
    const startBuffer = moment(e.target.value).subtract(2, 'd').format("YYYY-MM-DD");
    formikProps.setFieldValue("event[start_date]", e.target.value)
    formikProps.setFieldValue("event[buffer_start_date]", startBuffer)
  } 

  handleEndDateChange(e, formikProps) {
    const endBuffer = moment(e.target.value).add(2, 'd').format("YYYY-MM-DD");
    formikProps.setFieldValue("event[end_date]", e.target.value)
    formikProps.setFieldValue("event[buffer_end_date]", endBuffer)
  }

  renderAddresses() {
    const { selectedAddresses } = this.state;
    return selectedAddresses.map((selectedAddress,index) =>
      <>
        <Select
          options={this.getAddressOptions()}
          defaultValue={{value:null,label:"Moonlite"}}
          value={selectedAddress}
          isClearable={true}
          onChange={(value) => this.updateAddress(value,index)}
        />
        <br />
      </>
    )
  }

  renderRenters() {
    const { selectedRenters } = this.state;
    return selectedRenters.map((selectedRenter,index) =>
      selectedRenter.value == null 
      ?
      <>
        <Select
          options={this.getRenterOptions()}
          defaultValue={{value:null,label:""}}
          value={selectedRenter}
          onChange={(value) => this.updateRenter(value,index)}
          isClearable={true}
        />
        <br />
      </>
      :
      <>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <div>
            {selectedRenter.label}
          </div>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <div>
              <label>Edit Access</label>
              <input onClick={() => this.toggleRenterAccess(index)} checked={selectedRenter.editAccess} type="checkbox" />
            </div>
            <div style={{paddingLeft: "15px"}} onClick={(value) => this.updateRenter(null, index)}>
              X
            </div>
          </div>
        </div>
      </>
    )
  }


  updateAddress(selectedValue, index) {
    let { selectedAddresses } = this.state;
    if(selectedValue == null) {
      selectedAddresses = selectedAddresses.filter((x,i) => i != index);
    }else {
      selectedAddresses[index] = selectedValue;
    }

    this.setState({
      selectedAddresses
    });
  }

  updateRenter(selectedValue, index) {
    console.log({selectedValue})
    let { selectedRenters } = this.state;
    if(selectedValue == null) {
      selectedRenters = selectedRenters.filter((x,i) => i != index);
    }else {
      selectedRenters[index] = selectedValue;
    }
    this.setState({
      selectedRenters
    });

  }

  toggleRenterAccess(index) {
    let {selectedRenters} = this.state;
    selectedRenters[index].editAccess = !selectedRenters[index].editAccess;
    this.setState({
      selectedRenters
    })
  }

  renderAccessButton() {
    const { button } = this.props;
    if( button ) {
      return (
        <div onClick={this.openModal}>
          {button}
        </div>
      );
    }
    return (
      <button
        type="button"
        role="button"
        onClick={this.openModal}
        className="btn btn-green"
      >
        Add Event
      </button>
    );
  }

  renderSaveButton(formikProps) {
    return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Save </button>
  }

  formMethod() {
    const { event } = this.props;
    if( event ){
      return 'PATCH'
    }
    return 'POST'
  }

  formURL() {
    const { event } = this.props;
    if( event ){
      return  `/events/${event.slug}`
    }
    return `/events/`
  }

  renderForm(){
    const { event } = this.props;
    const { selectedAddresses, selectedRenters } = this.state;
    return (
      <Formik
        initialValues={{
          event: {
            name: event ? event.name : null,
            start_date: event ? event.start_date : null,
            end_date: event ? event.end_date : null,
            buffer_start_date: event ? event.buffer_start_date : null,
            buffer_end_date: event ? event.buffer_end_date : null,
          }
        }}

        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          values['addresses'] = selectedAddresses.filter(x => x.value).map(x => x.value);
          values['renters'] = selectedRenters.filter(x => x.value).map(x => ({id: x.value, edit_access: x.editAccess}));
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
          .then(response => {
            return response.json();
          })
          .then(json => {
            if (json.error == null) {
              if(this.props.reload){
                document.location.href = `/events/${json.event.slug}`
              }else {
                this.closeModal();
                if(event) {
                  this.props.updateEvent(json.event);
                } else {
                  this.props.addEvent(json.event);
                }
              }
            } else {
              alert(json.error);
            }
          });
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-attachment-form">
            <div className="row">
              <div className="col-xs-12">
                <label> Name: </label>
                <Field className="form-control" name="event[name]" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <label> Start Date: </label>
                <Field onChange={(e) => this.handleStartDateChange(e, formikProps)} className="form-control" name="event[start_date]" type="date"/>
              </div>
              <div className="col-xs-6">
                <label> End Date: </label>
                <Field onChange={(e) => this.handleEndDateChange(e, formikProps)} className="form-control" name="event[end_date]" type="date"/>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <label> Buffer Start Date: </label>
                <Field className="form-control" name="event[buffer_start_date]" type="date"/>
              </div>
              <div className="col-xs-6">
                <label> Buffer End Date: </label>
                <Field className="form-control" name="event[buffer_end_date]" type="date"/>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <br/>
                <b> Addresses </b>
                <br/>
                {this.renderAddresses()}
                <a href="#" onClick={this.addAddress}> Click here to add another address </a>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <br/>
                <b> Connected Renters </b>
                <br/>
                People connected to these inventories will be allowed to make and remove holds in this event.
                <br />
                {this.renderRenters()}
                <a href="#" onClick={this.addRenter}> Click here to add another renter </a>
              </div>
            </div>
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-attachment-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Attachment"
        >
          {this.renderForm()}
        </Modal>
      </>
    )
  }
}

export default EventModal
