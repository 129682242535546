import React, { Component } from 'react'
import PublicCatalogLayout from '../layouts/PublicCatalogLayout'
import Cookies from 'universal-cookie'
import { Formik, Field, Form } from "formik";
import { isJaguar, isSuperadmin, isClient } from "../utility/UserUtils"
import CartItemShowCard from './partials/CartItemShowCard';

export default class NewOrderPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      orderItems : this.props.order_items,
      selectedShippingAddress: {value: null, label: "Select a shipping location"},
      requestedArrivalDate: null,
    }
    this.removeItemFromCart = this.removeItemFromCart.bind(this);
  }
  
  //Cart

  removeItemFromCart(id) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/order_items/${id}`, {
      method: "DELETE",
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      console.log(json)
      if (!json.errors) {
        let {orderItems} = this.state;
        orderItems.splice(orderItems.findIndex((orderItem) => orderItem.id == id), 1);
        this.setState({orderItems: orderItems})
      } else {
        alert(json.errors);
      }
    });
  }

  renderCartItems() {
    const {orderItems} = this.state;
    return (
      orderItems.length == 0 ? "There are no items in your cart" : orderItems.map((orderItem) => (<CartItemShowCard orderItem={orderItem} item={orderItem.item} removeItemFromCart={this.removeItemFromCart.bind(this)}/>)) 
    )
  }

  renderRenter() {
    //TODO: Make this able to be changed for cases where a user belongs to multiple renters
    const {user} = this.props
    if (user.role == ('jaguar' || 'superadmin')) {
      return;
    }
    const renter = user.renters[0] ? user.renters[0].name : "N/A"
    return (
      <div>Renter: {renter}</div>
    )
  }

  //Shipping Address form

  renderShippingAddressForm() {
    const { address, user } = this.props;
    const {orderItems} = this.state;
    return (
      <Formik
        initialValues={{
          arrival_date: "",
          renter_id: user.renters[0] ? user.renters[0].id : null,
          user_id: user.id,
          shipping_address: {
            venue_name: "",
            first_name: "",
            last_name: "",
            address_line_1: "",
            address_line_2: "",
            city: "",
            state_province: "",
            postal_code: "",
            country: "",
            phone: "",
            email: "",
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch("/orders", {
            method: "POST",
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.errors == null) {
                window.location = `/orders?user_id=${user.id}`
              } else {
                alert(json.errors);
              }
            });
        }}
        render={(formikProps) => (
          <Form id="item-attachment-form">
            <div className="row">
              <div className="col-xs-12">
                <label for="shipping_address[venue_name]"> Location Name: (e.g. Aria, Jaguar Design Studio - Aptos) </label>
                <Field required="true" className="form-control" name="shipping_address[venue_name]" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <label> Recipient First Name: </label>
                <Field className="form-control" name="shipping_address[first_name]" />
              </div>
              <div className="col-xs-12 col-sm-6">
                <label> Recipient Last Name: </label>
                <Field className="form-control" name="shipping_address[last_name]" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <label> Contact Phone #</label>
                <Field className="form-control" name="shipping_address[phone]" />
                <label> Contact Email</label>
                <Field className="form-control" name="shipping_address[email]" />
                <label> Address Line #1</label>
                <Field className="form-control" name="shipping_address[address_line_1]" />
                <label> Address Line #2</label>
                <Field className="form-control" name="shipping_address[address_line_2]" />
                <label> City</label>
                <Field className="form-control" name="shipping_address[city]" />
                <label> State / Province </label>
                <Field className="form-control" name="shipping_address[state_province]" />
                <label> Postal Code </label>
                <Field className="form-control" name="shipping_address[postal_code]" />
                <label> Country </label>
                <Field className="form-control" name="shipping_address[country]" />
                <br />
                <br />
                <label>Requested Arrival Date</label>
                <Field className='form-control' type="date" name="arrival_date"/>
                <br />
                <button disabled={orderItems.length == 0} type="submit" className="event-dashboard-add-event">
                  Confirm Order
                </button>
              </div>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return (
      <PublicCatalogLayout
        {...this.props}
        groups={[]}
        currentPage="new_order"
        classNames={{body: 'white-background'}}
      >
        <div className='full-body-container'>
          <div>
            <h2>Your Cart</h2>
            {this.renderRenter()}
            {this.renderCartItems()}
          </div>
          <div>
            <h2>Shipping</h2>
            <div>
              {this.renderShippingAddressForm()}
            </div>
          </div>
        </div>
      </PublicCatalogLayout>
    )
  }
}
