import React, { Component } from 'react'
import WarehouseLayout from '../WarehouseLayout'

export default class AllInventoryLogs extends Component {

  renderLogsTable() {
    let {inventory_logs} = this.props;
    return (
      <table className='warehouse-logs-table'>
        <tr>
          <th className="item-name-col">Item Name</th>
          <th className="delta-col">Δ</th>
          <th className="reason-col">Reason</th>
          <th className="notes-col">Notes</th>
          <th className="user-col">User</th>
          <th className="date-col">Date</th>
        </tr>
        {
          inventory_logs.map(log => 
            <tr key={log.id}>
              <td>{log.item.name}</td>
              <td>{log.count}</td>
              <td>{log.reason}</td>
              <td>{log.notes}</td>
              <td>{log.user ? log.user.first_name + " " + log.user.last_name : ""}</td>
              <td>
                {new Date(log.created_at).toLocaleString(
                  [], 
                  {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'}
                )}
              </td>
            </tr>
          )
        }
      </table>
    )
  }

  render() {
    const {inventory_logs} = this.props
    console.log(inventory_logs)
    return (
      <WarehouseLayout className="warehouse-item-index">
        <h1>Inventory Logs</h1>
        <div className="row">
          <div className="col-xs-12">
            <div className="warehouse-card">
              {this.renderLogsTable()}
            </div>
          </div>
        </div>
      </WarehouseLayout>
    )
  }
}
