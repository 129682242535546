import React from "react";
import ReactDOM from "react-dom/client";

export default function mount_component(components, filenames): void {
  document.addEventListener("DOMContentLoaded", () => {
    const mountPoints = document.querySelectorAll("[data-react-class]");
    mountPoints.forEach((mountPoint) => {
      const { dataset } = mountPoint as HTMLElement;
      const componentName = dataset.reactClass;
      if (componentName) {
        const componentIndex = filenames.findIndex((x) => x.replace("./","").replace("components/","").replace(".jsx","").replace(".js") === componentName);
        const Component = components[componentIndex];
        if (Component) {
          const props = JSON.parse(dataset.reactProps as string);
          const root = ReactDOM.createRoot(mountPoint);
          root.render(<Component {...props} />);
        } else {
          console.warn(
            "WARNING: No component found for: ",
            dataset.reactClass,
            components
          );
        }
      }
    });
  });
}