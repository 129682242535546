import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import { Tooltip } from "react-tooltip";

class ItemInventoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { children, destroy, outerStyle } = this.props;
    if(children) {
      return (
        <div style={{...outerStyle, cursor:"pointer", display: "inline-block"}} onClick={(e) => this.openModal(e)} >
          {children}
        </div>
      )
    }
    if(destroy) {
       return (<button type="button" role="button" onClick={this.openModal} className="col-xs-12 btn btn-danger">Destroy Inventory</button>)
    }
    return (<button type="button" role="button" onClick={this.openModal} className="col-xs-12 btn btn-green">Add Inventory</button>)
  }

  renderSaveButton(formikProps){
    return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Save </button>
  }

  formMethod() {
    return 'POST'
  }

  formURL() {
    const { item } = this.props;
    return `/webapi/items/${item.id}/create_inventory_update/`
  }

  renderTitle() {
    if(this.props.destroy){
      return <h3>Destroying Inventory from {this.props.item.name}</h3>
    }
     return <h3>Adding Inventory to {this.props.item.name}</h3>
  }

  renderSubtitle(){
    if(this.props.destroy) {
      return "* Inventory destroyed will be logged for the end of the year. If you accidentaly added some, try editing the inventory log"
    }
  }

  renderForm(){
    const { updateItem } = this.props;
    return (
      <Formik
        initialValues={{
          item_inventory_log: {
            count: 0,
            recieving_item: false,
            tracking_number: "",
          }
        }}
        onSubmit={(values, actions) => {
          var data = new FormData();
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          data.append("item_inventory_log[recieving_item]", values["item_inventory_log"]["recieving_item"])
          data.append("item_inventory_log[count]", this.props.destroy ? -1*values["item_inventory_log"]["count"] : values["item_inventory_log"]["count"])
          data.append("item_inventory_log[tracking_number]", values["item_inventory_log"]["tracking_number"])
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: data,
            headers: {
              "X-CSRF-Token": token
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.closeModal();
                this.props.updateInventoryCount(json.item_inventory_log.theoretical_total, json.currently_in_warehouse)
                if(updateItem) {
                  updateItem(json.item)
                }
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-image-form">
            {this.renderTitle()}
            <div className="row">
              <div className="col-xs-12">
                <label> Count: </label>
                <Field name="item_inventory_log[count]" className="form-control" type="number" min="0" step="1"/>
              </div>
              {!this.props.destroy ? (
                <>
                  <div className="col-xs-12">
                    <br/>
                    <Field name="item_inventory_log[recieving_item]" type="checkbox"/>
                    <label data-tooltip-id="recieving-item">
                      These items are shipping to the warehouse 
                      <img src="/images/info-default.svg"/>
                    </label>
                    <Tooltip id="recieving-item"
                      content={
                        <>
                          Please check this if the item you are adding has not yet been recieved by the warehouse.
                          <br/>
                          <br/>
                          This lets the warehouse staff know that an item is coming, and to expect it.
                        </>
                      }
                    />
                  </div>

                  {
                    formikProps.values.item_inventory_log.recieving_item ? (
                      <div className="col-xs-12">
                        <label> Tracking Number </label>
                        <Field name="item_inventory_log[tracking_number]" className="form-control"/>
                      </div>
                    ):""
                  }
                </>
              ):""}
            </div>
            <br />
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
            {this.renderSubtitle()}
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal inventory-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Manage Inventory"
        >
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default ItemInventoryModal
