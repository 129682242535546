import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@/utility/NetworkUtils";
import PublicCatalogLayout from "@/layouts/PublicCatalogLayout"
import DataGridTableToolbar from "@/utility/DataGridTableToolbar";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
import { 
  Button
} from "@mui/material";

const InventoryTagsManagementDashboard = (props) => {
  const { renter, user } = props;
  const [ tags, setTags ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const fetchItemTags = async ()=> {
    fetchAPI(`/webapi/item_tags?renter_id=${renter.id}`, (json)=> {
      if (!json.errors) {
        setTags(json.tags)
        setLoading(false)
      } else {
        console.log({
          errors: json.errors
        })
      }
    });
  }

  useEffect(() => {
    fetchItemTags()
  }, []);

  const getItems = () => {
    return tags
  }

  const getColumns = () => {
    return [
      {field: "tag", flex: 1, headerName: "Tag"},
      {field: 'Actions', sorting: false, filtering: false, width: 250, headerName: "Actions", renderCell: renderActions},
    ];
  }

  const renderActions = (params) => {
    return (
      <>
        <Button onClick={()=>onDelete(params.row.tag)} size="small" variant="contained" color="primary">Delete</Button>
      </>
    )
  }

  const onDelete = (tag) => {
    if(confirm("Are you sure you want to delete this tag?")) {
      fetchAPI(`/webapi/renters/${renter.id}/item_tags/${tag}`, (json)=> {
        if (!json.errors) {
          fetchItemTags()
        } else {
          alert('Could not delete tag')
          console.log({
            errors: json.errors
          })
        }
      }, {
        method: "DELETE",
        body: {
          renter_id: renter.id
        }
      });
    }
  }

  const renderTagTable = () => {
    return (
      <DataGridPremium
        autoHeight
        unstable_headerFilters
        rows={getItems()}
        columns={getColumns()}
        slots={{
          toolbar: DataGridTableToolbar
        }}
        style={{
          cell: {
            paddingBottom: 0,
            paddingRight: 0,
            whiteSpace: 'normal',
            overflow: 'visible',
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
      />
    )
  }
  return (
    <PublicCatalogLayout
      classNames={{container: 'full-body-container inventory-dashboard'}}
      className="inventory-management-dashboard"
      currentPage=""
      {...props}
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        renderTagTable()
      )}
    </PublicCatalogLayout>
  );
};

export default InventoryTagsManagementDashboard