import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@/utility/NetworkUtils";
import PublicCatalogLayout from "@/layouts/PublicCatalogLayout"
import WarehouseCategoriesTable from "@/warehouse/categories/WarehouseCategoriesTable";
const InventoryCategoriesManagementDashboard = (props) => {
  const { renter, user } = props;

  const [ categories, setCategories ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  return (
    <PublicCatalogLayout
      classNames={{container: 'full-body-container inventory-dashboard'}}
      className="inventory-management-dashboard"
      currentPage=""
      {...props}
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        <WarehouseCategoriesTable renter={renter} user={user} />
      )}
    </PublicCatalogLayout>
  );
};

export default InventoryCategoriesManagementDashboard