import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import ItemCard from "../items/components/ItemCard"
import { isJaguar, isSuperadmin } from "../utility/UserUtils"
import KitCard from "../kits/KitCard";

class InventoryDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: "All",
    }
  }


  renderItems() {
    let { items, user } = this.props;
    return this.getSortedItems().sort((a,b) => (a.name < b.name ? -1 : 1)).filter(item=> !item.variant_of_id).map(item => (
      <ItemCard key={`item-card-${item.id}`} inventory={true} item={item} user={user}/>
    ))
  }

  renderKits() {
    let { user } = this.props;
    return this.getSortedKits().map(kit => (
      <KitCard inventory={true} kit={kit} user={user}/>
    ))
  }

  getSortedItems(){
    let { items } = this.props;
    const { selectedGroup } = this.state;
    if(selectedGroup=="All"){

    }else if (selectedGroup == "Other"){
      items = items.filter(item => !item.category || !item.category.group_name)
    }else {
      items = items.filter(item=> item.category && item.category.group_name == selectedGroup)
    }
    items = items.filter(item => item.owned_including_variants > 0);
    return items;
  }

  getSortedKits() {
    let { kits } = this.props;
    return kits.sort((a, b) => 
      (a.name < b.name ? -1 : 1)
    )
  }

  getFullSquareFootage(){
    const { renter } = this.props;
    const size = renter.stored_volume || 0
    return Math.round(size/1728)
  }

  getRentersFullPrice(){
    const { renter } = this.props;
    const price = renter.monthly_cost || 0
    return (price * 12).toFixed(2);
  }

  renderCategories() {
    const { groups, renter, categories } = this.props;

    return groups.filter(g => g).map(group => (
      <div key={"group"+group} className="col-xs-12 col-sm-6 col-md-4 catalog-dashboard-group-container">
        <a href={`/inventory/${renter.id}/category/${group}`} className="catalog-dashboard-group row">
          <div className="col-xs-6 image-container">
            <div className="image" style={{color: "red", backgroundImage: `url(/group/${group}/group_image.jpg?renter_id=${renter.id})`}}>
              &nbsp;
            </div>
          </div>
          <div className="col-xs-6">
            <a href={`/inventory/${renter.id}/category/${group}`}><h2>{group || "Other"}</h2></a>
            {this.renderSubCategories(group)}
            <a className="category-link" href={`/category/${group}`}>
              and more
            </a>
          </div>
        </a>
      </div>
    ));
  }

  renderSubCategories(group) {
    const { categories, renter } = this.props;
    const actualGroup = (group == "Other") ? null : group;
    return categories.filter(category => category.group_name == actualGroup).filter((unused,index) => index < 4).map(category => (
      <a className="category-link" href={`/inventory/${renter.id}/category/${escape(group).replace("/","%2F")}/${escape(category.name).replace("/","%2F")}`}>
        {category.name}
      </a>
    ));
  }

  renderManagement() {
    const { renter } = this.props;
    return (
      <>
        <div  className="col-xs-12 text-center col-sm-6 col-md-4 catalog-dashboard-group-container">
          <a style={{display: "flex",alignItems: "center", justifyContent: "center"}} href={`/inventory/${renter.id}/items`} className="catalog-dashboard-group row">
            <h2>Manage Inventory</h2>
          </a>
        </div>
        <div  className="col-xs-12 text-center col-sm-6 col-md-4 catalog-dashboard-group-container">
          <a style={{display: "flex",alignItems: "center", justifyContent: "center"}} href={`/inventory/${renter.id}/categories`} className="catalog-dashboard-group row">
            <h2 >Manage Categories</h2>
          </a>
        </div>
        <div  className="col-xs-12 text-center col-sm-6 col-md-4 catalog-dashboard-group-container">
          <a style={{display: "flex",alignItems: "center", justifyContent: "center"}} href={`/inventory/${renter.id}/item_tags`} className="catalog-dashboard-group row">
            <h2 >Manage Tags</h2>
          </a>
        </div>
      </>
    )
  }

  renderReports() {
    const { renter } = this.props;
    if(!renter.jaguar) {
      return (
        <>
        <div className="inventory-dashboard-information-area col-xs-12">
          Cubic Footage:
          <span className="gold-text">
            {this.getFullSquareFootage()} cuft
          </span>
          Annual Storage Cost:
          <span className="gold-text">
            ${this.getRentersFullPrice()}
          </span>
        </div>
        <div className="col-xs-12">
          <a  href={`/warehouse/reports/renter_full_billing?id=${renter.id}`}>Download {renter.name} Inventory Report</a>
        </div>
        </>
      )
    }
  }

  render() {
    const { renter } = this.props;
    const {selectedGroup} = this.state
    return (
      <PublicCatalogLayout
        classNames={{container: 'full-body-container inventory-dashboard'}}
        className="inventory-dashboard"
        renter={renter}
        currentPage={"inventory-"+renter.id}
        
        {...this.props}
      >
        <div className="row">
          <div className="col-xs-12">
            <h1>{renter.name} Inventory </h1>
          </div>
        </div>
        <div className="col-xs-12">
          <div className="row">
            <h2>Management</h2>
            {this.renderManagement()}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
          <h2>Categories</h2>
          {this.renderCategories()}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
          <h2>Reports</h2>

          {this.renderReports()}
          </div>
        </div>
      </PublicCatalogLayout>
    );
  }
}

InventoryDashboard.propTypes = {

};

export default InventoryDashboard;
