import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class ItemCustomFieldModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { field } = this.props;
    if(field){
      return (<button type="button" className="btn btn-primary" onClick={this.openModal}>Edit</button>)
    }
    return (<button type="button" role="button" onClick={this.openModal} className="btn btn-green">New Custom Field</button>)
  }
  
  renderSaveButton(formikProps){
    if(formikProps.dirty){
      return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Save </button>
    }
    return <button className="btn btn-primary pull-right"type="button"  disabled> Save </button>
  }

  formMethod() {
    const { image } = this.props;
    if(image){
      return 'PATCH'
    }
    return 'POST'
  }

  formURL() {
    const { image, item } = this.props;
    if(image){
      return  `/warehouse/items/${item.id}/images/${image.id}`
    }
    return `/warehouse/items/${item.id}/images/`
  }

  renderForm(){
    const { field, index } = this.props;
    return (
      <Formik
        initialValues={{
          field: {
            name: field ? field.name : "",
            value: field ? field.value : "",
            type: "string"
          }
        }}
        onSubmit={(values, actions) => {
          if(this.props.field) {
            this.props.updateCustomField(index, values.field);
          }
          else {
            this.props.addCustomField(values.field);
          }
          actions.resetForm();
          this.closeModal();
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-image-form">
            <h1>Custom Field:</h1>
            <div className="row">
              <div className="col-xs-12">
                <label> Name: </label>
                <Field name="field[name]" className="form-control" />
              </div>
              <div className="col-xs-12">
                <label> Value: </label>
                <Field name="field[value]" className="form-control" />
              </div>
              <Field name="field[type]" type="hidden" />
            </div>
            <br />
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal}> Cancel </button>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-image-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Image"
        >
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default ItemCustomFieldModal