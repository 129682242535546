import React, { useEffect, useState } from "react";

import Modal from "react-modal";
import Cookies from "universal-cookie";

const ItemLogModal = (props) => {
  const { item, open, onClose} = props
  const [ logs, setLogs ] = useState([])

  useEffect(() => {
    if(open) {
      loadLogs()
    }
  },[open])

  const loadLogs = async () => {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/webapi/items/${item.id}/logs`, {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          setLogs(json.logs)
        }
      })
  }

  const renderLogs = () => {
    return (
      <div>

      </div>
    )
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className="react-modal inventory-modal add-form-modal"
      overlayClassName="react-modal-overlay"
    >
      <div className="modal-header">
        <div className="modal-title">Logs</div>
      </div>
      <div className="modal-body">
        <table className="table">
          <thead>
            <tr>
              <th className="delta-col">Δ</th>
              <th className="reason-col">Reason</th>
              <th className="notes-col">Notes</th>
              <th className="user-col">User</th>
              <th className="date-col">Date</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log, index) => {
              return (
                <tr key={index}>
                  <td>{log.count}</td>
                  <td>{log.reason}</td>
                  <td>{log.notes}</td>
                  <td>{log.user ? log.user.first_name + " " + log.user.last_name : ""}</td>
                  <td>
                    {new Date(log.created_at).toLocaleString(
                      [], 
                      {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'}
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {renderLogs()}
      </div>
    </Modal>
  )
}

export default ItemLogModal