import React, {Component} from "react";
import PublicCatalogLayout from "../../layouts/PublicCatalogLayout"
import EventSubheader from "../partials/EventSubheader"

export default class ReportsDashboard extends Component {

  render() {
    const {event} = this.props;
    return (
      <PublicCatalogLayout
        currentPage="reports" {...this.props}
        groups={[]}
        subHeader={
          <EventSubheader 
            {...this.props}
          />
        }
      >
        <h1>Reports</h1>
        <div className="reports-dashboard-option">
          <a href={`/events/${event.id}/reports/items`}>Item Reports</a>
        </div>
        <div className="reports-dashboard-option">
          <a href={`/events/${event.id}/reports/losses`}>Loss Report</a>
        </div>
        <div className="reports-dashboard-option">
          <a href={`/events/${event.id}/reports/returned`}>Shipped vs. Returned Report (download)</a>
        </div>
      </PublicCatalogLayout> 
    );
  }
}