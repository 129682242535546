import React,{ Component }  from 'react'
import Modal from "react-modal";
import classNames from "classnames";
import TemporaryItemForm from "./forms/TemporaryItemForm"
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class TemporaryItemFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;
    this.setState({modalOpen: !modalOpen})
  }

  render() {
    const { modalOpen } = this.state;
    const {children, outerClassNames, outerStyle} = this.props;
    return(
      <>
      <div className={`${outerClassNames}`} style={{...(outerStyle||{}), display: "inline"}} onClick={this.toggleModal} >
        {children}
      </div>
      <Modal
        className="react-modal item-card-quick-view-modal"
        overlayClassName="react-modal-overlay"
        isOpen={this.state.modalOpen}
        onRequestClose={this.toggleModal}
      >
        <div className="react-modal-close-button" onClick={this.toggleModal}> &#10005; </div>
        <TemporaryItemForm {...this.props} />
      </Modal>
      </>
    )
  }
}

export default TemporaryItemFormModal
