import React from "react";
import PropTypes from "prop-types";

class WarehouseNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <nav className=" navbar navbar-expand-lg warehouse-navbar navbar-light">
        <button onClick={() => this.props.toggleSidebar()} className="navbar-toggler navbar-toggler align-self-center">
          <span className="fas fa-bars" />
        </button>
        <a className="navbar-brand" href="# ">Warehouse Tool</a>
      </nav>
    );  
  }
}

WarehouseNavbar.propTypes = {

};

export default WarehouseNavbar;
