import React, { Component } from 'react'
import { Formik, Form } from 'formik';
import Select, {components} from 'react-select';
import Creatable from 'react-select/creatable'
import Cookies from "universal-cookie";

export default class KitHoldForm extends Component {
  constructor(props) {
    super(props)
    const {event, item_holds, max_kits_allowed} = this.props
    const hold = item_holds ? item_holds[0] : null;
    let selectedQuantity = event && hold ? this.getSelectedQuantity() : null
    this.state = {
      selectedEvent: event ? {value: event.id, label: event.name} : null,
      selectedAddress: event && hold.shipping_address ? {value: hold.shipping_address_id, label: hold.shipping_address.venue_name} : null,
      selectedDate: event && hold.shipping_date ? hold.shipping_date : null,
      selectedQuantity: event && hold ? {label: selectedQuantity, value: selectedQuantity} : null,
      selectedPallet: event && hold.pallet ? {value: hold.pallet.name, label: hold.pallet.name} : null,
      selectedDistributionLocation: event && hold.event_location ? {value: hold.event_location.name, label: hold.event_location.name} : null,
      selectedRecipient: event && hold.event_recipient ? {value: hold.event_recipient.name, label: hold.event_recipient.name} : null,
      signageCodeInput: event && hold.signage_code ? hold.signage_code : null,
      isDirectToSite: event && hold ? hold.direct_to_site : null,
      notesInput: event && hold.notes ? hold.notes : null,
      kitAvailable: event && hold ? true : null,
      maxKitsAllowed: max_kits_allowed || null,
      fullEventInformation: event || null,
    }
    this.handleEventChange = this.handleEventChange.bind(this);
  }

  getSelectedQuantity() {
    const {item_holds, kit} = this.props
    const hold = item_holds[0];
    const totalOfItemHeld = hold.total_count;
    const itemsPerKit = kit.items.find(x => x.item_id == hold.item_id).item_count
    return totalOfItemHeld / itemsPerKit
  }

  handleEventChange(e) {
    this.setState({
      selectedEvent: e
    })
    this.getEventInformation(e.value, this.state.selectedDate)
  }

  getEventInformation(event_id, shipping_date) {
    console.log("Fetching Event Info: ", event_id);
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { kit } = this.props;
    if(kit.id && event_id) {
      fetch(`/kits/${kit.id}/check_event_availability`, {
        method: 'POST',
        redirect: "manual",
        body: JSON.stringify({event_id: event_id, shipping_date: shipping_date}),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.code == 200) {
          console.log(json);
          this.setState({kitAvailable: true, selectedDate: json.event.buffer_start_date, maxKitsAllowed: json.kits_available, fullEventInformation: json.event})
        } else if (json.code == 405){
          alert(json.message);
          this.setState({kitAvailable: false})
        }
      });
    }
  }

  getEventOptions() {
    const {events} = this.props;
    if (events) {
      return events.filter(event => 
        event.archived_at == null
      ).map(event => ({label: event.name, value: event.id}))
    } else {
      return [];
    }
  }

  getQuantityOptions() {
    const {event} = this.props
    let { maxKitsAllowed, selectedQuantity } = this.state;
    
    if (event && selectedQuantity) {
      maxKitsAllowed += selectedQuantity.value
    }
    if(maxKitsAllowed && maxKitsAllowed > 0) {
      return new Array(maxKitsAllowed+1).fill(null).map((v,i)=> {return {value: i, label: i}});
    }
    return [{value: 0, label: 0}];
  }

  getShippingAddressOptions() {
    const {fullEventInformation} = this.state;
    return fullEventInformation ? fullEventInformation.shipping_addresses.map(
      address => ({value: address.id, label: address.venue_name})
    ) : []
  }

  getPalletOptions() {
    const {fullEventInformation} = this.state;
    return fullEventInformation ? fullEventInformation.pallets.map(
      pallet => ({value: pallet.name, label: pallet.name})
    ).sort((a, b) => {
      return a.label.localeCompare(b.label, undefined, {
        numeric: true,
        sensitivity: 'base'
      })
    }) : []
  }

  getDistributionLocationOptions() {
    const {fullEventInformation} = this.state;
    return fullEventInformation ? fullEventInformation.event_locations.map(
      location => ({value: location.name, label: location.name})
    ).sort((a,b) => {
      return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
    }) : []
  }

  getRecipientOptions() {
    const {fullEventInformation} = this.state;
    return fullEventInformation ? fullEventInformation.event_recipients.map(
      recipient => ({value: recipient.name, label: recipient.name})
    ).sort((a,b) => {
      return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
    }) : []
  }

  //TODO: Refactor this to actually use Formik state and props
  render() {
    const {selectedEvent, selectedAddress, selectedDate, selectedQuantity, selectedPallet, isDirectToSite,selectedDistributionLocation, selectedRecipient, signageCodeInput, notesInput, kitAvailable, fullEventInformation} = this.state
    const {event, kit, kit_hold} = this.props
    return (
      <>
        <Formik
          initialValues={{}}
          validate={values => {
          }}
          onSubmit={ (values, actions) => {
            const cookies = new Cookies();
            const token = cookies.get("X-CSRF-Token");
            if(selectedEvent && selectedDate && selectedQuantity && selectedQuantity.value > 0) {
              fetch((event && kit_hold ? `/events/${event.id}/kit_holds/${kit_hold.id}` : `/kits/${kit.id}/hold_kit_items`), {
                method: event && kit_hold ? 'PATCH' : 'POST',
                redirect: "manual",
                body: JSON.stringify({
                  hold_params : {
                    item_id: null, //to add later on backend
                    event_id: selectedEvent.value, 
                    shipping_date: selectedDate,
                    total_count: selectedQuantity.value,
                    shipping_address_id: selectedAddress ? selectedAddress.value : null,
                    notes: notesInput,
                    direct_to_site: isDirectToSite,
                    signage_code: signageCodeInput,
                    kit_id: kit.id,
                    kit_hold_id: kit_hold ? kit_hold.id : null,
                  },
                  event_location: selectedDistributionLocation ? selectedDistributionLocation.value : null,
                  recipient: selectedRecipient ? selectedRecipient.value : null,
                  pallet: selectedPallet ? selectedPallet.value : null,
                }),
                headers: {
                  "X-CSRF-Token": token,
                  "Content-Type": 'application/json'
                }
              })
              .then(response => {
                return response.json();
              })
              .then(json => {
                if (json.error) {
                  alert(json.error)
                } else if (json.status == 200) {
                  window.location = json.url
                }
              });
            } else {
              alert("Please fill out all required fields (event, date, quantity)")
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              <div className="row">
                Event Information
              </div>
              <div className="row">
                <Select 
                  value={selectedEvent}
                  options={this.getEventOptions()}
                  placeholder="Select Event"
                  isDisabled={event}
                  onChange={this.handleEventChange}
                  className="kit-show-input col-sm-6"
                />
                <Select
                  value={selectedQuantity}
                  options={this.getQuantityOptions()}
                  placeholder="Select Qty."
                  isDisabled={!fullEventInformation || !kitAvailable}
                  onChange={e => this.setState({selectedQuantity: e})}
                  className="kit-show-input col-sm-6"
                />
              </div>
              <div className='row'>
                Shipping Information
              </div>
              <div className='row'>
                <Select 
                  options={this.getShippingAddressOptions()}
                  value={selectedAddress}
                  placeholder="Select Shipping Location"
                  isDisabled={!fullEventInformation || !kitAvailable}
                  onChange={e => this.setState({selectedAddress: e})}
                  className="kit-show-input col-sm-6"
                />
                <div className='col-sm-6'>
                  <input 
                    type="date" 
                    placeholder="Shipping Date:" 
                    value={selectedDate}
                    disabled={!fullEventInformation || !kitAvailable}
                    onChange={e => this.setState({selectedDate: e.target.value})}
                    className='kit-show-input form-control'
                  />
                </div>
              </div>
              <div className='row'>
                <Creatable
                  value={selectedPallet}
                  placeholder="Select Pallet"
                  options={this.getPalletOptions()}
                  isDisabled={!fullEventInformation}
                  onChange={e => this.setState({selectedPallet: e})}
                  className="kit-show-input col-sm-6"
                  components={{
                    SingleValue: ({ children, ...props }) => {
                      return (
                        <components.SingleValue {...props}>
                          {"Pallet: " + children}
                        </components.SingleValue>
                      );
                    },
                    IndicatorSeparator: () => null
                  }}
                />
                <div className="col-sm-6">
                  <label>Direct to site?</label>
                  <input
                    type="checkbox"
                    onChange={() => this.setState({isDirectToSite: !isDirectToSite})}
                    checked={isDirectToSite}
                    disabled={!fullEventInformation || !kitAvailable}
                  />
                </div>
              </div>
              <div className='row'>
                On Site Information
              </div>
              <div className='row'>
                <Creatable
                  value={selectedDistributionLocation}
                  placeholder="Select Distribution Location"
                  options={this.getDistributionLocationOptions()}
                  isDisabled={!fullEventInformation || !kitAvailable}
                  onChange={e => this.setState({selectedDistributionLocation: e})}
                  className="kit-show-input col-sm-6"
                  components={{
                    SingleValue: ({ children, ...props }) => {
                      return (
                        <components.SingleValue {...props}>
                          {"Distribution Location: " + children}
                        </components.SingleValue>
                      );
                    },
                    IndicatorSeparator: () => null
                  }}
                />
                <Creatable
                  value={selectedRecipient}
                  placeholder="Select Recipient"
                  options={this.getRecipientOptions()}
                  isDisabled={!fullEventInformation || !kitAvailable}
                  onChange={e => this.setState({selectedRecipient: e})}
                  className="kit-show-input col-sm-6"
                  components={{
                    SingleValue: ({ children, ...props }) => {
                      return (
                        <components.SingleValue {...props}>
                          {"Recipient: " + children}
                        </components.SingleValue>
                      );
                    },
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
              <div className='row'>
                Signage Only
              </div>
              <div className='row'>
                <div className='col-sm-6'>
                  <input 
                    placeholder="Signage Code" 
                    value={signageCodeInput} 
                    onChange={e => this.setState({signageCodeInput: e.target.value})} 
                    type="text" 
                    className="form-control kit-show-input" 
                    disabled={!fullEventInformation || !kitAvailable}
                  />
                </div>
              </div>
              <div className='row'>
                Notes:
                <div className='col-sm-12'>
                  <textarea 
                    onChange={e => this.setState({notesInput: e.target.value})} 
                    type="textarea" 
                    value={notesInput} 
                    className="form-control kit-show-input"
                    disabled={!fullEventInformation && !kitAvailable}
                  />
                </div>
              </div>
              <button className="item-hold-form-submit-button" type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </>
    )
  }
}
