import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import Cookies from "universal-cookie";
import EditTagModal from "./EditTagModal"
class TagDashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      item_tags: props.item_tags,
      searchQuery: "",
      selectedLetter: null,
    }

    this.updateSearch = this.updateSearch.bind(this);
    this.getSortedTags = this.getSortedTags.bind(this);
    this.deleteTag = this.deleteTag.bind(this);
    this.selectLetter = this.selectLetter.bind(this);
    this.updateTags = this.updateTags.bind(this);
  }

  updateTags(tags){
    this.setState({item_tags: tags});
  }

  deleteTag(tag) {
    const { item_tags } = this.state;
    if(confirm("Are you sure you want to remove this tag? This cannot be undone.")) {

      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");

      fetch(`/tags/${tag}`, {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            this.setState({
              item_tags: item_tags.filter(tag2 => tag2.tag != tag)
            })
          } else {
            alert(json.error);
          }
        });
    }
  }
  renderTagTable() {
    const sortedTags = this.getSortedTags();
    if(sortedTags.length > 0){
      const rows = sortedTags.map((tag,index) => (
        <React.Fragment>
          <tr>
            <td>{tag.tag}</td>
            <td>{tag.count}</td>
            <td className="text-right">
              <EditTagModal
                button={<a href="#" className="">Edit Tag</a>}
                tag={tag}
                updateTags={this.updateTags}
              />
              &nbsp; &nbsp; 
              <a href="#" className="" onClick={()=>this.deleteTag(tag.tag)}>Delete Tag</a>
            </td>
          </tr>
        </React.Fragment>
      ));

      return (
        <div className="row">
          <div className="col-xs-12">
            <div className=" table-responsive">
              <br />
              <table className="table tag-management-table">
                <thead>
                  <tr>
                    <th> Tag </th>
                    <th> Item Count </th>
                    <th className="actions"> </th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="row">
          <div className="col-xs-12 text-center">
            <br />
            <h1> No Tags Available </h1>
          </div>
        </div>
      )
    }
  }

  getSortedTags(){
    const { item_tags, searchQuery, selectedLetter } = this.state;
    return item_tags.filter(tag => !selectedLetter || tag.tag.toUpperCase().startsWith(selectedLetter)).sort((a,b) => a.count > b.count ? -1 : 1)
  }

  updateSearch(e) {
    this.setState({
      searchQuery: e.target.value
    })
  }

  renderTagAlphabet(){
    const { item_tags } = this.state;
    const alphabet = item_tags.map(tag => tag.tag.toUpperCase().charAt(0)).filter((l,i,self) => self.findIndex(x=>x==l) == i).sort().map(letter => (
      <a className="tag-alphabet-letter" onClick={()=>this.selectLetter(letter)} href="#">
        {letter}
      </a>
    ));
    return (
      <div className="row">
        <div className="col-xs-12">
          Search By Letter:
        </div>
        <div className="col-xs-12 tag-alphabet">
          <a href="#" className="tag-alphabet-letter" onClick={()=>this.selectLetter(null)} >
            All
          </a>
          {alphabet}
        </div>
      </div>
    )
  }

  selectLetter(selectedLetter){
    this.setState({
      selectedLetter: selectedLetter
    })
  }

  render() {
    const { searchQuery } = this.state;
    return (
      <PublicCatalogLayout 
        classNames={{container: 'full-body-container'}}
        currentPage="" {...this.props}
      >
        <h1> Tags </h1>
        {this.renderTagAlphabet()}
        {this.renderTagTable()}
      </PublicCatalogLayout> 
    );
  }
}

TagDashboard.propTypes = {

};

export default TagDashboard;
