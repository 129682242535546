import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import KitShowItemCard from './components/KitShowItemCard';
import Cookies from "universal-cookie";
import { isJaguar, isSuperadmin, isClient } from "../utility/UserUtils"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class KitCardQuickViewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      events: [],
      kit: this.props.kit
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderItems = this.renderItems.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  renderItems() {
    const {kit} = this.state
    if (kit.items && kit.items.length > 0) {
      return (
        <>
        {
          kit.items.map(item => {
            return (<KitShowItemCard item={{count: item.item_count, ...item.item}} />)
          })
        }
        </>
      )
    } else {
      return <p>There are no items in this kit</p>
    }
  }

  renderAccessButton() {
    const { accessButton } = this.props;
    if (accessButton) {
      return (
        <a onClick={this.openModal}>
          {accessButton}
        </a>
      )
    }
    return (
      <div className="item-preview-button" onClick={this.openModal}>
        Quickview
      </div>
    )
  }

  render() {
    const { kit } = this.props;
    return(
      <>
        {this.renderAccessButton()}
        <Modal
          className="react-modal item-card-quick-view-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Kit Quickview"
        >
          <div className="react-modal-close-button" onClick={this.closeModal}> &#10005; </div>
          <h1> {kit.name} </h1>
          <div>
            {this.renderItems()}
          </div>
        </Modal>
      </>
    )
  }
}

export default KitCardQuickViewModal
