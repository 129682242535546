import React from "react";
import PropTypes from "prop-types";
import { formatEventDate } from "../../utility/DateUtils"
import EventItemCard from "./EventItemCard"
import EventKitCard from "./EventKitCard";
import moment from 'moment'
import Cookies from "universal-cookie";
import { isHoldShipped } from '../../utility/HoldUtils'
class EventSortedItemsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'group',
      filterBy: null,
      shrunkSections: [],
      item_holds: props.item_holds.sort((a,b) => a.id - b.id)
    }
    this.getUniqueByID = this.getUniqueByID.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
    this.lockHold = this.lockHold.bind(this);
    this.filterHolds = this.filterHolds.bind(this);
  }

  renderSections(){
    const {sortBy} = this.state;
    switch(sortBy) {
      case 'name':
        return this.renderSectionsByName();
      case 'shipping-address':
        return this.renderSectionsByShippingAddress();
      case 'group':
        return this.renderSectionByGroup();
      case 'category':
        return this.renderSectionByCategory();
      case 'recently-added':
        return this.renderSectionsByRecentlyAdded();
      case 'shipping-date':
        return this.renderSectionsByShippingDate();
      case 'status':
        return this.renderSectionsByStatus();
      case 'distribution-location':
        return this.renderSectionsByDistributionLocation();
      case 'recipient':
        return this.renderSectionsByRecipient();
      case 'pallet':
        return this.renderSectionsByPallet();
      default:
        return this.renderSectionsByName();
    }
  }

  getHoldsWithItems(alphaSort = false) {
    const { item_holds } = this.state;
    const { items } = this.props;
    let result = item_holds.filter(hold => !hold.kit_id).map(hold => {
      const item = items.find(item2 => item2.id == hold.item_id)
      return {
        hold: hold,
        item: item.variant_of_id ? items.find(item2 => item2.id == item.variant_of_id) : item,
        variant: item.variant_of_id ? item : null,
        name: item.name
      }
    })
    return result.sort((a,b) => {
      return a.item.name.toLowerCase() < b.item.name.toLowerCase() ? -1 : 1
    });
  }

  getKitsWithHolds(alphaSort = false) {
    const {item_holds} = this.state;
    const {kits, kit_holds} = this.props;
    let result = kit_holds.map(kitHold => {
      const kit = kits.find(k => kitHold.kit_id == k.id)
      //We'll grab an item hold that belongs to a kit, all item_holds per kit_hold have the same values in each field (except total_count)
      let kitItemHold = {...item_holds.find(hold => hold.kit_hold_id == kitHold.id)}
      //Only want to include information relevant to the entire kit
      delete kitItemHold.id
      delete kitItemHold.item
      delete kitItemHold.item_id
      delete kitItemHold.total_count
      delete kitItemHold.total_packed
      let kitHoldInfo = {...kitHold, ...kitItemHold}
      return {
        hold: kitHoldInfo,
        kit: kit,
        name: kit.name,
      }
    })
    
    if (alphaSort) {
      return result.sort((a,b) => {
        return a.kit.name.toLowerCase() < b.kit.name.toLowerCase() ? -1 : 1
      });
    } else {
      return result;
    }
  }

  getItemsAndKitsWithHolds(alphaSort = false) {
    let combined = this.getHoldsWithItems().concat(this.getKitsWithHolds());
    return combined.sort((a,b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    })
  }

  renderSectionsByStatus() {
    const {kits} = this.props;
    const holdsWithItems = this.getHoldsWithItems(true);
    const holdsWithKits = this.getKitsWithHolds(true);
    //TODO: Make this sort kits by status once kit status is able to be changed
    return (
      <>
        {this.renderSection('Shipped', holdsWithItems.filter(hwi => (isHoldShipped(hwi.hold))))}
        {this.renderSection('Locked', holdsWithItems.filter(hwi => (!isHoldShipped(hwi.hold) && hwi.hold.locked)))}
        {this.renderSection('Reserved', holdsWithItems.filter(hwi => (!isHoldShipped(hwi.hold) && !hwi.hold.locked)).concat(holdsWithKits))}
      </>
    );
  }

  renderSectionsByName() {
    const { kits } = this.props;
    const all = this.getItemsAndKitsWithHolds(true)
    console.log(all);
    return (
      <>
        {this.renderSection('All', all)}
      </>
    );
  }

  renderSectionsByRecentlyAdded() {
    const { item_holds } = this.state;
    const { items } = this.props;
    //TODO: This hasn't been finished
    const holdsWithItems = this.getHoldsWithItems().sort((a,b) => {
      return moment(a.item_hold.created_at) - moment(b.item_hold.created_at)
    });

    return (
      <>
        {this.renderSection('All', holdsWithItems)}
      </>
    );
  }

  renderSectionsByShippingDate() {
    const { item_holds } = this.state;
    const { items, event, kits } = this.props;
    //The Plan: sort item_holds by date, grab first unique per item_id
    const shippingDates = item_holds.map(hold => hold.shipping_date).filter((item, index, self) => self.findIndex(item2 => item2 == item) == index).sort((a,b) => {return moment(a || event.buffer_start_date) - moment(b || event.buffer_start_date)});
    const itemsAndKitsWithHolds = this.getItemsAndKitsWithHolds()

    const sections = shippingDates.map(date => {
      const dateHolds = itemsAndKitsWithHolds.filter(x => (x.hold.shipping_date == date))
      return this.renderSection((date || event.buffer_start_date), dateHolds);
    })
    return (
      <>
        {sections}
      </>
    );
  }

  renderSectionByCategory() {
    const { item_holds } = this.state;
    const { items, kits } = this.props;
    const kitCategories = kits.filter(k=> k.category).map(k=> k.category);
    const categories = this.getUniqueByID(item_holds.filter(x => !x.kit_id).map(item_hold => {
      const item = items.find((i) => item_hold.item_id == i.id)
      return item.category ? item.category : {id: null, name: 'Other'}
    }).concat(kitCategories).concat([{id: null, name: 'Other'}]));
    const itemsAndKitsWithHolds = this.getItemsAndKitsWithHolds(true);

    const sections = categories.map(category =>
      this.renderSection(category.name, itemsAndKitsWithHolds.filter(x => (x.item ? x.item.category_id == category.id : x.kit.category_id == category.id)))
    );
    return (
      <>
        {sections}
      </>
    );
  }

  renderSectionByGroup() {
    const { item_holds } = this.state;
    const { items, kits } = this.props;
    let item_groups = item_holds.filter(x => !x.kit_id).map(item_hold => {
      const item = items.find((i) => i.id == item_hold.item_id);
      return item.category && item.category.group_name ? item.category.group_name : null
    })
    let kit_groups = kits.filter(k=> k.category).map(k => k.category.group_name)
    console.log(kit_groups)
    const groups = item_groups.concat(kit_groups).filter((g,i,self) => self.findIndex(g2=>g2==g)==i && g !== null)
    const itemsAndKitsWithHolds = this.getItemsAndKitsWithHolds();
    const sections = groups.map(group =>
       this.renderSection(group, itemsAndKitsWithHolds.filter(x => (x.item ? x.item.category && x.item.category.group_name == group : x.kit.category && x.kit.category.group_name == group)))
    )
    return (
      <>
        {sections}
        {this.renderSection("Other", itemsAndKitsWithHolds.filter(x => (x.item ? !x.item.category  || x.item.category.group_name == null : !x.kit.category || x.kit.category.group_name == null)))}
      </>
    );
  }

  renderSectionsByShippingAddress() {
    const { item_holds } = this.state;
    const addresses = this.getUniqueByID(item_holds.filter(x=> x.shipping_address).map(x=>x.shipping_address)).concat([{id: null, venue_name: 'Unassigned'}]);
    const itemsAndKitsWithHolds = this.getItemsAndKitsWithHolds();

    const sections = addresses.map(address => {
      const addressHolds = itemsAndKitsWithHolds.filter(x => (x.hold.shipping_address_id == address.id))
      return this.renderSection(address.venue_name, addressHolds)
    })

    return (
      <>
        {sections}
      </>
    );
  }

  renderSectionsByDistributionLocation() {
    const {item_holds} = this.state;
    const {kits} = this.props;
    const locations = this.getUniqueByID(item_holds.filter(x => x.event_location).map(x => x.event_location).concat([{id: null, name: 'Unassigned'}]));
    const itemsAndKitsWithHolds = this.getItemsAndKitsWithHolds();

    let sections = locations.map(location => {
      const locationHolds = itemsAndKitsWithHolds.filter(x => (x.hold.event_location_id == location.id))
      return this.renderSection(location.name, locationHolds)
    })

    return (
      <>
        {sections}
      </>
    )
  }

  renderSectionsByRecipient() {
    const {item_holds} = this.state;
    const recipients = this.getUniqueByID(item_holds.filter(x => x.event_recipient).map(x => x.event_recipient).concat([{id: null, name: 'Unassigned'}]));
    const itemsAndKitsWithHolds = this.getItemsAndKitsWithHolds();

    let sections = recipients.map(recipient => {
      const recipientHolds = itemsAndKitsWithHolds.filter(x => (x.hold.event_recipient_id == recipient.id))
      return this.renderSection(recipient.name, recipientHolds)
    })

    return (
      <>
        {sections}
      </>
    )
  }

  renderSectionsByPallet() {
    const {item_holds} = this.state;
    const pallets = this.getUniqueByID(item_holds.filter(x => x.pallet).map(x => x.pallet).concat([{id: null, name: "Unassigned"}]));
    const itemsAndKitsWithHolds = this.getItemsAndKitsWithHolds();

    let sections = pallets.map(pallet => {
      const palletHolds = itemsAndKitsWithHolds.filter(x => (x.hold.pallet_id == pallet.id))
      return this.renderSection(pallet.name, palletHolds)
    })

    return (
      <>
        {sections}
      </>
    )
  }

  lockHold(hold){
    const { event } = this.props;
    const body = {
      item_hold: {
        locked: true
      }
    }
    if(hold.has_error){
      alert('Item can not be locked because it is missing information.');
      return;
    }
    if(confirm(`Are you sure you want to lock this item in? This cannot be undone.`)) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/events/${event.slug}/item_holds/${hold.id}/lock`, {
        method: 'POST',
        redirect: "manual",
        body: JSON.stringify(body),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            item_holds: this.state.item_holds.filter(hold2 => hold2.id != hold.id).concat([json.item_hold]).sort((a,b) => a.id - b.id)
          });
        } else {
          alert(json.error);
        }
      });
    }
  }


  getUniqueByID(array){
    //Checks if the index of the array is the same as the index given or filter
    //Theoretically n^2, gross, depending on implementation.
    //I miss compiled languages.
    // there is a es6 unique with terrible polyfills. Not recommended.
    return array.filter((item, index, self) => self.findIndex(item2 => item.id == item2.id) == index);
  }

  getAllItemHoldsForItem(item) {
    const { item_holds } = this.state;
    return item_holds.filter(hold => hold.item_id == item.id || hold.item.variant_of_id == item.id);
  }

  filterHolds(itemsAndKitsWithHolds) {
    const {filterBy} = this.state;
    switch(filterBy) {
      case "status-reserved":
        return itemsAndKitsWithHolds.filter(x => x.hold.status === "reserved")
      case "status-in_progress":
        return itemsAndKitsWithHolds.filter(x => x.hold.status === "in_progress")
      case "status-packed":
        return itemsAndKitsWithHolds.filter(x => x.hold.status === "packed")
      case "status-shipped":
        return itemsAndKitsWithHolds.filter(x => x.hold.status === "shipped")
      case "status-received":
        return itemsAndKitsWithHolds.filter(x => x.hold.status === "received")
      case "not-received":
        return itemsAndKitsWithHolds.filter(x => x.hold.status !== "received")
      default:
        return itemsAndKitsWithHolds;
    }
  }

  renderSection(name, itemsAndKitsWithHolds = []) {
    const { shrunkSections } = this.state;
    const { event, inventory_logs } = this.props;
    const nonReceivedItemIds = inventory_logs.map((log) => log.item_id)
    const cards = this.filterHolds(itemsAndKitsWithHolds).map(x => {
      if (x.item) {
        const {item, hold, variant} = x
        const received = !nonReceivedItemIds.includes(item.id)
        return (
          <EventItemCard key={`event-item-card-${(item||{}).id}-${(hold||{}).id}`} lockHold={this.lockHold} event={event} item={item} hold={hold} variant={variant} received={received}/>
        )
      } else if (x.kit) {
        const {kit, hold} = x
        return (
          <EventKitCard kit={kit} hold={hold} count={this.getKitNumber(kit, hold)} event={event}/>
        )
      }
    })

    const isOpen = !shrunkSections.includes(name);
    return (
      <div className="item-section">
        <div className="item-section-header" onClick={()=>this.toggleSection(name)}>
          <h4>
            {name} &nbsp;
            {isOpen ?
             (<i class="fas fa-sort-down" />)
             :
             (<i class="fas fa-sort-down arrow-rotated" />)
            }
          </h4>
        </div>
        {isOpen ? cards : ""}
      </div>
    )
  }

  getKitNumber(kit, kitHold) {
    //Number of kits assigned to an event isn't actually stored anywhere, so we calculate it by comparing the number of a
    //kit item per kit to the number of said item being held for the event
    if (kit.items.length == 0) {
      return 0;
    }
    const {item_holds} = this.props;
    const kitItem = kit.items[0];
    const itemsPerKit = kitItem.item_count;
    const hold = item_holds.find(hold => (hold.item_id == kitItem.item_id && hold.kit_hold_id == kitHold.id))
    const totalOfItem = hold.total_count
    return itemsPerKit > 0 ? totalOfItem / itemsPerKit : 0
  }

  toggleSection(name){
    const { shrunkSections } = this.state;
    if(shrunkSections.includes(name)){
      this.setState({shrunkSections: shrunkSections.filter(x=>x!=name)});
    } else {
      this.setState({ shrunkSections: shrunkSections.concat([name])});
    }
  }

  renderSortByAndFilter() {
    const {sortBy, filterBy} = this.state
    return (
      <div className="row">
        <div className="col-xs-4 event-sorted-back-container">
          <a href="/events/">
            {'< Back'}
          </a>
        </div>
        <div className="col-xs-8 text-right event-sorted-item-container-item-sort-by-menu">
          <label className="event-sort-by-dropdown-label">Sort By:</label>
          <select className="form-control event-sort-by-dropdown" value={sortBy} onChange={(e) => this.updateSortBy(e.target.value)}>
            <option value="name">Name</option>
            <option value="category">Category</option>
            <option value="group">Group</option>
            <option value="shipping-address">Shipping Location</option>
            <option value="distribution-location">Distribution Location</option>
            <option value="recipient">Recipient</option>
            <option value="pallet">Pallet</option>
            <option value="shipping-date">Shipping Date</option>
            <option value="status">Status</option>
          </select>
          <label className="event-sort-by-dropdown-label">Filter By:</label>
          <select className="form-control event-sort-by-dropdown" value={filterBy} onChange={(e) => this.updateFilterBy(e.target.value)}>
            <option value={null}>All</option>
            <option value="status-reserved">Status: Reserved</option>
            <option value="status-in_progress">Status: In Progress</option>
            <option value="status-packed">Status: Packed</option>
            <option value="status-shipped">Status: Shipped</option>
            <option value="status-received">Status: Received</option>
            <option value="not-received">Not yet received</option>
          </select>
        </div>
      </div>
    )
  }

  updateSortBy(sortBy){
    this.setState({
      sortBy,
      shrunkSections: [],
    })
  }

  updateFilterBy(filterBy) {
    this.setState({ filterBy })
  }

  render() {
    const { event } = this.props;
    return (
      <div className="event-sorted-item-container">
        {this.renderSortByAndFilter()}
        {this.renderSections()}
      </div>
    )
  }
}

EventSortedItemsContainer.propTypes = {
  event: PropTypes.object.optional,
};

export default EventSortedItemsContainer;
