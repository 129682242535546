import React from "react";
import PropTypes from "prop-types";
import WarehouseDeleteItemModal from "../items/WarehouseDeleteItemModal"
class KitItemSearchableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    }
    this.updateSearchText = this.updateSearchText.bind(this);
    this.toggleVariants = this.toggleVariants.bind(this);
  }

  componentDidUpdate(prevProps) { //checks if received prop selectedItems' length has changed
    if (this.props.selectedItems.length !== prevProps.selectedItems.length) {
      this.setState({selectedItems: this.props.selectedItems.map(item => item.id)})
    }
  }

  generateItemVariantClass(item) {
    return item.variant_of_id ? "item-variant" : "";
  }

  filteredItems() {
    let { items, searchText} = this.props;
    const {selectedItems} = this.state;

    items = items.filter(item => item.variant_of_id == null && !selectedItems.includes(item.id));

    if(searchText) {
      items = items.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.variants.filter(variant=> variant.name.toLowerCase().includes(searchText.toLowerCase())).length > 0  
      );
    }

    return items;
  }

  handleAddItem(item) {
    const {addItem} = this.props;
    let selectedItems = this.state.selectedItems.slice();
    selectedItems.push(item.id);
    this.setState({selectedItems: selectedItems})
    addItem(item);
  }

  renderItem(item) {
    const { showVariants } = this.props;
    if(item) {
      return (
        <React.Fragment>
          <tr className={`${this.generateItemVariantClass(item)}`}  key={`item-row-${item.id}`}>
            <td className="kit-add-item-btn-col">
              <button className="kit-add-item-btn btn-green pull-right button-margin-right" onClick={() => this.handleAddItem(item)}>+</button>
            </td>
            <td> {item.name} </td>
          </tr>
          {(showVariants && item.variants)? item.variants.map(variant => this.renderItem(this.props.items.find(item => item.id == variant.id) || variant)) : (<React.Fragment></React.Fragment>)}
        </React.Fragment>
      )
    }
  }

  updateSearchText(e) {
    this.setState({
      searchText: e.target.value,
    })
  }

  toggleVariants(e){
    this.setState({
      showVariants: e.target.checked
    })
  }

  render() {
    const rows = this.filteredItems().map(item=>this.renderItem(item));
    return (
      <table className="kit-add-item-table">
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }
}

export default KitItemSearchableTable;
