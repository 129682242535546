import React from "react";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
const DataGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />

      <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          sx={{
            marginLeft: "auto"
          }}
        />
    </GridToolbarContainer>
  );
};

export default DataGridToolbar;