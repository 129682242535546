import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import WarehouseSearchableItemTable from "./WarehouseSearchableItemTable"
import WarehouseDeleteItemModal from "./WarehouseDeleteItemModal"
import Select from "react-select"

class WarehouseItemIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showVariants: true,
      searchText: "",
      sortBy: { label: "Last Updated", value: "updated_at" },
      searchBy: { label: "Name", value: "name" },
    }

    this.updateSearchText = this.updateSearchText.bind(this);
    this.toggleVariants = this.toggleVariants.bind(this);
  }

  generateItemVariantClass(item) {
    return item.variant_of_id ? "item-variant" : "";
  }

  filteredItems() {
    let { items } = this.props;
    const { searchText } = this.state;

    items = items.filter(item => item.variant_of_id == null);

    if(searchText) {
      items = items.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
        //|| item.variants.filter(variant=> variant.name.toLowerCase().includes(searchText.toLowerCase())).length > 0
      );
    }

    return items;
  }

  renderItem(item) {
    const { showVariants } = this.state;
    if(item) {
      return (
        <React.Fragment>
          <tr className={`${this.generateItemVariantClass(item)}`}  key={`item-row-${item.id}`}>
            <td> {item.name} </td>
            <td> {item.inventory}</td>
            <td>
              <a href={`/warehouse/items/${item.id}/edit`}>
                <button className="btn btn-gray pull-right">Edit</button>
              </a>
              <a href={`/warehouse/items/${item.id}/edit#inventory`}>
                <button className="btn btn-gray pull-right button-margin-right">Manage Inventory</button>
              </a>
              <a href={`/warehouse/items/${item.id}/edit#inventory`}>
                <WarehouseDeleteItemModal item={item} />
              </a>
            </td>
          </tr>
          {/* {showVariants ? item.variants.map(variant => this.renderItem(this.props.items.find(item => item.id == variant.id))) : (<React.Fragment></React.Fragment>)} */}
        </React.Fragment>
      )
    }
  }

  renderItemTable() {
    const rows = this.filteredItems().map(item=>this.renderItem(item));
    return (
      <table className="warehouse-item-index-table">
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  getSortByOptions() {
    return [
      { label: "(A - Z)", value: "name" },
      { label: "Last Updated", value: "updated_at" },
      { label: "Created At", value: "created_at" },
      { label: "Total #", value: "total_count" }
    ]
  }

  getSearchByOptions() {
    return [
      { label: "Name", value: "name" },
      { label: "Renter", value: "renter" },
      { label: "Category", value: "category" },
    ]
  }

  updateSearchText(e) {
    this.setState({
      searchText: e.target.value,
    })
  }

  toggleVariants(e){
    this.setState({
      showVariants: e.target.checked
    })
  }

  render() {
    return (
      <WarehouseLayout className="warehouse-item-index">
        <h1> Items </h1>
        <div className="row">
          <div className="col-xs-12">
            <div className="warehouse-card">
              <div className="row">
                <div className="col-xs-2">
                  <a href="/warehouse/items/new">
                    <button className="btn btn-green"> Add New </button>
                  </a>
                  <br/>
                  <br/>
                </div>
              </div>
              <WarehouseSearchableItemTable 
              renters={this.props.renters} 
              showOwners={true} 
              items={this.props.items} 
              searchText={this.state.searchText}
              searchBy={this.state.searchBy.value} 
              sortBy={this.state.sortBy.value} 
              showVariants={this.state.showVariants} 
              />
            </div>
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

WarehouseItemIndex.propTypes = {
  items: PropTypes.array.isRequired,
  renters: PropTypes.array.isRequired
};

export default WarehouseItemIndex;
