import React from "react";
import PropTypes from "prop-types";
import PublicCatalogLayout from "../../layouts/PublicCatalogLayout"
import ItemCard from "../components/ItemCard"
import Cookies from "universal-cookie";
import {isJaguar, isSuperadmin} from '../../utility/UserUtils.js'

class ItemSearchDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      tagsOpen: false,
      commonTags: null,
      selectedTags: [],
      searchInput: "",
    }

    this.toggleTags = this.toggleTags.bind(this);
    this.toggleSelectedTag = this.toggleSelectedTag.bind(this);
    this.updateSearchInput = this.updateSearchInput.bind(this);
    this.loadTags = this.loadTags.bind(this);
    this.getSearchItems = this.getSearchItems.bind(this);
  }

  componentDidMount() {
    this.loadTags();
  }

  getSearchItems(e) {
    e.preventDefault();
    const {searchInput} = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/items/get_search_items?query=${searchInput.trim()}`, {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      console.log(json)
      if (json.error == null) {
        this.setState({items: json.items || []});
      } else {
        alert(json.error);
      }
    });
  }

  renderSearchForm() {
    const { searchInput } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12">
          <form onSubmit={this.getSearchItems}>
            <div className="search-menu-card" style={{paddingTop: "20px"}}>
              <h2> Search: </h2>
              <br />
              <div className="row">
                <div className="col-xs-8">
                  <input value={searchInput} onChange={this.updateSearchInput} className="form-control"/>
                </div>
                <div className="col-xs-4">
                  <button className="search-submit-button" type="submit">Search</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

  generateSearchLink(){
    const { searchInput, selectedTags } = this.state;
    const tags = selectedTags.map(tag => `tags[]=${tag}`);
    const params = [`query=${searchInput}`].concat(tags).join('&');
    return `/items/search?${params}`
  }

  loadTags() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/item_tags/common_tags?count=25`, {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({commonTags: json.tags || []});
        } else {
          alert(json.error);
        }
      });
  }

  updateSearchInput(e){
    this.setState({
      searchInput: e.target.value
    })
  }

  toggleTags() {
    const { tagsOpen } = this.state;
    this.setState({
      tagsOpen: !tagsOpen
    })
  }

  toggleSelectedTag(tag) {
    const { selectedTags } = this.state;
    this.setState({
      selectedTags: ((selectedTags.indexOf(tag) > -1) ? selectedTags.filter(tag2 => tag2 != tag) : [tag].concat(selectedTags))
    })
  }

  getTagClass(tag) {
    const { selectedTags } = this.state;
    return selectedTags.indexOf(tag) > -1 ? "active" : "";
  }

  renderTags() {
    const { tagsOpen, commonTags} = this.state;
    let content = ""
    if(tagsOpen && commonTags) {
      content = commonTags.map(tag => (
          <span className="selectable-tag" onClick={() => this.toggleSelectedTag(tag)}>
            <div className={`tag-container ${this.getTagClass(tag)}`} >
              {tag}
            </div>
          </span>
        )
      )
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12 archive-events-header" onClick={this.toggleTags}>
            Tags&nbsp;&nbsp;
            {tagsOpen ?
              (<i class="fas fa-sort-down" />)
              :
              (<i class="fas fa-sort-down archive-arrow-rotated" />)
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 search-tags-container" >
            { content }
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderItems() {
    const { user } = this.props;
    const { items, searchInput } = this.state;
    return items.map(item => (
      <ItemCard user={user} item={item} />
    )); 
  }

  render() {
    const { group, search } = this.props;
    return (
      <PublicCatalogLayout
        currentPage={`search`}
        classNames={{container: 'full-body-container '}} 
        {...this.props}
        className="category-dashboard"
      >
        <div className="container wider-container">
          {this.renderSearchForm()}
          {this.renderItems()}
        </div>
      </PublicCatalogLayout> 
    );
  }
}

ItemSearchDashboard.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ItemSearchDashboard;
