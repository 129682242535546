import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { Formik, Field, Form } from "formik";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
class TemporaryItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  renderSelectField(name, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['item'][name])[0]
    }catch (e) {

    }

    return (
      <Select
        className={ "registration-select-erroring"}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { item } = formikProps.values;
          item[name] = option.value
          formikProps.setFieldValue('item', item)
        }}
        filterOption={this.customFilter}
      />
    )
  }

  renderForm(formikProps) {
    const { item } = this.props;
    // thumbnail: null,
    // shipping_address_id: null,
    return (
      <Form className="inventory-temp-item-form">
        <Field name="item[draft]" type="hidden" />
        <label> Name* </label>
        <Field type="text" compontent="textarea" className="form-control" name="item[name]" placeholder="Ex: Ikea Kailax Shelf" required/>
        <label> Count* </label>
        <Field className="form-control" name="item[count]" type="number" min={1}/>
        <label> Description </label>
        <Field name="item[description]" type="textarea" component="textarea" rows="5" className="form-control"  style={{width: "100%"}}/>
        <label> Vendor </label>
        <Field className="form-control" name="item[vendor]" />

        <b> Hold Information </b>
        <label> Shipping Date* </label>
        <Field required type="date" className={`form-control`}  name="item[shipping_date]"/>
        <label> Shipping Address </label>
        {this.renderSelectField('shipping_address_id', formikProps, this.generateAddressOptions())}
        <label> Tracking Number </label>
        <Field className="form-control" name="item[tracking_number]"/>
        <button onClick={formikProps.submitForm}>
          Submit
        </button>
      </Form>
    );
  }


  generateAddressOptions() {
    const { shipping_addresses } = this.props;
    if(shipping_addresses) {
      return shipping_addresses.map(address =>
        {return {label: address.venue_name, value: address.id}}
      );
    }
    return []
  }

  render() {
    const { item, children, event } = this.props;
    return (
      <Formik
        initialValues={{
          item: {
            name: "",
            description: "",
            event_id: event.id,
            thumbnail: null,
            vendor: "",
            tracking_number: "",
            shipping_date: null,
            shipping_address_id: null,
            count: 1,
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch(`/warehouse/temporary_items`, {
            method: 'POST',
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                window.location.replace(json.url);
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          this.renderForm(formikProps)
        )}
      />
    );
  }
}

TemporaryItemForm.propTypes = {
  event: PropTypes.object.isRequired,
  shipping_addresses: PropTypes.array.isRequired,
};

export default TemporaryItemForm;
