import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Cookies from "universal-cookie";

class WarehouseRentersDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renters: props.renters
    }
  }

  handleDelete(renter) {
    if (confirm(`Are you sure you want to delete renter ${renter.name}? This can't be undone.`)) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");

      fetch(`/warehouse/renters/${renter.id}`, {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
        }
      })
      .then(res => {
        return res.json();
      })
      .then(res => {
        if (res.error) {
          alert(res.error)
        } else {
          this.setState({renters: res.renters})
        }
      })
    }
  }

  renderRentersTable() {
    const { renters } = this.state;
    if(renters && renters.length > 0) {
      const renterRows = renters.map(renter =>
        <tr key={`renter-table-renter-${renter.id}`}>
          <td><a href={`/warehouse/renters/${renter.id}`}>{renter.name}</a></td>
          <td> </td>
          <td className="options">
            <button onClick={() => this.handleDelete(renter)} type="button" className="btn btn-danger pull-right"> Delete </button>
            <a href={`/warehouse/renters/${renter.id}/edit`}><button type="button" className="btn btn-gray pull-right"> Edit </button></a>
            <a href={`/warehouse/renters/${renter.id}`}><button type="button" className="btn btn-gray pull-right"> View </button></a>
          </td>
        </tr>
      );  

      return (
        <table className="warehouse-renters-table">
          <tbody>
            {renterRows}
          </tbody>
        </table>
      );
    }
    return (
      <h2 className="text-center">
        No Renters Currently Exist
      </h2>
    );
  }

  render() {
    return (
      <WarehouseLayout currentPage="dashboard">
        <div className="warehouse-card">
          <h1> Renters </h1>
          <div className="row">
            <div className="col-xs-12">
              <a href="/warehouse/renters/new"><button type="button" className="btn btn-green"> Add New </button></a>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {this.renderRentersTable()}
            </div>
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

WarehouseRentersDashboard.propTypes = {
  renters: PropTypes.array.isRequired,
};

export default WarehouseRentersDashboard;
