import React, { Component } from 'react'

export default class ReportEntry extends Component {
  render() {
    const {report} = this.props
    return (
      <div className='event-reports-entry'>
        <div>{report.name}</div>
        <div className='options'>
          {report.completed ? <a target="_blank" href={report.report_url}>View Report</a> : <>Generating...</>}
        </div>
      </div>
    )
  }
}
