import React,{ Component }  from 'react'
import classNames from "classnames";
import ItemImageGalleryModal from "./ItemImageGalleryModal"
class ItemImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImage: props.item.active_image || {}
    }
    this.updateActiveImage = this.updateActiveImage.bind(this);
    this.updateVariantActiveImage = this.updateVariantActiveImage.bind(this);
  }

  renderItemImages() {
    const { activeImage } = this.state;
    const { item } = this.props;
    if ( item ) {
      const allImages= item.item_images.concat(...item.variants.map(v=> v.item_images))
      const subImages = allImages.map(image =>
        <div style={{backgroundImage: `url("${image.thumbnail_url}")`}} className="sub-image" onClick={() => this.updateActiveImage(image)}/>
      );
      return (
        <div className="item-image-component">
          <ItemImageGalleryModal
            button={(
              <div className="main-image" style={{backgroundImage: `url("${activeImage.thumbnail_url}")`}} />
            )}
            currentImage={activeImage}
            images={allImages}
          />
          <div className="images-container">
            { subImages }
          </div>
        </div>
      )
    }
  }

  updateActiveImage(image) {
    this.setState({
      activeImage: image
    })
  }

  updateVariantActiveImage(id){
    const { item } = this.props;
    const { variants } = item;
    const variant = variants.find(v=> v.id === id);
    if(variant && variant.active_image){
      this.setState({
        activeImage: variant.active_image
      })
    }
    else if(id === item.id || id === null){
      this.setState({
        activeImage: item.active_image
      })
    }
  }

  render() {
    return this.renderItemImages()
  }
}

export default ItemImageComponent
