import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Select, {components} from 'react-select'
import Creatable from 'react-select/creatable'
import Cookies from "universal-cookie";
import { hasWarehouseAccess } from "../../utility/UserUtils"
import OnSiteInformationModal from "./OnSiteInformationModal"
class ItemHoldForm extends React.Component {
  constructor(props) {
    super(props);
    const { item_hold } = props;
    const shipping_address = item_hold ? item_hold.shipping_address : null;
    const item  = item_hold ? item_hold.item : null;
    console.log(this.props.item_hold)
    this.state = {
      selectedEvent: item_hold ? {label: item_hold.event.name, value: item_hold.event.id} : null,
      selectedCount: item_hold ? {label: item_hold.total_count, value: item_hold.total_count} : null,
      selectedAddress: item_hold && shipping_address ? {label: shipping_address.venue_name, value: shipping_address.id} : null,
      selectedPallet: item_hold && item_hold.pallet ? {label: item_hold.pallet.name, value: item_hold.pallet.name} : null,
      selectedOnSiteLocation: item_hold && item_hold.event_location ? {label: item_hold.event_location.name, value: item_hold.event_location.name} : null,
      selectedRecipient: item_hold && item_hold.event_recipient ? {label: item_hold.event_recipient.name, value: item_hold.event_recipient.name} : null,
      directToSite: item_hold ? item_hold.direct_to_site : false,
      signageCodeInput: item_hold ? item_hold.signage_code : null,
      availableItemCount: null,
      selectedVariant: item ? {label: (item.id == props.item.id ? "default" : item.name), value: item.id} :null,
      fullEventInformation: props.event || null,
      shippingDate: item_hold ? item_hold.shipping_date : null,
      dirty: !!props.event,
      itemHoldNotes: item_hold ? item_hold.notes || "" : "",
      createOptionInput: null,
      onSiteInformationModalOpen: false
    }
    this.getEventInformation = this.getEventInformation.bind(this);
    this.updateEventField = this.updateEventField.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
    this.updateVariantField = this.updateVariantField.bind(this);
    this.updateShippingDateField = this.updateShippingDateField.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.updateEventInformation = this.updateEventInformation.bind(this);
  }

  componentDidMount() {
    const { item } = this.props;
    const { selectedEvent, selectedVariant,shippingDate } = this.state;
    if(selectedEvent){
      const id = selectedEvent.value
      this.getEventInformation(id, selectedVariant ? selectedVariant.value : item.id, shippingDate);
    }
  }

  updateEventInformation() {
    const { selectedEvent, selectedVariant,shippingDate } = this.state;
    if(selectedEvent){
      const id = selectedEvent.value
      this.getEventInformation(id, selectedVariant ? selectedVariant.value : item.id, shippingDate);
    }
  }

  createNewOption() {

  }

  hasVariant(){
    const { item } = this.props;
    const { variants } = item;
    return (variants && variants.length > 0);
  }

  renderSelectVariant() {
    const { item, item_hold, size } = this.props;
    const { selectedVariant } = this.state;
    const { variants } = item;
    const small = size == "small";
    if(variants && variants.length > 0) {
      return (
        <React.Fragment>
          <br />
          <Select
            value={selectedVariant}
            onChange={this.updateVariantField}
            options={this.generateVariantOptions()}
            placeholder="Select Variant"
            isDisabled={item_hold}
          />
        </React.Fragment>
      )
    }
  }

  generateVariantOptions() {
    const { item } = this.props;
    const { variants } = item;
    const originalArray = item.owned > 0 ? [{label: 'default', value: item.id}] : []
    return originalArray.concat(variants.map(variant =>{
        return {label: variant.name, value: variant.id}
      }
    ));
  }

  renderForm(formikProps) {
    const { selectedEvent, selectedCount, selectedAddress, availableItemCount, fullEventInformation } = this.state;
    const { item_hold, event, size } = this.props;

    if(item_hold){
      return (
        <Form className="item-hold-form">
          <div className="row">
            <div className="col-xs-12">
              <br />
              <b>Hold Information:</b>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectEvent()}
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectQuantity()}
            </div>
            <div className="col-xs-3">
              {this.renderAvailableCount()}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <b>Shipping Information:</b>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectEventLocation()}
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectShippingDate()}
            </div>  
          </div>
          <div className="row">
            <br />
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectPallet()}
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderDirectToSiteCheckbox()}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-12">
              <b>On-Site Information 

                <a style={{marginLeft:"5px"}} href="#" onClick={()=>this.setState({onSiteInformationModalOpen: true})}>
                  Manage Options
                </a>
                <OnSiteInformationModal
                  open={this.state.onSiteInformationModalOpen}
                  onClose={()=>this.setState({onSiteInformationModalOpen: false})}
                  fullEventInformation={fullEventInformation}
                  updateEventInformation={this.updateEventInformation}
                />
              </b>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectOnSiteLocation()}
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectRecipient()}
            </div>
          </div>
          <div className="row">
            <br />
            <div className="col-xs-12">
              <b>Signage only:</b>
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSignageCodeInput()}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-12">
              {this.renderNotes()}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 col-sm-4">
              {this.renderSubmitButton()}
            </div>
            {this.renderCancelButton()}
          </div>
        </Form>
      )
    }
    else{
      return this.renderFormWithoutHold(formikProps)
    }

  }

  renderNotes() {
    const { itemHoldNotes, fullEventInformation } = this.state;
    const {item_hold} = this.props;
    return (
      <React.Fragment>
      Notes:
      <textarea onChange={this.updateNotes} type="textarea" value={itemHoldNotes} className="form-control" disabled={!fullEventInformation && !item_hold}/>
      <br />
      </React.Fragment>
    )
  }

  updateNotes(e) {
    this.setState({
      itemHoldNotes: e.target.value,
      dirty: true
    })
  }

  isLocked() {
    const { item_hold, lockOverride } = this.props;
    if(lockOverride != undefined) {
      return lockOverride
    }
    if(item_hold && item_hold.locked){
      return true
    }
    return false
  }

  renderSubmitButton(){
    const { item_hold } = this.props;
    if(this.isLocked()){
      return (
        <button className="item-hold-form-locked-button" disabled="true" type="button">
          Item Locked In
        </button>
      )
    }
    return (
      <button className="item-hold-form-submit-button" disabled={!this.isFormValid()} type="submit">
        {item_hold ? "Update Hold" : "Add To Event"}
      </button>
    )
  }

  renderFormWithoutHold(formikProps) {
    //Yes this has to be seperate, and yes this is triple the work.
    const { selectedEvent, selectedCount, selectedAddress, availableItemCount, fullEventInformation } = this.state;
    const { item_hold, event, size } = this.props;
    const small = size == "small";
    if(small) {
      return(
        <Form className="item-hold-form small-item-form">
          <div className="row">
            <div className="col-xs-12">
              {this.renderSelectVariant()}
              {this.hasVariant() ? <br /> : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {this.renderSelectEvent()}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <br />
              {this.renderSelectEventLocation()}
            </div>
            <div className="col-xs-12">
              <br />
              {this.renderSelectShippingDate()}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <br />
              {this.renderSelectQuantity()}
            </div>
            <div className="col-xs-6">
              {this.renderAvailableCount()}
            </div>
            <div className="col-xs-6">
              {this.renderSelectPallet()}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-12">
              <b>On-Site Information:</b>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectOnSiteLocation()}
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectRecipient()}
            </div>
          </div>
          <div className="row">
            <br />
            <div className="col-xs-12">
              <b>Signage only:</b>
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSignageCodeInput()}
            </div>
          </div>
          <br />
          <div className="row" style={{lineHeight: "1em"}}>

            <div className="col-xs-12">
              {this.renderSubmitButton()}
            </div>
            {this.renderCancelButton()}
          </div>
        </Form>
      )
    } else {
      return (
        <Form>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectVariant()}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectEvent()}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <br />
              {this.renderSelectEventLocation()}
            </div>
            <div className="col-xs-12 col-sm-6">
              <br />
              {this.renderSelectShippingDate()}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectQuantity()}
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectPallet()}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 col-sm-6">
              {this.renderAvailableCount()}
            </div>
            <div className="col-xs-6 col-sm-6">
              {this.renderDirectToSiteCheckbox()}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-xs-12">
              <b>On-Site Information:</b>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectOnSiteLocation()}
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSelectRecipient()}
            </div>
          </div>
          <div className="row">
            <br />
            <div className="col-xs-12">
              <b>Signage only:</b>
            </div>
            <div className="col-xs-12 col-sm-6">
              {this.renderSignageCodeInput()}
            </div>
          </div>
          <div className="row">
            <br />
            <div className="col-xs-12">
              {this.renderNotes()}
            </div>
          </div>
          <div className="row">
            <br />
            <div className={!small ? "col-xs-6 col-sm-4" : "col-xs-12"}>
              {this.renderSubmitButton()}
            </div>
            {this.renderCancelButton()}
          </div>
        </Form>
      )
    }
  }

  renderSignageCodeInput() {
    const { shippingDate, fullEventInformation, signageCodeInput } = this.state;
    const isDisabled = (!fullEventInformation || this.isLocked())
    return (
      <input placeholder="Signage Code" value={signageCodeInput} onChange={(e) => this.setState({signageCodeInput: e.target.value, dirty: true})} type="text" className={`form-control ${isDisabled ? 'disabled-date-input' : ''}`} disabled={isDisabled}/>
    )
  }

  renderSelectShippingDate(){
    const { shippingDate,fullEventInformation } = this.state;
    const { item_hold } = this.props;
    return (
      <input type="date" placeholder="Shipping Date" onChange={this.updateShippingDateField} className="form-control" disabled={!fullEventInformation || this.isLocked()} value={fullEventInformation ? shippingDate || fullEventInformation.buffer_start_date : null}/>
    )
  }

  renderAvailableCount(){
    const { selectedEvent, selectedCount, selectedAddress, availableItemCount, fullEventInformation } = this.state;
    const { item_hold, event, size } = this.props;
    if(availableItemCount == null){
      return ""
    }
    if(availableItemCount && availableItemCount > 0){
      return (
        <div className="item-hold-availability-count">
          {(availableItemCount && !item_hold) ? `Available: ${availableItemCount}`:""}
        </div>
      )
    }
    else {
      return (
        <div className="item-hold-availability-count unavailable-count">
          Item is Unavailable
        </div>
      )
    }
  }

  renderSelectEventLocation(){
    const { selectedEvent, selectedCount, selectedAddress, availableItemCount, fullEventInformation } = this.state;
    const { item_hold, event, size } = this.props;

    return (
      <Select
        value={selectedAddress}
        onChange={(e) => this.updateSelectField(e,'selectedAddress')}
        options={this.generateAddressOptions()}
        placeholder="Select Shipping Location"
        isDisabled={!(fullEventInformation) || this.isLocked()}
        className={item_hold && item_hold.shipping_address_id === null ? "error-selected" : "test"}
        classNamePrefix={item_hold && selectedAddress === null ? "error-selected" : "react-select"}
      />
    )
  }

  renderSelectQuantity(){
    const { selectedEvent, selectedCount, selectedAddress, availableItemCount, fullEventInformation } = this.state;
    const { item_hold, event, size } = this.props;

    return (
      <Select
        value={selectedCount}
        onChange={(e) => this.updateSelectField(e,'selectedCount')}
        options={this.renderQuantityOptions()}
        placeholder="Qty."
        classNamePrefix={item_hold && (!selectedCount || !selectedCount.value) ? "error-selected" : "test"}
        isDisabled={!(availableItemCount!=null && fullEventInformation) || this.isLocked()}
        components={{
          SingleValue: ({ children, ...props }) => {
            return (
              <components.SingleValue {...props}>
                {"Qty: " + children}
              </components.SingleValue>
            );
          },
          IndicatorSeparator: () => null
        }}
      />
    )
  }

  renderSelectEvent(){
    const { selectedEvent } = this.state;
    const { item_hold, event, size } = this.props;
    return (
      <Select
        value={selectedEvent}
        onChange={this.updateEventField}
        options={this.generateEventOptions()}
        placeholder="Select Event"
        isDisabled={item_hold}
      />
    );
  }

  renderSelectOnSiteLocation() {
    const {selectedOnSiteLocation, fullEventInformation} = this.state;
    const {item_hold} = this.props;
    return(
      <Creatable
        isClearable
        value={selectedOnSiteLocation}
        placeholder="Select Distribution Location"
        options={this.generateOnSiteLocationOptions()}
        isDisabled={!fullEventInformation && !item_hold}
        onChange={(e) => this.updateSelectField(e,'selectedOnSiteLocation')}
        components={{
          SingleValue: ({ children, ...props }) => {
            return (
              <components.SingleValue {...props}>
                {"Dist. Location: " + children}
              </components.SingleValue>
            );
          },
          IndicatorSeparator: () => null
        }}
      />
    )
  }

  generateOnSiteLocationOptions() {
    const {fullEventInformation} = this.state;
    if (fullEventInformation && fullEventInformation.event_locations) {
      return fullEventInformation.event_locations.map(location =>
        ({label: location.name, value: location.name})
      ).sort((a,b) => {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
      });
    } else {
      return [];
    }
  }

  renderSelectRecipient() {
    const {selectedRecipient, fullEventInformation} = this.state;
    const {item_hold} = this.props;
    return (
      <Creatable
        isClearable
        value={selectedRecipient}
        placeholder="Select Recipient"
        options={this.generateRecipientOptions()}
        isDisabled={!fullEventInformation && !item_hold}
        onChange={(e) => this.updateSelectField(e,'selectedRecipient')}
        components={{
          SingleValue: ({ children, ...props }) => {
            return (
              <components.SingleValue {...props}>
                {"Recipient: " + children}
              </components.SingleValue>
            );
          },
          IndicatorSeparator: () => null
        }}
      />
    )
  }

  generateRecipientOptions() {
    const {fullEventInformation} = this.state;
    if (fullEventInformation && fullEventInformation.event_recipients) {
      return fullEventInformation.event_recipients.map(recipient =>
        ({label: recipient.name, value: recipient.name})
      ).sort((a,b) => {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
      });
    } else {
      return [];
    }
    
  }

  renderDirectToSiteCheckbox() {
    const {directToSite, fullEventInformation} = this.state;
    const {item_hold} = this.props;
    return (
      <div style={{padding: "8px 0 0"}}>
        <label>Shipping direct to site?</label>
        <Field type="checkbox" checked={directToSite} disabled={!item_hold && !fullEventInformation} onChange={() => this.setState({directToSite: !directToSite, dirty: true,})}/>
      </div>
    )
  }

  renderSelectPallet() {
    const {selectedPallet, fullEventInformation} = this.state;
    const {item_hold} = this.props
    let options = []
    return (
      <Creatable
        isClearable
        value={selectedPallet}
        placeholder="Select Pallet"
        options={this.generatePalletOptions()}
        isDisabled={!fullEventInformation && !item_hold}
        onChange={(e) => this.updateSelectField(e,'selectedPallet')}
        components={{
          SingleValue: ({ children, ...props }) => {
            return (
              <components.SingleValue {...props}>
                {"Pallet: " + children}
              </components.SingleValue>
            );
          },
          IndicatorSeparator: () => null
        }}
      />
    )
  }

  generatePalletOptions() {
    const {fullEventInformation} = this.state;
    if (fullEventInformation && fullEventInformation.pallets) {
      return fullEventInformation.pallets.map(pallet =>
        ({label: pallet.name, value: pallet.name})
      ).sort((a, b) => {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      })
    } else {
      return [];
    }
  }

  renderCancelButton() {
    const { item_hold } = this.props;
    const { dirty } = this.state;
    if( item_hold && dirty ) {
      return (
        <div className="col-xs-6 col-sm-4">
          <button className="item-hold-form-cancel-button" onClick={this.cancelChanges} type="button">
            Cancel
          </button>
        </div>
      )
    }
  }

  cancelChanges() {
    const { item_hold } = this.props;
    const shipping_address = item_hold ? item_hold.shipping_address : null;
    const state = {
      selectedEvent: item_hold ? {label: item_hold.event.name, value: item_hold.event.id} : null,
      selectedCount: item_hold ? {label: item_hold.total_count, value: item_hold.total_count} : null,
      selectedAddress: item_hold && shipping_address ? {label: shipping_address.venue_name, value: shipping_address.id} : null,
      itemHoldNotes: item_hold.notes,
      dirty: !!this.props.event,
    }
    this.setState(state);
  }

  generateAddressOptions() {
    const { fullEventInformation } = this.state;
    if(fullEventInformation && fullEventInformation.shipping_addresses) {
      return fullEventInformation.shipping_addresses.map(address =>
        {return {label: address.venue_name, value: address.id}}
      );
    }
    return []
  }

  renderQuantityOptions() {
    const { availableItemCount } = this.state;
    if(availableItemCount && availableItemCount > 0) {
      return new Array(availableItemCount+1).fill(null).map((v,i)=> {return {value: i, label: i}});
    }
    return [{value: 0, label: 0}];
  }

  renderShippedQuantityOptions() {
    const { item_hold } = this.props;
    if(item_hold && item_hold.total_count > 0) {
      return new Array(item_hold.total_count+1).fill(null).map((v,i)=> {return {value: i, label: i}});
    }
    return [{value: 0, label: 0}];
  }

  generateEventOptions(){
    const { events } = this.props;
    return events.filter(x=>
      x.archived_at == null
    ).map(event => {
      return {label: event.name, value: event.id}
    })
  }

  updateSelectField(value,field) {
    this.setState((state,props) => {
      state[field]=value;
      state['dirty'] = true;
      return state;
    })
  }

  updateVariantField(value) {
    const { selectedEvent,shippingDate } = this.state;
    console.log({imageComponentReference: this.props.imageComponentReference})
    this.props.imageComponentReference.current.updateVariantActiveImage(value.value);
    this.setState({
      selectedVariant: value,
      selectedCount: null,
      selectedAddress: null,
      availableItemCount: null,
      fullEventInformation: null,
    },
      () => this.getEventInformation(selectedEvent ? selectedEvent.value : null, value.value, shippingDate )
    )
  }

  updateEventField(value) {
    const {selectedVariant,shippingDate} = this.state;
    this.setState({
      selectedEvent: value,
      selectedCount: null,
      selectedAddress: null,
      availableItemCount: null,
      fullEventInformation: null,
      shippingDate: null,
      selectedPallet: null,
      selectedRecipient: null,
      selectedOnSiteLocation: null,
      directToSite: false,
    },
      () => this.getEventInformation(value.value, selectedVariant ? selectedVariant.value : null, shippingDate )
    )
  }

  updateShippingDateField(e) {
    const { selectedVariant, selectedEvent } = this.state;
    const value = e.target.value
    console.log("e", selectedEvent ? selectedEvent.value : null, selectedEvent )
    this.setState({
      shippingDate: value,
      selectedCount: null,
      availableItemCount: null,
    },
      () => this.getEventInformation()
    )
  }

  getEventInformation(event_id, variant_id, shipping_date) {
    console.log("Fetching Event Info", event_id, variant_id);
    const { item_hold } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { item } = this.props;
    let item_id = item.id;
    if(item.variants.length > 0) {
      item_id = variant_id
    }
    if(item_id && event_id) {
      fetch(`/events/${event_id}/hold_menu_info`, {
        method: 'POST',
        redirect: "manual",
        body: JSON.stringify({item_id: item_id,shipping_date: shipping_date}),
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.error == null) {
            this.setState({
              fullEventInformation: json.event,
              availableItemCount: item_hold ? json.available_item_count + item_hold.total_count : json.available_item_count,
              shippingDate : json.event.buffer_start_date
            })
          } else {
            alert(json.error);
          }
        });
      }

  }

  isFormValid() {
    const { selectedCount, selectedEvent, availableItemCount, dirty} = this.state;
    const { item_hold } = this.props;
    if(
       dirty &&
       selectedEvent &&
       selectedCount &&
       (selectedCount.value > 0 || item_hold) &&
       (selectedCount.value <= availableItemCount|| item_hold)
      ) {
      return true;
    }
    return false;
  }

  getFormURL() {
    const { item_hold } = this.props;
    const { selectedEvent } = this.state;
    if(item_hold) {
      return `/events/${selectedEvent.value}/item_holds/${item_hold.id}`
    }
    return `/events/${selectedEvent.value}/item_holds`
  }

  getFormMethod() {
    const { item_hold } = this.props;
    const { selectedCount } = this.state;

    if(item_hold) {
      if(selectedCount.value == 0) {
        return 'DELETE'
      }
      return `PATCH`
    }
    return `POST`
  }

  render() {
    const { item_hold, item } = this.props;
    const { selectedCount, selectedEvent, selectedAddress, selectedVariant, shippingDate, itemHoldNotes, selectedOnSiteLocation, selectedRecipient, selectedPallet, directToSite, signageCodeInput} = this.state;
    return (
      <Formik
        initialValues={{
          item_hold: {
          }
        }}
        onSubmit={(values, actions) => {
          if(this.isFormValid) {
            const cookies = new Cookies();
            const token = cookies.get("X-CSRF-Token");
            let item_id = item.id;
            if(item.variants.length > 0) {
              item_id = selectedVariant.value
            }
            const request = {
              item_hold :{
                total_count: selectedCount.value,
                event_id: selectedEvent.value,
                shipping_address_id: selectedAddress ? selectedAddress.value : null,
                shipping_date: shippingDate,
                item_id: item_id,
                notes: itemHoldNotes,
                direct_to_site: directToSite,
                signage_code: signageCodeInput && signageCodeInput.length > 0 ? signageCodeInput : null
              },
              event_location: selectedOnSiteLocation ? selectedOnSiteLocation.value : null,
              recipient: selectedRecipient ? selectedRecipient.value : null,
              pallet: selectedPallet ? selectedPallet.value : null,
            }
            fetch(this.getFormURL(), {
              method: this.getFormMethod(),
              redirect: "manual",
              body: JSON.stringify(request),
              headers: {
                "X-CSRF-Token": token,
                "Content-Type": 'application/json'
              }
            })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                window.location.replace(json.url);
              } else {
                alert(json.error);
              }
            });
          }
        }}
        render={(formikProps) => (
          this.renderForm(formikProps)
      )}/>
    );
  }
}

ItemHoldForm.propTypes = {
  event: PropTypes.object.optional,
  hold: PropTypes.object.optional
};

export default ItemHoldForm;