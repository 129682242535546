import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import moment from 'moment'

class WarehouseReportsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  dateRange() {
    const currentDate = new Date()
    const start      = [2019, 10, 1]
    const end        = [currentDate.getYear()+1900, currentDate.getMonth() + 1, 1]
    const startYear  = parseInt(start[0]);
    const endYear    = parseInt(end[0]);
    let dates      = [];
    const monthNames=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    for(let i = startYear; i <= endYear; i++) {
      const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      const startMon = i === startYear ? parseInt(start[1])-1 : 0;
      for(let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        const month = j+1;
        const displayMonth = month < 10 ? '0'+month : month;
        dates.push({date: [i, displayMonth, '01'].join('-'), display: `${monthNames[displayMonth-1]} ${i}`});
      }
    }
    return dates;
  }

  renderMonthlyReports() {

    const rows = this.dateRange().reverse().map(date =>
      <tr>
        <td>{date.display} Destroyed Items</td>
        <td className="spacing hidden-sm hidden-xs"> </td>
        <td><a href={`/warehouse/reports/monthly_destroyed?date=${date.date}`} className="pull-right">.xls Report</a></td>
      </tr>
    )
    return (
      <table className="warehouse-basic-table">
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  renderMonthlyIncomeReports() {

    const rows = this.dateRange().reverse().map(date =>
      <tr>
        <td>{date.display} Income</td>
        <td className="spacing hidden-sm hidden-xs"> </td>
        <td><a href={`/warehouse/reports/monthly_income?date=${date.date}`} className="pull-right">.xls Report</a></td>
      </tr>
    )
    return (
      <table className="warehouse-basic-table">
        <tr>
          <td>Year to Date Income</td>
          <td className="spacing hidden-sm hidden-xs"> </td>
          <td><a href={`/warehouse/reports/monthly_income?date=1-1-${moment().format("YYYY")}&end_date=${moment().endOf('month').format("DD-MM-YYYY")}`} className="pull-right">.xls Report</a></td>
        </tr>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  renderRentersReports() {
    const rows = this.props.renters.map(renter =>
      <tr>
        <td>{renter.name}'s Items</td>
        <td className="spacing hidden-sm hidden-xs"> </td>
        <td><a href={`/warehouse/reports/renter?id=${renter.id}`} target="_blank" className="pull-right">.xls Report</a></td>
      </tr>
    )
    return (
      <table className="warehouse-basic-table">
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <WarehouseLayout currentPage="dashboard">
        <div className="warehouse-card">
          <h1> Monthly Income </h1>
          {this.renderMonthlyIncomeReports()}
        </div>
        <div className="warehouse-card">
          <h1> Monthly Reports </h1>
          {this.renderMonthlyReports()}
        </div>
        <div className="warehouse-card">
          <h1> Renter Reports </h1>
          {this.renderRentersReports()}
        </div>
      </WarehouseLayout>
    );
  }
}

WarehouseReportsDashboard.propTypes = {
};

export default WarehouseReportsDashboard;
