import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"

class CatalogDashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {}
  }


  renderCategories(group) {
    const { categories } = this.props;
    const actualGroup = (group == "other") ? null : group;
    return categories.filter(category => category.group_name == actualGroup).filter((unused,index) => index < 4).map(category => (
      <a className="category-link" href={`/category/${escape(group).replace("/","%2F")}/${escape(category.name).replace("/","%2F")}`}>
        {category.name}
      </a>
    ));
  }
  renderGroups() {
    const { groups } = this.props;

    return groups.filter(g => g).map(group => (
      <div key={"group"+group} className="col-xs-12 col-sm-6 col-md-4 catalog-dashboard-group-container">
        <a href={`/category/${group}`} className="catalog-dashboard-group row">
          <div className="col-xs-6 image-container">
            <div className="image" style={{color: "red", backgroundImage: `url(/group/${group}/group_image.jpg)`}}>
              &nbsp;
            </div>
          </div>
          <div className="col-xs-6">
            <a href={`/category/${group}`}><h2>{group || "Other"}</h2></a>
            {this.renderCategories(group)}
            <a className="category-link" href={`/category/${group}`}>
              and more
            </a>
          </div>
        </a>
      </div>
    ));
  }

  render() {
    return (
      <PublicCatalogLayout currentPage="home"  classNames={{container: 'full-body-container '}} {...this.props}>
        <br />
        <br />
        <div className="row">
          {this.renderGroups()}
        </div>
      </PublicCatalogLayout>
    );
  }
}

CatalogDashboard.propTypes = {

};

export default CatalogDashboard;
