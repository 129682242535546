import { isDate } from "moment";

export function isJaguar(user) {
  return user && user.role === "superadmin" || user.role === "jaguar";
}

export function isSuperadmin(user) {
  return user.role === "superadmin";
}

export function hasWarehouseAccess(user) {
  return user.role === "superadmin";
}

export function isClient(user) {
  return user.role === "client";
}

export function canEditEvent(event,user) {
  console.log(user);
  if(!event || !user) {
    return false;
  }
  if(event.renters && user.renters) {
    const event_renter_ids = event.renters.map(x=>x.id);
    const user_renter_ids = user.renters.map(x=>x.id);
    return event_renter_ids.filter(x=> user_renter_ids.indexOf(x) > -1).length > 0;
  }
}

export function canEditItem(item,user) {
  console.log({
    item,
    user
  })
  if(isJaguar(user)) {
    return true;
  }
  if(!item || !user) {
    return false;
  }
  console.log("Made it this far")
  if(item.renter_id && user.renters) {
    const item_renter_id = item.renter_id
    const user_renter_ids = user.renters.map(x=>x.id);
    return user_renter_ids.indexOf(item_renter_id) > -1;
  }
}
