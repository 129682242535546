import React, { Component } from 'react'
import PublicCatalogLayout from '../layouts/PublicCatalogLayout'
import Cookies from 'universal-cookie'
import OrderDropdown from './partials/OrderDropdown';

export default class OrdersDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: this.props.orders, 
      showingArchived: false,
    }
    this.toggleShowArchived = this.toggleShowArchived.bind(this);
    this.archiveOrder = this.archiveOrder.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
  }

  toggleShowArchived(e) {
    e.preventDefault();
    const {showingArchived} = this.state;
    this.setState({showingArchived: !showingArchived})
  }

  archiveOrder(id) {
    if (window.confirm(`Archive Order #${id}?`)) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      return fetch(`/orders/${id}/archive`, {
        method: "PATCH",
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (!json.errors) {
          let orders = this.state.orders;
          orders[orders.findIndex((order) => order.id == id)].archived = true;
          this.setState({orders: orders})
        } else {
          alert(json.errors);
        }
      });
    }
  }

  deleteOrder(id) {
    if (window.confirm(`Delete Order #${id}?`)) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/orders/${id}`, {
        method: "DELETE",
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(res => {
        if (res.status == 204) {
          let orders = this.state.orders;
          orders.splice(orders.findIndex((order) => order.id == id), 1);
          this.setState({orders: orders})
        } else {
          alert(res.errors);
        }
      });
    } 
  }

  renderOrders() {
    const {orders, showingArchived} = this.state;
    const {user} = this.props;
    return (
      <>
        {
          orders.filter(order => (showingArchived ? true : !order.archived)).map((order) => (
            <OrderDropdown archiveOrder={this.archiveOrder} deleteOrder={this.deleteOrder} order={order} user={user}/>
          ))
        }
      </>
    )
  }
  
  render() {
    const {showingArchived} = this.state
    const {user, renter} = this.props 
    return (
      <PublicCatalogLayout
        {...this.props}
        groups={[]}
        currentPage="orders_index"
        classNames={{body: 'white-background'}}
      >
        <div className='full-body-container'>
          <div className='orders-page-header'>
            <h2>Orders{user.role == "client" && renter && ` by ${renter.name}`}</h2>
            <a href=""><div onClick={this.toggleShowArchived}>{showingArchived ? "Hide" : "Show"} Archived</div></a>            
          </div>
          {this.renderOrders()}
        </div>
      </PublicCatalogLayout>
    )
  }
}
