import React from "react";
import PropTypes from "prop-types";

class WarehouseItemCounter extends React.Component {
  constructor(props) {
    super(props);
    console.log({props})
    this.state = {
      count: item.owned
    }
  }



  render() {
    const { event, sortBy, filterBy } = this.props;
    return (
      <div className="">
        {count == null ? ("Loading") : count}
      </div>
    );
  }
}

WarehouseItemCounter.propTypes = {
  item: PropTypes.object.isRequired,
};

export default WarehouseItemCounter;
