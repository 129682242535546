import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { Formik, Field, Form } from "formik";
import { isJaguar, isSuperadmin } from "../utility/UserUtils"
import Select from 'react-select'
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
class UsersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
    };

    this.deleteUser = this.deleteUser.bind(this);
    this.reactivateUser = this.reactivateUser.bind(this);
  }

  renderHeader(){
    const {editing_user} = this.props;
    if(editing_user.id){
      return (<h1> Editing: {editing_user.first_name} {editing_user.last_name} </h1>)
    }
    return (
      <h1> Create a New User </h1>
    )
  }
  renderForm(formikProps) {
    return (
      <Form className="users-form" id="event-edit-form">
        {this.renderHeader()}
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <label>First Name </label>
            <Field
              className="users-form-input form-control"
              type="text"
              name="user[first_name]"
              placeholder="first Name"
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <label>Last Name </label>
            <Field
              className="users-form-input form-control"
              type="text"
              name="user[last_name]"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <label> Email </label>
            <Field
              className="users-form-input form-control"
              type="text"
              name="user[email]"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <label> Slack Username (leave blank if you dont want notifications) </label>
            <Field
              className="users-form-input form-control"
              type="text"
              name="user[slack_username]"
              placeholder="Slack Username"
            />
          </div>
        </div>
        {this.renderRoleSelection()}
        {this.renderPasswordEntry()}
        <div className="row">
          <div className="col-xs-12">
            <br />
            <button className="btn btn-primary" type="submit" disabled={formikProps.isSubmitting}>Save</button>  
            {this.renderDeleteButton()}
          </div>
        </div>
      </Form>
    )
  }

  renderPasswordEntry(){
    const { editing_user } = this.props;
    if(!editing_user.id){
      return (
        <div className="row">
          <div className="col-xs-12">
            <label> Password </label>
            <Field
              className="users-form-input form-control"
              type="text"
              name="user[password]"
              placeholder="Password"
            />
          </div>
          <div className="col-xs-12">
            <label> Password Confirmation</label>
            <Field
              className="users-form-input form-control"
              type="text"
              name="user[password_confirmation]"
              placeholder="Password Confirmation"
            />
          </div>
        </div>
      )
    }
  }

  renderRoleSelection(){
    const { editing_user, user } = this.props;
    let options = [
      {value: 'jaguar', label: 'Jaguar'},
      {value: 'client', label: 'Client'},
      {value: 'superadmin', label: 'Super Admin', isDisabled: !isSuperadmin(user)}
    ]
    if(!isSuperadmin(user) && isSuperadmin(editing_user)) {
      return (
        <select disabled value="superadmin">
          <label> Role </label>
          <option value="superadmin"> SuperAdmin </option>
        </select>
      )
    }
    else {
      return (
        <div className="row">
          <div className="col-xs-12">
            <label> Role </label>
            <Select
              defaultValue={options.find(option => option.value==editing_user.role)}
              name="user[role]"
              options={options}
            />
          </div>
        </div>
      )
    }
  }

  renderDeleteButton() {
    const { user, editing_user } = this.props;
    if(isSuperadmin(user) && editing_user.id){
      if(editing_user.active){
        return (
          <button
            className="btn btn-danger"
            type="button"
            onClick={this.deleteUser}
          >
            Delete
          </button>
        )        
      }
      else {
        return (
          <button
            className="btn btn-danger"
            type="button"
            onClick={this.reactivateUser}
          >
            Reactivate
          </button>
        )
      }
    }
  }

  formUrl() {
    const { editing_user } = this.props;
    if(editing_user.id){
      return `/users/${editing_user.id}/`
    }
    return `/users/`
  }

  formMethod() {
    const { editing_user } = this.props;
    if(editing_user.id){
      return 'PATCH'
    }
    return 'POST'
  }

  async deleteUser() {
    if(confirm("Are you sure you want to delete this user? This cannot be undone.")){

      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      await fetch(this.formUrl(), {
        method: "DELETE",
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token
        }
      })
      location.href = "/users"
    }
  }

  async reactivateUser() {
    if(confirm("Are you sure you want to reactivate this user? This will give them access to many functions.")){
      const { editing_user } = this.props;
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      await fetch(`/users/${editing_user.id}/reactivate`, {
        method: "POST",
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token
        }
      })
      location.href = "/users"

    }
  }

  render() {
    const {editing_user} = this.props;
    return (
      <PublicCatalogLayout currentPage="home" {...this.props}>
        <br />
        <Formik
            initialValues={{
              user: {
                first_name: editing_user.first_name || "",
                last_name: editing_user.last_name || "",
                email: editing_user.email || "",
                slack_username: editing_user.slack_username || "",
              }
            }}
            onSubmit={(values, actions) => {
              const form = document.getElementById("event-edit-form");
              var data = new FormData(form);

              const cookies = new Cookies();
              const token = cookies.get("X-CSRF-Token");

              fetch(this.formUrl(), {
                method: this.formMethod(),
                redirect: "manual",
                body: data,
                headers: {
                  "X-CSRF-Token": token
                }
              })
                .then(response => {
                  return response.json();
                })
                .then(json => {
                  if (json.error == null) {
                    window.location.replace(json.url);
                  } else {
                    alert(json.error);
                  }
                });
            }}
            render={(formikProps) => (
              this.renderForm(formikProps)
          )}/>
        </PublicCatalogLayout>
    );
  }
}

UsersForm.propTypes = {
  editing_user: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default UsersForm;
