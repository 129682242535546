import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import CreateShipment from "./CreateShipment";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import tnv from 'tracking-number-validation'
import Cookies from "universal-cookie";
import moment from 'moment';
import { createElement } from "dropzone";

class WarehouseShippingDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowID: null,
      createShipmentIsOpen: false,
      isEditingTitle: false,
      isEditingNote: false,
      editTitleValue: "",
      editNoteValue: "",
      trackings: this.props.shipments.data.trackings,
      tableIsLoading: false,
    }

    this.tableColumns = [
      {
        Header: 'Title',
        accessor: 'title' // String-based value accessors!
      },
      {
        Header: 'Tracking #',
        accessor: 'tracking_number'
      },
      {
        Header: 'Shipment Type',
        accessor: 'shipment_type'
      },
      {
        Header: 'Status',
        accessor: 'tag'
      },
      {
        Header: 'Pickup Time',
        accessor: 'shipment_pickup_date',
        Cell: props => <span>{props.value ? moment(props.value).format('MMMM D YYYY, h:mm a') : "N/A"}</span>
      },
      {
        Header: 'Delivery Time',
        accessor: 'shipment_delivery_date',
        Cell: props => <span>{props.value ? moment(props.value).format('MMMM D YYYY, h:mm a') : "N/A"}</span>
      },
    ];
    this.selectRow = this.selectRow.bind(this);
    this.toggleEditTitle = this.toggleEditTitle.bind(this);
    this.toggleEditNote = this.toggleEditNote.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.handleTitleSubmit = this.handleTitleSubmit.bind(this);
    this.handleNoteSubmit = this.handleNoteSubmit.bind(this);
    this.toggleCreateShipment = this.toggleCreateShipment.bind(this);
    this.getTrackingData = this.getTrackingData.bind(this);
    this.toggleTableLoading = this.toggleTableLoading.bind(this);
  }

  getTrackingData() {
    this.setState({tableIsLoading: true})
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token")
    return fetch("/warehouse/shipping/get_shipments", {
      method: 'GET',
      redirect: 'manual',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      this.setState({trackings: json.data.trackings, tableIsLoading: false});
      return json;
    })
    .catch((err) => {
      console.error(err);
    })
  }

  selectRow(id) {
    this.setState({
      selectedRowID: id,
      isEditingTitle: false,
      editTitleValue: "",
      isEditingNote: false,
      editNoteValue: "",
    })
  }
  
  toggleCreateShipment() {
    const {createShipmentIsOpen} = this.state;
    this.setState({createShipmentIsOpen: !createShipmentIsOpen})
  }
  
  toggleTableLoading() {
    const {tableIsLoading} = this.state;
    this.setState({tableIsLoading: !tableIsLoading})
  }

  handleDeleteShipment(courier, tracking_number) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token")
    fetch(`/warehouse/shipping/delete_shipment?courier=${courier};tracking_number=${tracking_number}`, {
      method: 'DELETE',
      redirect: 'manual',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      },
    })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      this.getTrackingData();
      this.setState({selectedRowID: null})
    })
    .catch((err) => {
      alert(err);
    })
  }

  trackingUpdateRequest(updates, tracking_number, courier) { //takes updates in key value pairs(object), must be readable by AftershipAPI
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token")
    console.log(updates)
    return fetch("/warehouse/shipping/update_shipment", {
      method: 'PATCH',
      redirect: 'manual',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({
        updates: updates,
        tracking_number: tracking_number,
        courier: courier,
      })
    })
  }
  
  toggleEditTitle(currentTitle = "") {
    const {isEditingTitle} = this.state;
    this.setState({isEditingTitle: !isEditingTitle, editTitleValue: currentTitle})
  }

  toggleEditNote(currentNote = "") {
    const {isEditingNote} = this.state;
    this.setState({isEditingNote: !isEditingNote, editNoteValue: currentNote})
  }
  
  handleTitleChange(e) {
    this.setState({editTitleValue: e.target.value})
  }

  handleNoteChange(e) {
    this.setState({editNoteValue: e.target.value})
  }

  handleTitleSubmit(tracking_number, courier) {
    const {editTitleValue} = this.state;
    this.trackingUpdateRequest({title: editTitleValue}, tracking_number, courier)
    .then(res => {
      this.toggleEditTitle();
    })
    .then(() => {
      this.getTrackingData();
    })
    .then(() => {
      this.renderSelectedShipment();
    })
    .catch(err => {
      alert(err);
    })
    
  }

  handleNoteSubmit(tracking_number, courier) {
    const {editNoteValue} = this.state;
    this.trackingUpdateRequest({note: editNoteValue}, tracking_number, courier)
    .then(res => {
      this.toggleEditNote();
    })
    .then(() => {
      this.getTrackingData();
    })
    .then(() => {
      this.renderSelectedShipment();
    })
    .catch(err => {
      alert(err);
    })
  }

  renderCheckpointLocation(checkpoint) {
    if(checkpoint.city && checkpoint.country_name){
      return `${checkpoint.city}, ${checkpoint.country_name}`
    }
    if(checkpoint.country_name){
      return checkpoint.country_name;
    }
    if(checkpoint.city){
      return checkpoint.city;
    }
    return "No Location Data"
  }
  renderCheckpoints(selectedRow) {
    const checkpoints = selectedRow.checkpoints.map(checkpoint => (
      <tr>
        <td> {checkpoint.checkpoint_time}</td>
        <td>{checkpoint.message}</td>
        <td>{this.renderCheckpointLocation(checkpoint)}</td>
      </tr>
    ));

    if(checkpoints.length > 0) {
      return (
        <React.Fragment>
          <h5>Checkpoints: </h5>
          <table className="warehouse-shipping-checkpoint-table">
            {checkpoints}
          </table>
        </React.Fragment>
      )
    }

    return (
      <h5>No checkpoints are available at this time.</h5>
    )
  }

  renderTrackingUrl(tracking_number) {
    const courier = tnv.getCourier(tracking_number)
    if(courier.length != 1) {
      return (
        <span>
          Tracking #:
          {tracking_number}
          {courier.map(c => (
            <a href={tnv.getTrackingUrl(tracking_number,c)} target="_blank" >
              ({c})
            </a>
          ))}
        </span>
      )
    }
    else {
      return (
        <span>
          Tracking #:
          <a
            style={{display:"inline"}}
            target="_blank"
            href={tnv.getTrackingUrl(tracking_number)}
          >
            {tracking_number} ({courier[0]})
          </a>
        </span>
      );
    }
  }

  renderEditNote(tracking_number, courier) {
    const {isEditingNote} = this.state;
    if (!isEditingNote) {
      return (
        <div><button className="warehouse-shipping-button selected-shipment" onClick={this.toggleEditNote}>Add a note</button></div>
      )
    } else {
      return (
        <form className="warehouse-shipping-note-form" onSubmit={() => this.handleNoteSubmit(tracking_number,courier)}>
          <textarea onChange={this.handleNoteChange}/>
          <button className="warehouse-shipping-button" type="submit">Submit</button>
        </form>
      )
    }
  }

  renderSelectedShipment() {
    const { selectedRowID, isEditingTitle, trackings } = this.state;
    if(selectedRowID){
      const selectedRow = trackings.find(shipment =>
        shipment.id == selectedRowID
      );
      if(selectedRow){
        return (
          <div className="warehouse-card" id="checkpoints">
            
              {
                isEditingTitle ? 
                <h4>Shipment: 
                  <form onSubmit={() => this.handleTitleSubmit(selectedRow.tracking_number, selectedRow.slug)}>
                    <input className="warehouse-shipping-input" value={this.state.editTitleValue} onChange={(e) => this.handleTitleChange(e)}/>
                    <button className="warehouse-shipping-button" type="submit">Save</button>
                  </form>
                </h4> 
                : 
                <div className="warehouse-shipping-selected-header">
                  <h4>Shipment: "{selectedRow.title}"  
                    <i className="fas fa-edit warehouse-shipping-icon" style={{color: "white", paddingLeft: "5px"}} onClick={() => this.toggleEditTitle(selectedRow.title)}/>
                  </h4>
                  <i className="fas fa-trash-alt warehouse-shipping-icon" onClick={() => {this.handleDeleteShipment(selectedRow.slug, selectedRow.tracking_number)}}/>
                </div>
              }
            
            ID #{selectedRow.id}
            <br />
            {
              selectedRow.note && selectedRow.note.length > 0 ? 
              <div><h5>Note:</h5><p>{selectedRow.note}</p></div> : 
              this.renderEditNote(selectedRow.tracking_number, selectedRow.slug)
            }
            <br />
            {this.renderTrackingUrl(selectedRow.tracking_number)}
            {this.renderCheckpoints(selectedRow)}
          </div>
        );
      }
    }
    else {
      return (
        <div className="warehouse-card">
          <h4>
            No Shipment Selected.
          </h4>
        </div>
      )
    }
  }

  render() {
    const {createShipmentIsOpen, trackings, tableIsLoading} = this.state;
    return (
      <WarehouseLayout currentPage="dashboard">
        <div className="warehouse-card">
          <h1> Shipment Dashboard </h1>
          <div>
            {
              !createShipmentIsOpen ? 
              <button className="warehouse-shipping-button" onClick={this.toggleCreateShipment}>Add a shipment</button> : 
              <CreateShipment toggleCreateShipment={this.toggleCreateShipment.bind(this)} toggleTableLoading={this.toggleTableLoading} getTrackingData={this.getTrackingData}/>
            }
          </div>
          <ReactTable
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  this.selectRow(rowInfo.original.id);
                  window.location.href = "#checkpoints";
                  if (handleOriginal) {
                    handleOriginal()
                  }
                }
              }
            }}
            data={trackings}
            loading={tableIsLoading}
            columns={this.tableColumns}
            showPageSizeOptions={true}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            defaultPageSize={10}
            sortable={true}
            filterable={true}
          />
          
        </div>
        {this.renderSelectedShipment()}
        <br />
      </WarehouseLayout>
    );
  }
}

WarehouseShippingDashboard.propTypes = {
  shipments: PropTypes.object.isRequired,
};

export default WarehouseShippingDashboard;
