import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class GenerateEventReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      sortBy: "name",
      opts: [
        {value: true, label: "Quantity Reserved", name: "show-reserved"},
        {value: true, label: "Quantity Shipped", name: "show-shipped"},
        {value: true, label: "Pallet", name: "show-pallet"},
        {value: true, label: "Locked", name: "show-locked"},
        {value: true, label: "Shipping Location", name: "show-shipping-location"},
        {value: true, label: "Distribution Location", name: "show-distribution-location"},
        {value: true, label: "Direct to Site", name: "show-direct-to-site"},
        {value: true, label: "Recipient", name: "show-recipient"},
        {value: true, label: "Notes", name: "show-notes"}, 
        {value: true, label: "Signage Code", name: "show-signage-code"},
        {value: false, label: "Last Updated By", name: "show-updated"},
        {value: false, label: "Rental Price", name: "show-rental-price"},
        {value: false, label: "Product Information", name: "show-product-information"},
        {value: false, label: "On Site Checkboxes", name: "show-on-site-checkboxes"},
      ],
      specialOptionSelected: false,
      consolidateQuantities: false,
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateSortBy = this.updateSortBy.bind(this);
    this.updateOpts = this.updateOpts.bind(this);
    this.updateConsolidateQuantities = this.updateConsolidateQuantities.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openModal(){
    this.setState({
      modalOpen: true
    })
  }

  closeModal(){
    this.setState({
      modalOpen: false
    })
  }

  renderAccessButton() {
    const { button } = this.props;
    const { modalOpen } = this.state;
    if( button ) {
      return (
        <span onClick={this.openModal}>
          {button}
        </span>
      );
    }
    return (
      <a
        type="button"
        role="button"
        onClick={this.openModal}
      >
        Download Report
      </a>
    );
  }

  renderInclusions(){
    const { opts, specialOptionSelected } = this.state;
    return opts.map(s =>
      <div className="col-xs-6 event-report-selection event-report-selection-sort">
        <input disabled={specialOptionSelected} type="checkbox" onChange={this.updateOpts} name={s.name} value={s.name} checked={s.value} />
        <label> {s.label}</label>
      </div>
    )
  }

  updateSortBy(e){
    if(e.target.checked){
      this.setState({
        sortBy: e.target.value
      })
    }
  }

  renderSortBy(){
    const { sortBy, specialOptionSelected } = this.state;
    const sortable=[
      {label: "Item Name", value: "name"},
      {label: "Category", value: "category"},
      {label: "Status", value: "status"},
      {label: "Recipient", value: "recipient"},
      {label: "Shipping Location", value: "shipping-location"},
      {label: "Distribution Location", value: "distribution-location"}
    ]
    return sortable.map(s =>
      <div className="col-xs-12 event-report-selection event-report-selection-sort">
        <input disabled={specialOptionSelected} type="checkbox" onChange={this.updateSortBy} name={s.value} value={s.value} checked={sortBy==s.value} />
        <label> {s.label}</label>
      </div>
    )
  }
  
  renderSpecialOptions() {
    const {consolidateQuantities} = this.state;
    return (
      <div className="col-xs-12 event-report-selection">
        <input type="checkbox" onChange={this.updateConsolidateQuantities} name="consolidate-quantities" value={consolidateQuantities} checked={consolidateQuantities} />
        <label>Consolidate item/kit quantities</label>
      </div>
    )
  }

  updateOpts(e){
    const { opts } = this.state;
    const index = opts.findIndex(opt => opt.name == e.target.value);
    if(index != null && index != -1){
      opts[index].value = e.target.checked;
    }
    this.setState({
      opts: opts
    })
  }

  updateConsolidateQuantities(e){
    const {specialOptionSelected} = this.state
    this.setState({
      consolidateQuantities: e.target.checked,
      specialOptionSelected: !specialOptionSelected
    })
  }

  renderMenu(){
    return (
      <div className="generate-event-report-menu">
        <div className="row">
          <div className="col-xs-12 report-menu-title">
            Report
          </div>
          <div className="col-xs-12 report-menu-description">
            Uncheck the items you do not wish to include in the report.
          </div>
        </div>
        <div className="row">
          {this.renderInclusions()}
          {/*  */}
        </div>
        <div className="row">
          <div className="col-xs-12 report-menu-description">
            Sort Report By
          </div>
          {this.renderSortBy()}
        </div>
        <div className="row">
          <div className="col-xs-12 report-menu-description">
            Special Options
          </div>
          {this.renderSpecialOptions()}
        </div>
        <br />
        <div className="row">
          <div className="col-xs-12">
            <button style={{marginRight: "5px"}} type="submit" className="modal-submit-button" onClick={this.handleSubmit}>
              Create Report PDF
            </button>
            <button type="button" style={{marginTop: "10px", marginLeft: "0"}} onClick={this.closeModal} className="modal-cancel-button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderForm(){
    const { event} = this.props;
    const { sortBy, opts, consolidateQuantities } = this.state;
    const inputs = opts.map(o =>
      <input type="checkbox" className="hidden" name={`opts[${o.name}]`} checked={o.value} />
    )
        const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    return(
      <form method="POST" action={`/events/${event.slug}/report.pdf`}>
        <input type="hidden" name="X-CSRF-Token" value={token}/>
        <input type="hidden" name="sort_by" value={sortBy} />
        <input type="hidden" name="opts['keep-on-null']" value="keep"/>
        <input type="checkbox" className='hidden' name="opts[consolidate-quantities]" checked={consolidateQuantities}/>
        {inputs}
        <button style={{marginRight: "5px"}} type="submit" className="modal-submit-button">
          Create Report PDF
        </button>
        {/* <button formaction={`/events/${event.slug}/report.xlsx`} type="submit" className="modal-submit-button">
          Download Xlsx
        </button> */}
        <br/>
        <button type="button" style={{marginTop: "10px", marginLeft: "0"}} onClick={this.closeModal} className="modal-cancel-button">
          Cancel
        </button>
      </form>
    )
  }

  handleSubmit() {
    const { event, handleNewReport } = this.props;
    const { sortBy, opts, consolidateQuantities } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    let fieldOptions = {}
    opts.forEach((opt) => {
      fieldOptions[opt.name] = opt.value
    })

    fetch(`/events/${event.slug}/report.pdf`, {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify({
        sort_by: sortBy,
        opts: fieldOptions
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then((res) => res.json())
    .then((json) => {
      if (json.status == 400) {
        alert(`Error: ${json.message}`)
      } else {
        handleNewReport({
          completed: false,
          name: json.filename,
        })
      }
      this.closeModal()
    })
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal generate-report-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel=""
        >
          {this.renderMenu()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default GenerateEventReportModal
