import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class ShippingAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    const { category } = this.props;
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { button } = this.props;
    if( button ) {
      return (
        <div onClick={this.openModal}>
          {button}
        </div>
      );
    }
    return (
      <button
        type="button"
        role="button"
        onClick={this.openModal}
        className="event-dashboard-add-event"
      >
        Add Address
      </button>
    );
  }

  renderSaveButton(formikProps) {
    return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Save </button>
  }

  formMethod() {
    const { address } = this.props;
    if( address ){
      return 'PATCH'
    }
    return 'POST'
  }

  formURL() {
    const { address } = this.props;
    if( address ){
      return  `/events/shipping_addresses/${address.id}`
    }
    return `/events/shipping_addresses/`
  }

  renderForm(){
    const { address } = this.props;
    return (
      <Formik
        initialValues={{
          shipping_address: {
            venue_name: address ? address.venue_name : "",
            company_name: address ? address.company_name : "",
            first_name: address ? address.first_name : "",
            last_name: address ? address.last_name : "",
            address_line_1: address ? address.address_line_1 : "",
            address_line_2: address ? address.address_line_2 : "",
            city: address ? address.city : "",
            state_province: address ? address.state_province : "",
            postal_code: address ? address.postal_code : "",
            country: address ? address.country : "",
            tax_id: address ? address.tax_id : "",
            phone: address ? address.phone : "",
            email: address ? address.email : "",
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.closeModal();
                if(address) {
                  this.props.updateShippingAddress(json.shipping_address);
                } else {
                  this.props.addShippingAddress(json.shipping_address);
                }

              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form id="item-attachment-form">
            <div className="row">
              <div className="col-xs-12">
                <label for="shipping_address[venue_name]"> Name: (e.g. Aria, Jaguar Design Studio - Aptos) </label>
                <Field required="true" className="form-control" name="shipping_address[venue_name]" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <label> Recipient First Name: </label>
                <Field className="form-control" name="shipping_address[first_name]" />
              </div>
              <div className="col-xs-12 col-sm-6">
                <label> Recipient Last Name: </label>
                <Field className="form-control" name="shipping_address[last_name]" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <label> Contact Phone #</label>
                <Field className="form-control" name="shipping_address[phone]" />
                <label> Contact Email</label>
                <Field className="form-control" name="shipping_address[email]" />
                <label> Address Line #1</label>
                <Field className="form-control" name="shipping_address[address_line_1]" />
                <label> Address Line #2</label>
                <Field className="form-control" name="shipping_address[address_line_2]" />
                <label> City</label>
                <Field className="form-control" name="shipping_address[city]" />
                <label> State / Province </label>
                <Field className="form-control" name="shipping_address[state_province]" />
                <label> Postal Code </label>
                <Field className="form-control" name="shipping_address[postal_code]" />
                <label> Country </label>
                <Field className="form-control" name="shipping_address[country]" />
                <br />
                <div className="row message-submit-button-area">
                  <div className="col-xs-12 col-sm-4 col-md-3">
                    <button type="submit" className="item-hold-form-submit-button">Submit</button>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-md-3">
                    <button type="button" onClick={this.closeModal} className="item-hold-form-cancel-button">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-attachment-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Attachment"
        >
          <div className="react-modal-close-button" onClick={this.closeModal}> &#10005; </div>
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default ShippingAddressModal
