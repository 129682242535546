import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import WarehouseLayout from "../WarehouseLayout"
import KitItemSearchableTable from "./KitItemSearchableTable"
import KitImageModal from './KitImageModal'

class EditKit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      kitName: this.props.kit.name || "",
      categoryId: this.props.kit.category_id || null,
      renterId: this.props.kit.renter_id || null,
      selectedItems: [],
      kitImage: null,
      kitImageEdited: false,
    }
    this.updateSearchText = this.updateSearchText.bind(this);
    this.updateName = this.updateName.bind(this);
    this.updateCategoryId = this.updateCategoryId.bind(this);
    this.updateRenterId = this.updateRenterId.bind(this);
    this.setImage = this.setImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.addItem = this.addItem.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const {kit_items} = this.props
    let selected = kit_items.map(ki => ({id: ki.item.id, name: ki.item.name, owned: ki.item.owned, amount: ki.count}))
    this.setState({selectedItems: selected})
  }

  updateSearchText(e) {
    this.setState({
      searchText: e.target.value,
    })
  }

  updateName(e) {
    this.setState({
      kitName: e.target.value,
    })
  }

  updateCategoryId(e) {
    this.setState({
      categoryId: e.target.value,
    })
  }

  updateRenterId(e) {
    this.setState({
      renterId: e.target.value,
    })
  }

  setImage(image) {
    this.setState({
      kitImage: image,
      kitImageEdited: true,
    })
  }

  deleteImage() {
    this.setState({
      kitImage: null,
      kitImageEdited: true,
    })
    alert("Changes made to the kit image will only take full effect after you've saved the kit, you can refresh if you wish to retrieve the deleted image")
  }

  addItem(item) {
    let currentItems = this.state.selectedItems.slice();
    currentItems.push({id: item.id, name: item.name, amount: 1, owned: item.owned})
    this.setState({selectedItems: currentItems})
  }

  removeItem(itemToRemove) {
    let currentItems = this.state.selectedItems.slice();
    currentItems.splice(currentItems.findIndex(item => item.id === itemToRemove.id), 1);
    this.setState({selectedItems: currentItems})
  }

  updateItemQuantity(e, item) {
    const {selectedItems} = this.state
    let items = selectedItems.slice();
    let itemIndex = selectedItems.findIndex((x) => x.id === item.id)
    items.splice(itemIndex, 1, {id: item.id, name: item.name, amount: Number(e.target.value), owned: item.owned})
    this.setState({selectedItems: items})
  }

  handleSave(e) {
    const {kitName, selectedItems, categoryId, renterId, kitImage, kitImageEdited} = this.state;
    const {kit} = this.props

    const data = new FormData();
    if (kitImageEdited) {
      if (kitImage) {
        data.append('kit_image', kitImage.image, kitImage.fileName);
      } else {
        data.append('kit_image', null);
      }
    }
    data.append('name', kitName);
    data.append('category_id', categoryId || null);
    data.append('renter_id', renterId || null)
    data.append('items', JSON.stringify(selectedItems) || null)

    if (kitName.length < 1) {
      alert("Please add name before submitting");
      return;
    } else {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/warehouse/kits/${kit.id}`, {
        method: 'PATCH',
        redirect: "manual",
        body: data,
        headers: {
          "X-CSRF-Token": token,
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === 200) {
          window.location = json.redirect_path;
        }
        else {
          alert(json.message);
        }
      })
      .catch(err => {
        console.log(err);
      })
    }
  }

  renderKitImage() {
    const { kitImage, kitImageEdited } = this.state
    let imgSrc, imgName;
    if (!kitImageEdited) {
      if (this.props.kit_image) {
        imgSrc = this.props.kit_image.url
        imgName = this.props.kit_image.image.image_file_name
      } else {
        return;
      }
    } else {
      if (kitImage) {
        imgSrc = kitImage.imagePreview;
        imgName = kitImage.fileName;
      } else {
        return;
      }
    }
    return (
      <table>
        <tbody>
          <tr className="item-form-image">
            <td>
              <img src={imgSrc} className="kit-image-preview" />
            </td>
            <td >
              {imgName}
            </td>
            <td>
              <button type="button" className="kit-image-delete btn btn-danger pull-right" onClick={() => this.deleteImage()}> Delete </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const {selectedItems, kitName, kitImage, kitImageEdited} = this.state;
    let {categories, kit, renters} = this.props;
    let groupNames = [];

    categories.forEach(({group_name}) => {
      if (!groupNames.includes(group_name)) {
        groupNames.push(group_name)
      }
    })

    let currentImage;
    kitImageEdited ? currentImage = kitImage : currentImage = this.props.kit_image

    return (
      <WarehouseLayout className="warehouse-item-index">
        <h1> Edit kit </h1>
        <div className="row">
          <div className="col-xs-12">
            <div className="warehouse-card">
             <div className="kit-edit-panel">
                <div className="kit-edit-name-section">
                  <label>Name</label>
                  <input onChange={this.updateName} value={this.state.kitName} className="form-control kit-input" type="text"/>
                </div>
                <div className="kit-edit-category-section">
                  <label>Category</label>
                  <select onChange={this.updateCategoryId} className="form-control kit-input">
                    <option value={""}></option>
                    {
                      groupNames.map((group) => {
                        return (
                          <optgroup label={group || "Other"}>
                            {
                              categories.filter((category) => category.group_name == group).map((category) => 
                                category.id === kit.category_id ? <option value={category.id} selected>{category.name}</option> : <option value={category.id}>{category.name}</option>
                              )
                            }
                          </optgroup>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="kit-edit-category-section">
                  <label>Renter</label>
                  <select onChange={this.updateRenterId} className="form-control kit-input">
                    <option value={null}>None (Moonlite)</option>
                    {
                      renters.map((renter) => (
                        <option value={renter.id} selected={renter.id === kit.renter_id}>{renter.name}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="kit-edit-image-section" id="images">
                  <div className="kit-image-section-title"> 
                    Image 
                  </div>
                  {this.renderKitImage()}
                  <div>
                    <KitImageModal setImage={this.setImage} currentImage={currentImage}/>
                  </div>
                </div>
                <div>
                  <h5>Kit Items: {selectedItems.length < 1 ? "None" : ""}</h5>
                  <table className="kit-item-selected-table">
                    
                    {selectedItems.map((item) => 
                      <tr> 
                        <td className="kit-item-selected-number-input-container">
                          <input className="form-control kit-item-selected-number-input" type="number" value={item.amount} max={item.owned} onChange={(e) => this.updateItemQuantity(e, item)}/>
                        </td>
                        <td className="kit-item-selected-name">
                          {item.name}
                        </td>
                        <td>
                          <i className="delete-icon fa fa-trash" onClick={() => this.removeItem(item)}/>
                        </td>
                      </tr>
                    )}
                  </table>
                  <div>
                    <button className="btn btn-green" onClick={this.handleSave}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="warehouse-card">
              <div>
                <h4>Add Items</h4>
                <input type="text" className="form-control kit-input" onChange={this.updateSearchText} placeholder="Search..."/>
              </div>
              <KitItemSearchableTable selectedItems={selectedItems} renters={this.props.renters} showOwners={true} items={this.props.items} searchText={this.state.searchText} addItem={this.addItem}/>
            </div>
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

export default EditKit;