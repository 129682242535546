import React from "react";

import { CssBaseline } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LicenseInfo } from "@mui/x-license";
import { ConfirmProvider } from "material-ui-confirm";

const MuiLayout = ({
  children
}) => {
  const MUI_KEY =
    "d17c34eea831104e9aaa6a1fcb440234Tz04NDAxMixFPTE3MzkxNDQzODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";
  LicenseInfo.setLicenseKey(MUI_KEY);
  const muiTheme = createTheme();

  return (
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-us">
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <CssBaseline />
              <ConfirmProvider>
                {children}
              </ConfirmProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
    </React.StrictMode>
  );
};

export default MuiLayout;
