import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogNavbar from "./partials/PublicCatalogNavbar"
import MuiLayout from "./MuiLayout"
class PublicCatalogLayout extends React.Component {

  constructor(props) {
    super(props);
    this.navBarRef= React.createRef();
    this.onBodyClick = this.onBodyClick.bind(this)
  }

  renderSubHeader() {
    const { subHeader } = this.props;
    if(subHeader) {
      return subHeader;
    }
  }

  onBodyClick(){
    try {
      this.navBarRef.current.toggleAdminMenu(false)
      this.navBarRef.current.searchButtonRef.current.toggleMenu(false)
    } catch (e) {

    }

  }

  renderFooter() {
    return (
      <div className="catalog-footer">
        <div className="container full-body-container ">
          <div className="catalog-footer-inner">
            <img className="footer-leaves" src="/images/footer-leaves.svg" />
          </div>
        </div>
      </div>
    )
  }
  render() {
    const {children, subHeader} = this.props;
    const classNames = this.props.classNames || {}
    return (
      <MuiLayout>
        <div className={`catalog-layout ${classNames.body || ""}`} onClick={this.onBodyClick}>
          <PublicCatalogNavbar subheader={this.props.subHeader} {...this.props} ref={this.navBarRef}/>
          {this.renderSubHeader()}
          <div className={`catalog-content container ${classNames.container || ""}`}>
            {this.props.children}
          </div>
          {this.renderFooter()}
        </div>
      </MuiLayout>
    );
  }
}

PublicCatalogLayout.propTypes = {

};

export default PublicCatalogLayout;
