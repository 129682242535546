import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Webcam from "react-webcam"
import DropzoneInline from "./DropzoneInline"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class ItemImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      photo_preview: props.photo ? props.photo.image : null,
      photo_uploaded: null,
      showPictureCapture: false,
      photo_filename: null,
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onDrop = this.onDrop.bind(this)

    this.videoRef = React.createRef();
    this.capturePicture = this.capturePicture.bind(this);
    this.dropzoneRef = React.createRef();
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
      photo_preview: this.props.photo ? this.props.photo.image : null,
      photo_uploaded: null,
      showPictureCapture: false
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { memory } = this.props;
    if(memory){
      return (<span role="button" className="memory-edit-button glyphicon glyphicon-pencil" onClick={this.openModal}/>)
    }
    return (<button type="button" role="button" onClick={this.openModal} className="btn btn-green">Add An Image</button>)
  }

  renderDropzone(){
    const {photo_preview, showPictureCapture} = this.state;
    if(!showPictureCapture){
      return (
        <DropzoneInline onDrop={this.onDrop} ref={this.dropzoneRef}/>
      )
    }
  }

  onDrop(file) {
    if (typeof window.FileReader !== "undefined") {
      var reader = new FileReader();
      reader.onload = e => {
        this.setState({
          photo_uploaded: file,
          photo_filename: file.name,
          photo_preview: e.target.result
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        photo_uploaded: file,
        photo_preview: null
      });
    }
  }


  renderSaveButton(formikProps){
    if(this.state.photo_uploaded){
      return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Save </button>
    }
    return <button className="btn btn-primary pull-right"type="button"  disabled> Save </button>
  }

  formMethod() {
    const { image } = this.props;
    if(image){
      return 'PATCH'
    }
    return 'POST'
  }

  formURL() {
    const { image, item } = this.props;
    if(image){
      return  `/webapi/images/${image.id}`
    }
    return `/webapi/images/`
  }

  renderTakePicture(){
    const { showPictureCapture } = this.state;
    if(showPictureCapture){
      return (
        <React.Fragment>
          <div className="row text-center">
            <div className="col-xs-12">
              <Webcam
                videoConstraints={{width: 500,  height: 500}}
                width={500}
                height={500}
                audio={false}
                screenshotFormat="image/jpeg"
                ref={this.videoRef}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <button className="btn btn-primary" onClick={this.capturePicture}>Capture Picture</button>
            </div>
          </div>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <div className="row text-center">
          <div className="col-xs-12">
            Or
          </div>
        </div>
        <div className="row text-center">
          <div className="col-xs-12">
            <button onClick={()=>this.setState({showPictureCapture: true})}className="btn btn-primary" href="#"> Take a Picture </button>
            <br />
          </div>
        </div>
      </React.Fragment>
    )

  }

  async capturePicture(){
    const screenshot = this.videoRef.current.getScreenshot();
    const name = `picture-${(Math.ceil(Math.random() * 1000000000))}.jpeg`

    const file = await fetch(screenshot)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], name, {type:'image/jpeg'});})
        .then((file) => {
            this.setState({
              photo_preview: screenshot,
              photo_uploaded: file,
              showPictureCapture: false,
            });
            this.dropzoneRef.current.onDrop([file]);
          }
        )
  }

  renderForm(){
    return (
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          var data = new FormData();
          const croppedBlob = this.dropzoneRef.current.cropImage()
          const filename = this.state.photo_filename && this.state.photo_filename != "blob" ? this.state.photo_filename : `picture-${(Math.ceil(Math.random() * 1000000000))}.jpeg`
          data.append('item_image[image]', croppedBlob, filename);
          data.append('item_image[item_id]', this.props.item.id);
          data.append('item_image[order]', this.props.index);
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: data,
            headers: {
              "X-CSRF-Token": token
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.closeModal();
                this.props.addImage(json.item_image);
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-image-form">
            {this.renderDropzone()}
            <br />
            {this.renderTakePicture()}
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-form-modal add-image-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Image"
        >
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default ItemImageModal
