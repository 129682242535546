import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { Formik, Field, Form } from "formik";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import WarehouseLayout from "../WarehouseLayout"


class WarehouseRentersForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      renterUsers: props.renter.users,
      selectedUser: null,
    }

    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);
    this.removeAssociatedUser = this.removeAssociatedUser.bind(this);
    this.updateSelectedUser = this.updateSelectedUser.bind(this);
  }

  renderAssociatedUsers() {
    const { renter } = this.props;
    if(renter.id) {
      const { renterUsers } = this.state;
      const associatedUsersRows = renterUsers.map(user => (
        <tr>
          <td>
            <a href={`/users/${user.id}/edit`}>
              {user.email}
            </a>
          </td>
          <td> </td>
          <td className="options">
            <button type="button" className="btn btn-danger pull-right" onClick={()=>this.removeAssociatedUser(user)}>Remove</button>
          </td>
        </tr>
      ));
      return (
        <div className="warehouse-card">
          <div className="row">
            <div className="col-xs-12">
              <h3>Associated Users</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-8">
              <Select
                styles={{menu: (provided, state)=>({color: "black", background: "white"})}}
                options={this.getUserOptions()}
                value={this.state.selectedUser}
                onChange={this.updateSelectedUser}
              />
            </div>
            <div className="col-xs-4">
              <button className="btn btn-green pull-right" onClick={() => this.addAssociatedUser()}> Add User </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <table className="warehouse-basic-table"> 
                <tbody>
                  {associatedUsersRows}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
  }

  updateSelectedUser(selectedOption){
    this.setState({
      selectedUser: selectedOption,
    })
  }

  getUserOptions(){
    let { users } = this.props;
    const { renterUsers } = this.state;
    return users.filter(user => !renterUsers.map(u => u.id).includes(user.id))
                .map(user => {return {value: user.id, label: user.email}});
  }

  addAssociatedUser() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { renter } = this.props;
    const { renterUsers, selectedUser } = this.state;
    if(selectedUser) {
      fetch(`/warehouse/renters/${renter.id}/add_user`, {
      method: 'POST',
      redirect: "manual",
      body: JSON.stringify({
        user_id: selectedUser.value
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            renterUsers: renterUsers.concat([json.user]),
            selectedUser: null
          });
        } else {
          alert(json.error);
        }
      }); 
    } 
  }

  removeAssociatedUser(user) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const { renter } = this.props;
    const { renterUsers } = this.state;
    fetch(`/warehouse/renters/${renter.id}/remove_user`, {
      method: 'DELETE',
      redirect: "manual",
      body: JSON.stringify({
        user_id: user.id 
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            renterUsers: renterUsers.filter(user2 => user2.id != user.id)
          });
        } else {
          alert(json.error);
        }
      }); 
  }

  renderForm(formikProps) {
     const { renter } = this.props;
    return (
      <React.Fragment>
      <div className="warehouse-card warehouse-renters-form-container">
        <Form className="renters-form" id="renters-form">
          <div className="col-xs-12">
            <label> Renter Name: </label>
            <Field className="form-control" name="renter[name]" disabled={renter.jaguar}/>
          </div>
          <div className="col-xs-12">
            <br />
            <button className="btn btn-primary button-margin-right" type="submit"> Save </button>
            <a href="/warehouse/renters"><button onClick={() => history.go(-1)} className="btn btn-gray" type="button"> Cancel </button></a>
          </div>
        </Form>
      </div>
      </React.Fragment>
    );
  }

  formURL() {
    const { renter } = this.props;
    if( renter.id) {
      return `/warehouse/renters/${renter.id}/`;
    }
    return '/warehouse/renters/';
  }

  formMethod() {
    const { renter } = this.props;
    if( renter.id ) {
      return 'PATCH';
    }
    return 'POST';
  }

  render() {
    const { renter } = this.props;
    console.log(this.state);
    return (
      <WarehouseLayout currentPage="renters">
        <Formik
          initialValues={{
            renter: {
              name: renter.name || "",
            }
          }}
          onSubmit={(values, actions) => {
            
            const cookies = new Cookies();
            const token = cookies.get("X-CSRF-Token");

            fetch(this.formURL(), {
              method: this.formMethod(),
              redirect: "manual",
              body: JSON.stringify(values),
              headers: {
                "X-CSRF-Token": token,
                "Content-Type": 'application/json'
              }
            })
              .then(response => {
                return response.json();
              })
              .then(json => {
                if (json.error == null) {
                  window.location.replace(json.url);
                } else {
                  alert(json.error);
                }
              });
          }}
          render={(formikProps) => (
            <React.Fragment>
              {this.renderForm(formikProps)}
              {this.renderAssociatedUsers()}
            </React.Fragment>
        )}/>
      </WarehouseLayout>
    );
  }
}

WarehouseRentersForm.propTypes = {
  renter: PropTypes.object.isRequired,
};

export default WarehouseRentersForm;
