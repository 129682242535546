import React, { useEffect, useState } from "react";
import WarehouseCategoriesModal from "./WarehouseCategoriesModal"
import Cookies from "universal-cookie";
import WarehouseGroupEditModal from "./WarehouseGroupEditModal"
import { fetchAPI } from "@/utility/NetworkUtils";
import Loading from "@/shared/Loading";
const WarehouseCategoriesTable = (props) => {
  const { renter } = props;
  const [ renterId, setRenterId] = React.useState(renter ? renter.id : null);
  const [ categories, setCategories ] = React.useState(null);

  const fetchCategories = async () => {
    fetchAPI(`/webapi/categories${renterId ? `?renter_id=${renterId}` : ""}`, (json)=> {
      if (!json.errors) {
        setCategories(json.categories)
      } else {
        console.log({
          errors: json.errors
        })
      }
    }, {});
  }
  
  useEffect(() => {
    fetchCategories();
  }, []);

  const renderRowHeader = (category, index) => {
    if(index == 0 || categories[index - 1].group_name != category.group_name) {
      return (
        <tr className="group-heading">
          <td colSpan="3">
            {category.group_name || "Misc"}
            {category.group_name ? (<WarehouseGroupEditModal renterId={renterId} updateGroup={updateGroup} group={category.group_name}/>) :""}
          </td>
        </tr>
      )
    }
  }

  const updateGroup = (categories) => { 
    fetchCategories();
  }

  const addCategory = (category) => {
    fetchCategories();
  }

  const updateCategory = (category) =>{
    const index = categories.findIndex(category2 => category2.id === category.id);
    categories[index] = category;
    fetchCategories();
  }


  const renderCategoryTable = () => {

    let sortedCategories = categories.sort((a,b) => {
      if(!a.group_name && !b.groupname) { return 0 }
      if(a.group_name > b.group_name) { return 1; }
      if(a.group_name < b.group_name || !a.group_name || !b.group_name) { return -1; }
      return 0;
    });

    const rows = sortedCategories.reverse().map((category,index) => 
      <React.Fragment>
        {renderRowHeader(category,index)}
        <tr key={`${category.id}`}>
          <td> {category.name}</td>
          <td> </td>
          <td>
            <button className="btn btn-danger pull-right" onClick={() => deleteCategory(category)}> Delete </button>
            <WarehouseCategoriesModal renter={renter} updateCategory={updateCategory} category={category} groups={getAllGroups()}/>
          </td>
        </tr>
      </React.Fragment>
    );
    return (
      <table className="warehouse-basic-table">
        <tbody>
            {rows}
        </tbody>
      </table>
    )
  }

  const deleteCategory = (category) => {
    if(confirm("Are you sure? All items in this category will be moved to Misc.")){
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");

      fetch(`/webapi/categories/${category.id}`, {
        method: 'DELETE',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          fetchCategories();
        } else {
          alert(json.error);
        }
      });      
    }
  }

  const getAllGroups = () => {
    return categories.map(category => category.group_name)
                     .filter((value, index, self) => self.indexOf(value) === index );
  }

  return (
    <div>
      {categories ? (
        <>
          <WarehouseCategoriesModal renter={renter} addCategory={addCategory} groups={getAllGroups()}/>
          <br/>
          <br/>
          {renderCategoryTable()}
        </>
      ) : (
        <Loading />
      )}

    </div>
  );
}

export default WarehouseCategoriesTable;
