import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import moment from 'moment'
import Cookies from "universal-cookie";
class WarehouseRecievingListDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inventory_logs: props.inventory_logs,
      hide_recieved: false,

    }
    this.toggleHideRecieved = this.toggleHideRecieved.bind(this);
    this.deleteRecievable = this.deleteRecievable.bind(this);
  }

  getSortedLogs() {
    let { inventory_logs, hide_recieved } = this.state;
    inventory_logs = inventory_logs.sort((a,b)=>new Date(a.created_at) < new Date(b.created_at) ? 1 : -1)
    if(hide_recieved) {
      inventory_logs = inventory_logs.filter(x=>!x.recieved_at)
    }
    return inventory_logs;
  }

  updateToggle(event, log) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/warehouse/recieving_list/${log.id}/set_recieved`, {
      method: 'POST',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({
        recieved: !log.recieved_at
      })
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        this.setState({
          inventory_logs: this.state.inventory_logs.filter(log2 => log.id != log2.id).concat([json.log])
        });
      } else {
        alert(json.error);
      }
    });
  }

  deleteRecievable(log) {
    if(!confirm('Are you sure you want to remove this from the recieving list? This cannot be undone.')){
      return
    }
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/warehouse/recieving_list/${log.id}`, {
      method: 'DELETE',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        this.setState({
          inventory_logs: this.state.inventory_logs.filter(log2 => log.id != log2.id)
        });
      } else {
        alert(json.error);
      }
    });
  }

  renderRecievingTable() {
    const rows = this.getSortedLogs().map(x=> (
      <tr className="">
        <td>
          <input
            type="checkbox"
            checked={!!x.recieved_at}
            onChange={(e)=>this.updateToggle(e,x)}
          />
        </td>
        <td>
          {x.item ? (
            <a href={`/warehouse/items/${x.item.id}`}>
              {x.item.name}
            </a>
          ):""}

        </td>
        <td>
        {x.count}
        </td>
        <td>
          {x.created_at ? moment(x.created_at).format("MMMM Do YYYY, h:mm:ss a") :""}
        </td>
        <td>
          {x.tracking_number ? x.tracking_number :""}
        </td>
        <td>
          {x.recieved_at ? moment(x.recieved_at).format("MMMM Do YYYY, h:mm:ss a") :""}
        </td>
        <td>
          <button type="button" role="button" onClick={()=>this.deleteRecievable(x)} className="btn btn-danger pull-right">Delete</button>
        </td>
      </tr>
    ))
    return (
      <table className="warehouse-basic-table warehouse-events-show-table">
        <thead>
          <tr>
            <th style={{width:"40px"}}>Recieved</th>
            <th style={{width:"100px"}} class="name-header">Item</th>
            <th style={{width:"10%"}}>Count</th>
            <th style={{width:"10%"}}>Generated At</th>
            <th style={{width:"15%"}}>Tracking Number</th>
            <th style={{width:"15%"}}>Recieved At</th>
            <th style={{width:"40px"}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }

  toggleHideRecieved() {
    const { hide_recieved } = this.state;
    this.setState({
      hide_recieved: !hide_recieved
    })
  }

  render() {
    const { hide_recieved } = this.state;
    return (
      <WarehouseLayout currentPage="recieving_list">
        <div className="warehouse-card">
          <div className="row">
            <div className="col-xs-12">
              <h1> Recieving List </h1>
            </div>
              <div className="col-xs-12">
              <input type="checkbox" value={hide_recieved} onClick={this.toggleHideRecieved}/><label> Hide Recieved </label>
              </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {this.renderRecievingTable()}
            </div>
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

WarehouseRecievingListDashboard.propTypes = {
};

export default WarehouseRecievingListDashboard;
