import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

class WarehouseSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTabs: [],
      notificationCount: null,
    }

    this.openOnMouseOver = this.openOnMouseOver.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
  }

  componentDidMount() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/warehouse/notifications/get_unread_count`, {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            notificationCount: json.unread_message_count
          });
        } else {
          //Die Silent
        }
      });
  }

  openOnMouseOver() {
    const { sidebarOpen, toggleSidebar } = this.props;
    if(!sidebarOpen) {
      $("#items").collapse();
      this.setState({openTabs: []});
      toggleSidebar(true);
    }
  }

  toggleTab(name) {
    const { openTabs } = this.state;
    const index = openTabs.indexOf(name);
    this.setState({
      openTabs: index != -1 ? openTabs.filter(tab => tab != name) : openTabs.concat([name])
    })
  }

  isTabOpen(name) {
    const { openTabs } = this.state;
    return openTabs.includes(name);
  }


  getTabClass(name) {
    return this.isTabOpen(name) ? "collapse in" : "collapse"
  }

  renderNotificationCount() {
    const { unreadNotifications } = this.props;
    const { notificationCount } = this.state;
    const tempNotificationCount = unreadNotifications ? unreadNotifications.length : notificationCount;
    if(tempNotificationCount){
      return (
        <div className="warehouse-sidebar-notification-number">{tempNotificationCount}</div>
      )
    }
  }

  render() {

    return (
      <div className="warehouse-sidebar" onMouseEnter={this.openOnMouseOver}>
        <div className="warehouse-sidebar-content">
          <ul className="nav">
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse">
                <i className="fas fa-home"></i>
                <span className="menu-title">Dashboard</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={()=>this.toggleTab('items')} href="#items" >
                <i className="fas fa-box-open"></i>
                <span className="menu-title">Inventory</span>
              </a>
              <div className={this.getTabClass("items")}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <a className="nav-link" href="/warehouse/items/">View Items</a></li>
                </ul>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <a className="nav-link" href="/warehouse/items/new">Add an Item</a></li>
                </ul>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <a className="nav-link" href="/warehouse/kits">Kits</a></li>
                </ul>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <a className="nav-link" href="/warehouse/inventory_logs">Logs</a></li>
                </ul>
              </div>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse/categories">
                <i className="fas fa-folder"></i>
                <span className="menu-title">Categories</span>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse/renters">
                <i className="fas fa-file-invoice-dollar"></i>
                <span className="menu-title">Renters</span>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse/orders">
              <i class="fas fa-shopping-cart"></i>
                <span className="menu-title">Orders</span>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse/reports">
                <i className="fas fa-chart-bar"></i>
                <span className="menu-title">Reports</span>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse/notifications">
                {this.renderNotificationCount()}
                <i className="fas fa-envelope">
                </i>
                <span className="menu-title">Notifications</span>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse/shipping">
                <i className="fas fa-truck-moving"></i>
                <span className="menu-title">Shipping</span>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse/recieving_list">
                <i className="fas fa-truck-loading"></i>
                <span className="menu-title">Recieving List</span>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/warehouse/events">
                <i className="fas fa-clipboard-list"></i>
                <span className="menu-title">Events</span>
              </a>
            </li>
            <hr />
            <li className="nav-item">
              <a className="nav-link" href="/">
                <i className="fas fa-angle-left"></i>
                <span className="menu-title">Public Catalog</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/users">
                <i className="fas fa-users"></i>
                <span className="menu-title">Users</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" rel="nofollow" href="/users/sign_out">
                <i className="fas fa-sign-out-alt"></i>
                <span className="menu-title">
                  Sign Out
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

WarehouseSidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
};

export default WarehouseSidebar;
