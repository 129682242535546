import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import { formatEventDate } from "../../utility/DateUtils"
import Select from "react-select"
import moment from 'moment'
import Cookies from "universal-cookie";
class WarehouseEventsDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: props.events,
      sortBy: {value: "start_date", label: "Start Date"},
      filterBy: {value: "all", label: "All"},
    }
    this.updateSortBy = this.updateSortBy.bind(this);
    this.updateFilterBy = this.updateFilterBy.bind(this);
  }

  renderEventsTable(archived) {
    const { events } = this.state;
    const sortedEvents = this.getSortedEvents().filter(event => !!event.archived_at === archived);
    const rows = sortedEvents.map(event =>(
      <tr>
        <td>
          <a className="gold-link bold-link" href={`/warehouse/events/${event.slug}`}> {event.name} </a>
        </td>
        <td> {formatEventDate(event)} </td>
        <td className="text-center">
          {event.total_item_count}
        </td>
        <td>
          {event.last_updated}
        </td>
      </tr>
    ))
    return (
      <table className="warehouse-basic-table warehouse-events-show-table">
        <thead>
          <tr>
            <th style={{width:"200px"}} class="name-header">Event Name</th>
            <th style={{width:"20%"}}>Date </th>
            <th className="text-center" style={{width: "100px", paddingLeft:"0px", paddingRight:"0px"}}> Item Total </th>
            <th style={{width:"25%"}}>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    )
  }


  getSortedEvents() {
    //This code breaks if the date is farther than 1000 years from the current date.
    //Moment has not added infinity date support and 1000 is the largest number i know.
    const { events, sortBy, filterBy } = this.state;
    let filteredEvents = events;
    console.log(filterBy.value);
    switch(filterBy.value) {
        case "current_events":
          filteredEvents = filteredEvents.filter(x=> x.start_date && moment(x.start_date) <= moment() && moment(x.end_date) >= moment())
          break;
        case "upcoming_events":
          filteredEvents = filteredEvents.filter(x=> x.start_date && moment(x.start_date) > moment())
          break;
    }
    console.log(filteredEvents);

    const sortByValue = sortBy.value;
    const sortedEvents = filteredEvents.sort((a,b) => {
      if(sortByValue == "name"){
        const sortA = a["name"];
        const sortB = b["name"];
        if(sortA < sortB) { return -1; }
        if(sortA > sortB) { return 1; }
        return 0;
      }
      else {
        const sortA = a[sortByValue] ? moment(a[sortByValue]) : moment().add(moment.duration({Y: 1000}));
        const sortB =  b[sortByValue] ? moment(b[sortByValue]) : moment().add(moment.duration({Y: 1000}));
        return sortByValue == "created_at" ?  (sortB - sortA) : (sortA - sortB);
      }
    });
    return sortedEvents;
  }

  getSortByOptions(){
    return [
      {label: "Start Date", value: "start_date"},
      {label: "Name", value: "name"},
      {label: "Recently Added", value: "created_at"}
    ]
  }

  updateSortBy(selectedValue){
    this.setState({
      sortBy: selectedValue
    })
  }

  updateFilterBy(selectedValue){
    this.setState({
      filterBy: selectedValue
    })
  }

  renderSortBy() {
    const { sortBy } = this.state;
    return (
      <div className="row">
        <div className="col-xs-0 col-sm-6 col-md-8" />
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Select
            options={this.getSortByOptions()}
            value={sortBy}
            onChange={this.updateSortBy}
            styles={{menu: (provided, state)=>{provided['color']='black'; return provided}}}
          />
        </div>
      </div>
    )
  }

  getFilterByOptions() {
    return [
      {label: "All", value: "all"},
      {label: "Current Events", value: "current_events"},
      {label: "Upcoming Events", value: "upcoming_events"}
    ]
  }

  render() {
    const { sortBy, filterBy } = this.state;
    return (
      <WarehouseLayout currentPage="dashboard">
        <div className="warehouse-card warehouse-event-show-card warehouse-events-table-card warehouse-event-index">
          <div className="row">
            <div className="col-xs-12">
              <h1> Events </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 ">
              <label> Sort by </label>
              <Select
                classNamePrefix={"custom-warehouse-event-select"}
                className="custom-warehouse-event-select"
                value={sortBy}
                onChange={this.updateSortBy}
                options={this.getSortByOptions()}
                styles={{menu: (provided, state)=>{provided['color']='black'; return provided}}}
              />
            <label> Filter by</label>
              <Select
                classNamePrefix={"custom-warehouse-event-select"}
                className="custom-warehouse-event-select"
                value={filterBy}
                onChange={this.updateFilterBy}
                options={this.getFilterByOptions()}
                styles={{menu: (provided, state)=>{provided['color']='black'; return provided}}}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {this.renderEventsTable(false)}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 warehouse-event-show-card">
              <h1> Archived Events </h1>
              {this.renderEventsTable(true)}
            </div>
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

WarehouseEventsDashboard.propTypes = {
  events: PropTypes.array.isRequired,
};

export default WarehouseEventsDashboard;
