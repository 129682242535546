import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

class EventCloneModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedAddresses: props.event ? props.event.shipping_addresses.map(address =>{return {value: address.id, label: address.venue_name}}) : [],
      selectedRenters: props.event ? props.event.renters.map(address =>{return {value: address.id, label: address.name}}) : [],
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    const { event } = this.props;
    this.setState({
      modalOpen: true,
      selectedAddresses: event ? event.shipping_addresses.map(address =>{return {value: address.id, label: address.venue_name}}) : []
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }


  renderAccessButton() {
    const { button } = this.props;
    if( button ) {
      return (
        <div onClick={this.openModal}>
          {button}
        </div>
      );
    }
  }

  renderSaveButton(formikProps) {
    return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Clone </button>
  }

  formMethod() {
    return 'POST'
  }

  formURL() {
    const { event } = this.props;
    if( event ){
      return  `/events/${event.slug}/clone`
    }
  }

  renderForm(){
    const { event } = this.props;
    const { selectedAddresses,selectedRenters } = this.state;
    return (
      <Formik
        initialValues={{
          event: {
            name: event ? event.name+" (Cloned)" : null,
            start_date: event ? event.start_date : null,
            end_date: event ? event.end_date : null,
            buffer_start_date: event ? event.buffer_start_date : null,
            buffer_end_date: event ? event.buffer_end_date : null,
          }
        }}

        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          values['addresses'] = selectedAddresses.filter(x=>x.value).map(x=>x.value);
          values['renters'] = selectedRenters.filter(x=>x.value).map(x=>x.value);
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                document.location.href = `/events/`
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-attachment-form">
            <div className="row">
              <div className="col-xs-12">
                Cloning Event
              </div>
              <div className="col-xs-12">
                <label> Name: </label>
                <Field className="form-control" name="event[name]" />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <label> Start Date: </label>
                <Field className="form-control" name="event[start_date]" type="date"/>
              </div>
              <div className="col-xs-6">
                <label> End Date: </label>
                <Field className="form-control" name="event[end_date]" type="date"/>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <label> Buffer Start Date: </label>
                <Field className="form-control" name="event[buffer_start_date]" type="date"/>
              </div>
              <div className="col-xs-6">
                <label> Buffer End Date: </label>
                <Field className="form-control" name="event[buffer_end_date]" type="date"/>
              </div>
            </div>
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-attachment-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Attachment"
        >
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default EventCloneModal
