import React, { Component } from 'react'

export default class CartItemShowCard extends Component {

  constructor(props) {
    super(props)
    this.handleImageClick = this.handleImageClick.bind(this);
  }

  handleImageClick() {
    const {item} = this.props
    window.location.href = `/items/${item.id}`
  }

  render() {
    const {item, orderItem, removeItemFromCart} = this.props
    return (
      <div className="cart-show-item-card">
        <img onClick={this.handleImageClick} className="cart-show-item-card-image" src={item.active_image ? item.active_image.image : "https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png"}/>
        <div className="cart-show-item-card-info">
          {item.name}
          <br />
          Qty: {orderItem.quantity}
        </div>
        <div className="cart-item-delete">
          <i style={{paddingLeft: "10px"}} className="fas fa-trash-alt warehouse-shipping-icon" onClick={() => removeItemFromCart(orderItem.id)}/>
        </div>
      </div>
    )
  }
}