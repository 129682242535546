import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import { isHoldRecieved } from "../../../utility/HoldUtils"
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class WarehouseEventsHoldReturnedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: this.props.modalOpen || false,
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    if (this.props.closeModal) {
      this.props.closeModal();
    } else {
      this.setState({
        modalOpen:false
      });
    }
  }

  getRecievedCount(){
    const {hold } = this.props;
    if(hold.item_inventory_log) {
      return hold.total_count + hold.item_inventory_log.count;
    }
    return hold.total_count;
  }

  renderAccessButton() {
    const { hold } = this.props;
    if(isHoldRecieved(hold)){
      return (<input onClick={this.openModal} value={this.getRecievedCount()} style={{color:"black", maxWidth:"50px", display:"inline-block"}}  className="form-control text-center"/>)
    }
    return (<input className="form-control" style={{maxWidth:"50px", display:"inline-block"}} disabled={true} value=""/>)
  }

  renderSaveButton(formikProps){
    return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Submit </button>
  }

  renderTitle() {
     return <h3 style={{fontWeight:"bold"}}>Quantity Change <a href={`/warehouse/items/${this.props.hold.item.id}/edit`} className="gold-link bold-link">{this.props.hold.item.name}</a></h3>
  }

  renderForm(){
    const { hold, triggerConfirmationMessage, updateItem } = this.props;
    const { item_inventory_log } = hold;
    return (
      <Formik
        initialValues={{
          returned_count: item_inventory_log ? item_inventory_log.count + hold.total_count : hold.total_count
        }}
        onSubmit={(values, actions) => {
          var data = new FormData();
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          data.append("returned_count", values['returned_count']);
          fetch(`/warehouse/item_holds/${hold.id}/set_returned_count`, {
            method: 'POST',
            redirect: "manual",
            body: data,
            headers: {
              "X-CSRF-Token": token
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                console.log(json.quantity_removed)
                if (json.quantity_removed != 0) {
                  triggerConfirmationMessage(`Removed ${json.quantity_removed} of item ${hold.item.name} from inventory`)
                }
                updateItem(json.item)
                this.closeModal();
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="item-image-form">
            {this.renderTitle()}
            Enter New Quantity if item was either destroyed, damaged, or consumed.
            <br />
            <div className="row">
              <div className="col-xs-12">
                <label> Quantity Recieved: </label>
                <Field name="returned_count" className="form-control" type="number" min="0" step="1" max={hold.total_count}/>
              </div>
            </div>
            <br />
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
          </Form>
      )}/>
    );
  }

  render() {
    const {showAccessButton} = this.props;

    return(
      <React.Fragment>
        {showAccessButton && this.renderAccessButton()}
        <Modal
          className="react-modal inventory-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Manage Inventory"
        >
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default WarehouseEventsHoldReturnedModal
