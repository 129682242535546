import React from "react";
import PropTypes from "prop-types";
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"

class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_deleted: false
    };

    this.toggleShowDeleted = this.toggleShowDeleted.bind(this);
  }

  renderOptions(user) {
    return (
      <div className="users-users-table-options">
        {user.active ? 
          (<a href={`/users/${user.id}/become_user`}>Become User</a>) :""
        }
      </div>
    );
  }

  renderUsers(){
    const { users } = this.props;
    const { show_deleted } = this.state;
    const current_user = this.props.user
    return users.filter(user=> user.active || show_deleted).map(user => (
      <tr className={`users-user-table-user ${current_user.id == user.id ? 'current_user' : ''} ${user.active ? "user-active" : "user-inactive"}`} key={`user-user-table-user-${user.id}`}>
        <td> <a href={`/users/${user.id}/edit`}>{user.first_name} {user.last_name} {current_user.id == user.id ? "(you)" : ""}</a></td>
        <td> {user.email} </td>
        <td> {user.role} </td>
        <td> {this.renderOptions(user)} </td>
      </tr>
    ));
  }

  renderHeaderRow() {
    return (
      <tr className="users-users-table-header-row">
        <th> Name </th>
        <th> Email </th>
        <th> Role </th>
        <th> Options </th>
      </tr>
    );
  }

  toggleShowDeleted(e) {
    this.setState({
      show_deleted: e.target.checked
    });
  }

  render() {
    const { show_deleted } = this.state;
    return (
      <PublicCatalogLayout currentPage="home" {...this.props} classNames={{container: 'full-body-container'}}>
        <div className="users-users-table">
          <h1>Users:</h1>
          <a href="/users/new">
            <button className="event-dashboard-add-event">
              Add New User
            </button>
          </a>
          <div className="pull-right">
            <label>Show Deleted?</label>
            <input type="checkbox" value={show_deleted} onChange={this.toggleShowDeleted}/>
          </div>
          <div className="table-responsive">
          <table className=" table">
            <thead>
              {this.renderHeaderRow()}
            </thead>
            <tbody>
              {this.renderUsers()}
            </tbody>
          </table>
          </div>
        </div>
      </PublicCatalogLayout>
    );
  }
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default UsersTable;
