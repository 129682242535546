import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "./WarehouseLayout"
import moment from 'moment'

class WarehouseDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  renderEvents() {
    const { events } = this.props;
    const rows = events.sort((a,b)=>
      moment(a.buffer_start_date) - moment(b.buffer_start_date)
    ).map(event => (
      <div className="ware-house-dashboard-event-row row">
        <div className="col-xs-12">
          <h3 style={{margin:0}}>
            <a href={`/warehouse/events/${event.slug}`}>
              {event.name}
            </a>
          </h3>
          <div>
            {event.start_date} {event.end_date ? ` - ${event.end_date}` : ""}
          </div>
          <div>
            Shipping Start Date: {event.buffer_start_date}
          </div>
          <br />
        </div>
      </div>
    ));

    return rows;
  }

  render() {
    const { children } = this.props;
    return (
      <WarehouseLayout currentPage="dashboard">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <div className="warehouse-card">
              <iframe src="https://calendar.google.com/calendar/embed?src=jaguardesignstudio.com_e8vjqibdb0k1qj3s27gcsk8ems%40group.calendar.google.com&ctz=America%2FLos_Angeles" style={{border: 0}} height="600" width="100%" frameBorder="0" scrolling="no"></iframe>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="warehouse-card">
              <h2>Upcoming Events:</h2>
              {this.renderEvents()}

            </div>
          </div>
        </div>
      </WarehouseLayout>
    );
  }
}

WarehouseDashboard.propTypes = {

};

export default WarehouseDashboard;
