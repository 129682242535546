import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class ItemImageGalleryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentImage: props.currentImage,
      images: props.images,
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateIndex = this.updateIndex.bind(this);
    this.keypressUpdateIndex = this.keypressUpdateIndex.bind(this);
  }

  componentDidMount(){
    document.addEventListener('keydown', this.keypressUpdateIndex);
  }
  componentWillUnmount(){
    document.removeEventListener('keydown ', this.keypressUpdateIndex);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
      currentImage: this.props.currentImage,
      images: this.props.images,
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }


  renderAccessButton() {
    if(this.props.button) {
       return (
        <div type="button" role="button" onClick={this.openModal} className="">
          {this.props.button}
        </div>
      )
    }
    return (<button type="button" role="button" onClick={this.openModal} className="col-xs-12 btn btn-green">Open Gallery</button>)
  }

  getImageIndex(){
    const { currentImage, images } = this.state;
    return images.findIndex(tempImage => tempImage.id == currentImage.id);
  }

  renderImageChangeControls(){
    const { images } = this.state;
    const index = this.getImageIndex();
    return (
      <React.Fragment>
        { index > 0 ? (
        <div onClick={(e) => this.updateIndex(index-1)} className="gallery-image-update-column gallery-image-update-column-left">
          <i class="fas fa-angle-left"></i>
        </div>
        ):""}
        { index < images.length - 1 ? (
          <div onClick={(e) => this.updateIndex(index+1)} className="gallery-image-update-column gallery-image-update-column-right">
            <i class="fas fa-angle-right"></i>
          </div>
          ) : ""
        }
      </React.Fragment>
    )
  }

  updateIndex(index){
    const { images } = this.state;
    this.setState({
      currentImage: images[index]
    })
  }

  keypressUpdateIndex(e){
    const { images } = this.state;
    const index = this.getImageIndex();
    if(e.keyCode==39 && index < images.length - 1 ){//Right
      this.updateIndex(index+1)
    }else if (e.keyCode==37 && index > 0) { //Left
      this.updateIndex(index+-1)
    }
  }

  render() {
    const { currentImage, images } =this.state;
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="gallery-modal"
          overlayClassName="gallery-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Gallery"
        >
          <div className="gallery-modal-top-bar" >
            <div className="row">
              <div className="top-bar-col col-xs-6 gallery-image-count">
                {this.getImageIndex()+1}/{images.length}
              </div>
              <div className="top-bar-col col-xs-6 text-right gallery-top-bar-actions">
                <a href={`/items/1/item_images/${currentImage.id}/download`} target="_blank" download><i class="fas fa-download gallery-top-bar-action" /></a>
                <i onClick={this.closeModal} class="fas fa-times gallery-top-bar-action"></i>
              </div>
            </div>
          </div>
          {this.renderImageChangeControls()}
          <div className="gallery-display-box" onClick={this.closeModal}>
            <img className="gallery-display-image" src={currentImage.image} onClick={(e)=> e.stopPropagation()} />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default ItemImageGalleryModal
