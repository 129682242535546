import React from "react";
import DataGridTableToolbar from "@/utility/DataGridTableToolbar";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";

import { 
  Stack,
  Link,
  Button
} from "@mui/material";
import WarehouseDeleteItemModal from "./WarehouseDeleteItemModal";
const WarehouseSearchableItemTable = ({
  items
}) => {
  const getItems = () => {
    //TODO Map Custom Fields

    return items.map(x=> {
      x.category_name = (x.category||{}).name || "Other";
      x.group_name = (x.category||{}).group_name || "Misc";
      x.renter_name = x.renter ? x.renter.name : "Moonlite";
      x.image_url = x.active_image ? x.active_image.thumbnail_url : "";
      return x;
    })
  }

  const getColumns = () => {
    return [
      {field: "image_url",sorting: false, filtering: false, headerName: "Image", renderCell: (params) => (params.value ? <img src={params.value} style={{width: "60px", height: "60px"}}/> : "")},
      {field: "name", flex: 1, headerName: "Name"},
      {field: "group_name", flex: 1, headerName: "Category"},
      {field: "category_name", flex: 1, headerName: "Subcategory"},
      {field: "renter_name", flex: 1, headerName: "Renter"},
      {field: "owned", flex: 1, headerName: "Owned"},
      {field: "actions", sorting: false, filtering: false, width: 250, headerName: "Actions", renderCell: renderActions},
    ];
  }

  renderActions = (params) => {
    return (
      <Stack direction="row" spacing={1}>
        <Button href={`/warehouse/items/${params.row.id}/edit`} size="small" variant="contained" color="primary" >Edit</Button>
        <Button href={`/warehouse/items/${params.row.id}/duplicate`} size="small" variant="contained" color="secondary">Clone</Button>
        <WarehouseDeleteItemModal item={params.row} />
      </Stack>
    )
  }

  return (
    <DataGridPremium
      autoHeight
      pagination
      unstable_headerFilters
      rows={getItems()}
      columns={getColumns()}
      slots={{
        toolbar: DataGridTableToolbar
      }}
      style={{
        cell: {
          paddingBottom: 0,
          paddingRight: 0,
          whiteSpace: 'normal',
          overflow: 'visible',
        },
        background: "white"
      }}
      pageSizeOptions={[5, 10, 20, 50, 100]}
    />
  )
};

export default WarehouseSearchableItemTable