import React from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import ItemForm from "../../items/form/ItemForm"
class WarehouseEditItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { children } = this.props;
    return (
      <WarehouseLayout currentPage="dashboard">
        <h1> Add an Item </h1>
        <ItemForm
          {...this.props} 
          onSuccessfulSaveUrl={
            `/warehouse/items/`
          }
        />
      </WarehouseLayout>
    );
  }
}

WarehouseEditItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default WarehouseEditItem;
