import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import WarehouseLayout from "../WarehouseLayout"
import WarehouseCategoriesTable from "./WarehouseCategoriesTable"
import { Autocomplete, TextField } from "@mui/material";
const WarehouseCategoriesDashboard = (props) => {
  const { renters } = props;
  const [ selectedRenterId, setSelectedRenterId ] = useState({
    value: null,
    label: "Moonlite",
    id: null
  });

  const getOptions = () => {
    return renters.map(x=> {
      return {
        label: x.name||"",
        value: x.id,
        id: x.id
      }
    }).concat([
      { label: "Moonlite", value: null }
    ]).sort((a, b) => a.label.localeCompare(b.label))
  }

  return (
    <WarehouseLayout currentPage="dashboard">
      <div className="warehouse-card">
        <div className="row">
          <div className="col-xs-12">
            <h1> Categories </h1>
            <Autocomplete
              sx={{
                background: "white",
              }}
              disableClearable
              options={getOptions()}
              value={selectedRenterId}
              onChange={(event, newValue) => {
                setSelectedRenterId(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Inventory" />}
            />
            <br/>
            <WarehouseCategoriesTable key={`warehouse-categories-table-${selectedRenterId.value}`} {...props} renter={selectedRenterId}/>
          </div>
        </div>
      </div>
    </WarehouseLayout>
  );
}

export default WarehouseCategoriesDashboard;
