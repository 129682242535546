import moment from 'moment'
//Todo Fix this
export function formatEventDate(event) {
  if(event.start_date) {
    const start_date = moment(event.start_date);
    if(event.end_date) {
      const end_date = moment(event.end_date);
      if(end_date.year() == start_date.year()) {
        return `${start_date.format("MMMM DD")} - ${end_date.format("MMMM DD, YYYY")}`
      }
      return `${start_date.format("MMMM DD, YYYY")} - ${end_date.format("MMMM DD, YYYY")}`
    }
    return moment(event.start_date).format("MMMM DD, YYYY")
  }
  return ""
}