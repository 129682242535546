import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class HoldMessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      notificationSent: false,
      selected_admin_users: [],
      admin_users: props.admin_users || null
    }
    this.formURL = this.formURL.bind(this);
    this.formMethod = this.formMethod.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateSelectedAdminUser = this.updateSelectedAdminUser.bind(this);
  }

  componentDidMount(){
  }

  openModal(e) {
    e.stopPropagation();
    const { category } = this.props;
    this.setState({
      modalOpen: true,
      notificationSent: false,
      selected_admin_users: [],
    });
    const { admin_users } = this.state;
    if(!admin_users){
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch('/notifications/get_admins', {
        method: 'GET',
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({
            admin_users: json.admin_users
          })
        } else {
          
        }
      });
    }
  }

  closeModal() {
    this.setState({
      modalOpen:false,
      notificationSent: false
    });
  }

  renderAccessButton() {
    const { button } = this.props;
    if( button ) {
      return (
        <a href="#" onClick={this.openModal}>
          {button}
        </a>
      );
    }
    return (
      <button
        type="button"
        role="button"
        onClick={this.openModal}
        className="btn btn-green"
      >
        Add Address
      </button>
    );
  }

  formMethod() {
    return 'POST'
  }

  formURL() {
    const { item_hold,event,item } = this.props;
    if(item_hold){
      return `/events/${event.slug}/item_holds/${item_hold.id}/notify`
    }else if(item){
      return `/items/${item.id}/notify`
    }else if(event){
      return `/events/${event.slug}/notify`
    }
    return `/notifications`
  }

  renderForm(){
    const { notificationSent } = this.state;
    const {headerTitle} = this.props;
    if(notificationSent) {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-xs-12">
              <h1> Thank you. Your message has been sent.</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <button type="button" className="item-hold-form-submit-button" onClick={this.closeModal}>Close</button>
            </div>
          </div>
        </React.Fragment>
      )
    }
    return (
      <Formik
        initialValues={{
          warehouse_notification: {
            message: "",
            recipient_ids:[]
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          values['warehouse_notification']['recipient_ids'] = this.state.selected_admin_users
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json'
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                this.setState({
                  notificationSent: true
                })
              } else {
                alert(json.error);
              }
            });
        }}
        render={(formikProps) => (
          <Form>
            <div className="row">
              <div className="col-xs-12">
                <h2>{headerTitle|| "Notification"}</h2>
              </div>
            </div>
            {this.renderUsers()}
            <div className="row">
              <div className="col-xs-12">
                <br />
                Your Message
                <br />
                <Field required="true" component="textarea" as="textarea" className="form-control" name="warehouse_notification[message]" />
              </div>
            </div>
            <br />
            <div className="row message-submit-button-area">
              <div className="col-xs-12 col-sm-4 col-md-3">
                <button type="submit" className="item-hold-form-submit-button">Submit</button>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-3">
                <button type="button" onClick={this.closeModal} className="item-hold-form-cancel-button">Cancel</button>
              </div>  
            </div>
          </Form>
      )}/>
    );
  }

  renderUsers(){
    const {admin_users} = this.state;
    const {selected_admin_users} = this.state;
    if(admin_users){
      const rows = admin_users.map(user =>
          <div className="col-xs-12 message-user-select" key={`user-${user.id}`}>
             <input type="checkbox" onChange={(e) => this.updateSelectedAdminUser(e,user.id)}checked={selected_admin_users.includes(user.id)}/>
             <label>{'\xa0'}{user.first_name} {user.last_name} </label>
          </div>
      )
      return(
        <div className="row">
          {rows}
        </div>
      )
    }
  }

  updateSelectedAdminUser(e,id){
    const { selected_admin_users } = this.state;
    if(e.target.checked){
      this.setState({
        selected_admin_users: selected_admin_users.concat([id])
      })
      return;
    }
    this.setState({
      selected_admin_users: selected_admin_users.filter(id2 => id2 != id)
    })
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-attachment-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel=""
        >
          <div className="react-modal-close-button" onClick={this.closeModal}> &#10005; </div>
          {this.renderForm()}
        </Modal>
      </React.Fragment>
    )
  }
}

export default HoldMessageModal