import React from "react";
import PropTypes from "prop-types";
import { isJaguar, isSuperadmin } from "../../utility/UserUtils"
import Cookies from "universal-cookie";

class PublicCatalogNavbarSearchMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      tagsOpen: false,
      commonTags: null,
      selectedTags: [],
      searchInput: "",
    }

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleTags = this.toggleTags.bind(this);
    this.toggleSelectedTag = this.toggleSelectedTag.bind(this);
    this.updateSearchInput = this.updateSearchInput.bind(this);
  }

  renderMenu() {
    const { menuOpen, searchInput } = this.state;
    if (menuOpen) {
      return (
        <div className="public-catalog-navbar-search-menu" onClick={(e)=>{e.stopPropagation()}}>
          <div className="row">
            <div className="col-xs-12">
              <input value={searchInput} onChange={this.updateSearchInput} placeholder="Search Catalog" className="form-control"/>
            </div>
          </div>
          {this.renderTags()}
          <div className="row">
            <div className="col-xs-12">
              <a href={this.generateSearchLink()}>
                <button className="search-submit-button">
                  Search
                </button>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }

  updateSearchInput(e){
    this.setState({
      searchInput: e.target.value
    })
  }

  toggleTags() {
    const { tagsOpen } = this.state;
    this.setState({
      tagsOpen: !tagsOpen
    })
  }

  toggleSelectedTag(tag) {
    const { selectedTags } = this.state;
    this.setState({
      selectedTags: ((selectedTags.indexOf(tag) > -1) ? selectedTags.filter(tag2 => tag2 != tag) : [tag].concat(selectedTags))
    })
  }

  getTagClass(tag) {
    const { selectedTags } = this.state;
    return selectedTags.indexOf(tag) > -1 ? "active" : "";
  }

  renderTags() {
    const { tagsOpen, commonTags} = this.state;
    let content = ""
    if(tagsOpen && commonTags) {
      content = commonTags.map(tag => (
          <span className="selectable-tag" onClick={() => this.toggleSelectedTag(tag)}>
            <div className={`tag-container ${this.getTagClass(tag)}`} >
              {tag}
            </div>
          </span>
        )
      )
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xs-12 archive-events-header" onClick={this.toggleTags}>
            Tags&nbsp;&nbsp;
            {tagsOpen ?
              (<i className="fas fa-sort-down" />)
              :
              (<i className="fas fa-sort-down archive-arrow-rotated" />)
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 search-tags-container" >
            { content }
          </div>
        </div>
      </React.Fragment>
    )
  }

  generateSearchLink(){
    const { searchInput, selectedTags } = this.state;
    const tags = selectedTags.map(tag => `tags[]=${tag}`);

    const params = searchInput && searchInput.length > 0 ? [`query=${searchInput}`].concat(tags).join('&') : tags.join('&'); 
    return `/items/search?${params}`
  }

  loadTags() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/item_tags/common_tags`, {
      method: 'GET',
      redirect: "manual",
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          this.setState({commonTags: json.tags || []});
        } else {
          alert(json.error);
        }
      });
  }

  toggleMenu(override = null) {
    const { menuOpen } = this.state;
    const newvalue =  override == null ? !menuOpen : override
    if(newvalue){
      this.loadTags();
    }
    this.setState({
      menuOpen: newvalue
    })
  }

  render() {
    return (
      <React.Fragment>
       <i class="fas fa-search" onClick={(e)=>{e.stopPropagation();this.toggleMenu()}}></i>
        {this.renderMenu()}
      </React.Fragment>
    );    
  }
}

PublicCatalogNavbarSearchMenu.propTypes = {

};

export default PublicCatalogNavbarSearchMenu;
