import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../../utility/WindowSizeHooks"
import ItemCardQuickViewModal from "../ItemCardQuickViewModal"
import AddItemToCartModal from "./AddItemToCartModal";

class ItemCard extends React.Component {
  constructor(props) {
    super(props);
  }

  renderDimensions() {
    const { item } = this.props;
    const dimensions = [
      {label: "H", value:item.product_height},
      {label: "W", value:item.product_width},
      {label: "L", value: item.product_length}
    ].filter(x=> x.value)
    if( dimensions.length > 0) {
      return (
        <div className="card-field product_dimensions">
          Dims: <span> {dimensions.map(d => `${d.value}"${d.label}`).join(" x ")} </span>
        </div>
      )
    }
  }

  renderItemIcons(){
    const { item } = this.props;
    let icons = [];
    if(!item.public){
      icons = icons.concat([
        <img className="item-card-icon" src="/images/internal_view_icon.png"/>
      ])
    }
    return (
      <div className="item-card-icon-area">
        {icons}
      </div>
    )
  }

  render() {
    const { item } = this.props;
    return (
      <div className="item-card">
        {this.renderItemIcons()}
        <div
          className="item-card-image"
          style={{backgroundImage: `url("${item.active_image ? item.active_image.thumbnail_url : ""}")`}}
        >
          <ItemCardQuickViewModal {...this.props}/>
          <AddItemToCartModal {...this.props}/>
        </div>
        <div className="item-card-description">
          <a href={`/items/${item.id}`}>
            <div className="name">
              {item.name} {item.currently_in_warehouse && <span className="owned"> ({item.currently_in_warehouse}/{item.owned}) </span>}
            </div>
          </a>
        </div>
      </div>
    );
  }
}

ItemCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ItemCard;
