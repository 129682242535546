import React, { Component } from 'react'

export default class KitShowItemCard extends Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {item} = this.props
    window.location.href = `/items/${item.id}`
  }

  render() {
    const {item} = this.props
    return (
      <div className="kit-show-item-card" onClick={this.handleClick}>
        <img className="kit-show-item-card-image" src={item.item_images.length > 0 ? item.item_images[0].image : "https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png"}/>
        <div className="kit-show-item-card-info">
          {item.name + " x " + item.count}
        </div>
      </div>
    )
  }
}
