import React, { Component } from 'react'
import WarehouseLayout from '../WarehouseLayout'
import Cookies from 'universal-cookie'

export default class OrderEditPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      shipped: this.props.order.shipped,
      billed: this.props.order.billed,
      trackingNumber: this.props.order.tracking_number,
    }
    this.saveOrder = this.saveOrder.bind(this);
  }

  saveOrder() {
    const {order} = this.props
    const {shipped, billed, trackingNumber} = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/orders/${order.id}`, {
      method: "PATCH",
      redirect: "manual",
      body: JSON.stringify({
        shipped: shipped,
        billed: billed,
        tracking_number: trackingNumber,
      }),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.errors == null) {
        window.location = `/warehouse/orders`
      } else {
        alert(json.errors);
      }
    });
  }

  renderShippingInfo() {
    const {shipping_address} = this.props.order
    const {returnIfNotEmpty} = this;
    return (
      <div>
        {shipping_address.venue_name}<br />
        {shipping_address.address_line_1}<br />
        {shipping_address.address_line_2.length > 0 && <>{shipping_address.address_line_2}<br /></>}
        {shipping_address.city + ", " + shipping_address.state_province}<br />
        {shipping_address.country + " " + shipping_address.postal_code}
        <br />
        <br />
        {"Address to: " + shipping_address.first_name + " " + shipping_address.last_name}<br />
        {"Email: " + shipping_address.email}<br />
        {"Phone: " + shipping_address.phone}
      </div>
    )
  }

  render() {
    const {order} = this.props
    const {shipped, billed, trackingNumber} = this.state;
    return (
      <WarehouseLayout>
        <h1> Order #{order.id} {order.renter && "- " + order.renter.name}</h1>
        <div className="row">
          <div className="col-xs-12">
            <div className="warehouse-card">
              <div className="row">
                <div className='col-xs-6'>
                  <h4>Shipping</h4>
                  {this.renderShippingInfo()}
                  <br />
                  <br />
                  <label>Tracking Number</label>
                  <input className='form-control kit-input' type="text" value={trackingNumber} onChange={(e) => this.setState({trackingNumber: e.target.value})}/> 
                  <br />
                  <label>Shipped</label>&nbsp;
                  <input type="checkbox" checked={shipped} onChange={(e) => this.setState({shipped: e.target.checked})}/>
                  <br />
                  <label>Billed</label>&nbsp;
                  <input type="checkbox" checked={billed} onChange={(e) => this.setState({billed: e.target.checked})}/>
                </div>
                <div className='col-xs-6'>
                  <h4>Items</h4>
                  <ul>
                    {order.order_items.map((order_item) => (
                      <li><a href={`/warehouse/items/${order_item.item.id}/edit`}>{order_item.item.name}</a> x {order_item.quantity}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-3'>
                  <br />
                  <br />
                  <button onClick={this.saveOrder} className='btn btn-green'>Save</button>
                  <a href="/warehouse/orders"><button style={{marginLeft: "5px"}} className='btn btn-grey'>Back</button></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WarehouseLayout>
    )
  }
}
