import React from "react";
import PropTypes from "prop-types";
import WarehouseSidebar from "./partials/WarehouseSidebar"
import WarehouseNavbar from "./partials/WarehouseNavbar"
import { isBreakpoint } from "../utility/WindowSizeHooks"
import MuiLayout from "@/layouts/MuiLayout"
class WarehouseLayout extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: !isBreakpoint('xs')
    }

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
  }

  generateSidebarOpenClass() {
    const {sidebarOpen} = this.state;
    return sidebarOpen ? "warehouse-sidebar-open" : "warehouse-sidebar-closed"
  }

  toggleSidebar(override = null){
    this.setState((state) => {
      let newState = state;
      newState.sidebarOpen = override == null ? !state.sidebarOpen : override;
      return newState;
    });
  }


  closeMobileMenu() {
    const { sidebarOpen } = this.state;
    if(isBreakpoint('xs') && sidebarOpen) {
      this.toggleSidebar(false);
    }
  }

  render() {
    const { children, notificationCount } = this.props;
    const { sidebarOpen } = this.state;

    return (
      <MuiLayout>
      <div
        className={`warehouse-layout ${this.generateSidebarOpenClass()}`}
      >
        <WarehouseSidebar {...this.props} sidebarOpen={sidebarOpen} toggleSidebar={this.toggleSidebar}/>
        <div className="warehouse-container container-fluid" onClick={()=>this.closeMobileMenu()}>
          <WarehouseNavbar toggleSidebar={this.toggleSidebar}/>
          <div className="warehouse-content">
            {this.props.children}
          </div>
        </div>
      </div>
      </MuiLayout>
    );
  }
}

WarehouseLayout.propTypes = {

};

export default WarehouseLayout;
