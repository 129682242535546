import React, { Component } from 'react'
import WarehouseLayout from '../WarehouseLayout'

export default class OrdersDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orders: this.props.orders
    }
  }

  handleDeleteOrder(id) {
    if (window.confirm(`Delete Order #${id}?`)) {
      const cookies = new Cookies();
      const token = cookies.get("X-CSRF-Token");
      fetch(`/orders/${id}`, {
        method: "DELETE",
        redirect: "manual",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": 'application/json'
        }
      })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.errors == null) {
          let orders = this.state.orders;
          orders.splice(orders.findIndex((order) => order.id == id), 1);
          this.setState({orders: orders})
        } else {
          alert(json.errors);
        }
      });
    }
  }

  renderOrdersTable() {
    let {orders} = this.state;
    return (
      <table className='kits-table'>
        {
          orders.map(order => 
            <tr key={order.id}>
              <td>
                Order #{order.id} - Renter: {order.renter ? order.renter.name : "N/A"}
              </td>
              <td>
              {order.archived && <i>Archived</i>}
              </td>
              <td className='kits-table-button-col'>
                <a href={`/warehouse/orders/${order.id}/edit`}><button className="kit-edit-button btn btn-green">View/Edit</button></a>
              </td>
            </tr>  
          )
        }
      </table>
    )
  }

  render() {
    return (
      <WarehouseLayout>
        <h1>Orders</h1>
        <div className="row">
          <div className="col-xs-12">
            <div className="warehouse-card">
              {this.renderOrdersTable()}
            </div>
          </div>
        </div>
      </WarehouseLayout>
    )
  }
}
