import React from 'react';
import Cookies from "universal-cookie";

class CreateShipment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      trackingNumber: "",
      courierSelected: "",
      courierOptions: []
    }
    this.createShipmentRequest = this.createShipmentRequest.bind(this);
    this.handleTrackingNumberChange = this.handleTrackingNumberChange.bind(this);
    this.handleCourierSelect = this.handleCourierSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    
  }

  detectCourierRequest(tracking_number) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token")
    return fetch(`/warehouse/shipping/detect_courier?tracking_number=${tracking_number}`, {
      method: 'GET',
      redirect: 'manual',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then((response) => response.json())
  }

  createShipmentRequest(tracking_number, slug) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token")
    return fetch('/warehouse/shipping/create_shipment', {
      method: 'POST',
      redirect: 'manual',
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      },
      body: JSON.stringify({
        tracking_number: tracking_number,
        slug: slug,
      })
    })
  }

  handleTrackingNumberChange(e) {
    this.setState({
      trackingNumber: e.target.value.trim()
    })
    this.detectCourierRequest(e.target.value.trim())
    .then((json) =>{
      let {couriers} = json.data;
      let courierData = [];
      for (let courier of couriers) {
        console.log(courier);
        courierData.push({
          slug: courier.slug,
          name: courier.name,
        })
      }
      return courierData;
    })
    .then((data) => {
      this.setState({
        courierSelected: data[0].slug,
        courierOptions: data
      })
    })
    .catch((err) => {
      console.error(err);
    })
  }

  handleCourierSelect(e) {
    this.setState({
      courierSelected: e.target.value,
    })
  }

  handleSubmit(e) {
    const {trackingNumber, courierSelected} = this.state;
    this.createShipmentRequest(trackingNumber, courierSelected)
    .then((res) => {
      return res.json()
    })
    .then((json) => {
      console.log(json);
      if (json.status !== 201) {
        alert(
          `Request status: ${json.status}\nMessage: ${json.message}`
        )
      }
    })
    .then(() => {
      this.props.toggleTableLoading();
      setTimeout(() => {this.props.getTrackingData()}, 3000);
    })
    .then(() => {
      this.props.toggleCreateShipment();
    })
    .catch((err) => {
      alert(err);
    })
  }

  render() {
    const {courierOptions, courierSelected} = this.state;
    const {toggleCreateShipment} = this.props;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <label className="warehouse-shipping-label">Tracking number</label>
          <input className="warehouse-shipping-input" type="text" onChange={this.handleTrackingNumberChange}/>
          <label className="warehouse-shipping-label">Courier</label>
          <select className="warehouse-shipping-input" onChange={this.handleCourierSelect}>
            {courierOptions.map((courier) => <option value={courier.slug}>{courier.name}</option>)}
          </select>
          <button className="warehouse-shipping-button" type="submit">Create</button>
          <button className="warehouse-shipping-cancel-button" onClick={() => toggleCreateShipment()}>Cancel</button>
        </form>
      </div>
    )
  }
}

export default CreateShipment;