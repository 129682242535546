import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Webcam from "react-webcam"
import DropzoneInline from "../../items/form/DropzoneInline"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class KitImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      photo_preview: props.photo ? props.photo.image : null,
      photo_uploaded: null,
      showPictureCapture: false,
      photo_filename: null,
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onDrop = this.onDrop.bind(this)

    this.videoRef = React.createRef();
    this.capturePicture = this.capturePicture.bind(this);
    this.dropzoneRef = React.createRef();
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
      photo_preview: this.props.photo ? this.props.photo.image : null,
      photo_uploaded: null,
      showPictureCapture: false
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { currentImage } = this.props;
    if(!currentImage){
      return (<button type="button" role="button" onClick={this.openModal} className="btn btn-green">Add An Image</button>)
    }
  }

  renderDropzone(){
    const {photo_preview, showPictureCapture} = this.state;
    if(!showPictureCapture){
      return (
        <DropzoneInline onDrop={this.onDrop} ref={this.dropzoneRef}/>
      )
    }
  }

  onDrop(file) {
    if (typeof window.FileReader !== "undefined") {
      var reader = new FileReader();
      reader.onload = e => {
        this.setState({
          photo_uploaded: file,
          photo_filename: file.name,
          photo_preview: e.target.result
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        photo_uploaded: file,
        photo_preview: null
      });
    }
  }


  renderSaveButton(formikProps){
    if(this.state.photo_uploaded){
      return <button className="btn btn-primary pull-right" type="button" onClick={formikProps.submitForm}> Save </button>
    }
    return <button className="btn btn-primary pull-right"type="button"  disabled> Save </button>
  }

  renderTakePicture(){
    const { showPictureCapture } = this.state;
    if(showPictureCapture){
      return (
        <>
          <div className="row text-center">
            <div className="col-xs-12">
              <Webcam
                videoConstraints={{width: 500,  height: 500}}
                width={500}
                height={500}
                audio={false}
                screenshotFormat="image/jpeg"
                ref={this.videoRef}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 text-center">
              <button className="btn btn-primary" onClick={this.capturePicture}>Capture Picture</button>
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <div className="row text-center">
          <div className="col-xs-12">
            Or
          </div>
        </div>
        <div className="row text-center">
          <div className="col-xs-12">
            <button onClick={()=>this.setState({showPictureCapture: true})}className="btn btn-primary" href="#"> Take a Picture </button>
            <br />
          </div>
        </div>
      </>
    )

  }

  async capturePicture(){
    const screenshot = this.videoRef.current.getScreenshot();
    const name = `picture-${(Math.ceil(Math.random() * 1000000000))}.jpeg`

    await fetch(screenshot)
    .then(function(res){return res.arrayBuffer();})
    .then(function(buf){return new File([buf], name, {type:'image/jpeg'});})
    .then((file) => {
        this.setState({
          photo_preview: screenshot,
          photo_uploaded: file,
          showPictureCapture: false,
        });
        this.dropzoneRef.current.onDrop([file]);
    })
  }

  renderForm(){
    const {setImage} = this.props
    const {closeModal} = this
    return (
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          var data = {};
          const croppedBlob = this.dropzoneRef.current.cropImage()
          const filename = this.state.photo_filename && this.state.photo_filename != "blob" ? this.state.photo_filename : `picture-${(Math.ceil(Math.random() * 1000000000))}.jpeg`
          data.image = croppedBlob;
          data.fileName = filename;
          data.imagePreview = this.state.photo_preview
          setImage(data);
          closeModal();
        }}>
        {(formikProps) => (
          <Form onSubmit={e => { e.preventDefault(); e.stopPropagation();  }} id="item-image-form">
            {this.renderDropzone()}
            <br />
            {this.renderTakePicture()}
            <div className="row">
              {this.renderSaveButton(formikProps)}
              <button className="btn btn-secondary pull-right" onClick={this.closeModal} > Cancel </button>
            </div>
          </Form>
        )}
      </Formik>
      
    );
  }

  render() {

    return(
      <>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-form-modal add-image-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Add An Image"
        >
          {this.renderForm()}
        </Modal>
      </>
    )
  }
}

export default KitImageModal
