import React from "react";
import PropTypes from "prop-types";
import { isBreakpoint } from "../utility/WindowSizeHooks"
import PublicCatalogLayout from "../layouts/PublicCatalogLayout"
import { formatEventDate } from "../utility/DateUtils"
import CategorySubheader from "./CategorySubheader"
import ItemCard from "../items/components/ItemCard"
import { isJaguar } from "../utility/UserUtils"
import Cookies from "universal-cookie"
class CategoryDashboard extends React.Component {
  constructor(props) {
    const cookies = new Cookies();
    const showPrivate = cookies.get("show_internal_items");
    super(props);
    this.state = {
      items: props.items,
      showPrivate: showPrivate == "true",
    }
    this.toggleShowPrivate = this.toggleShowPrivate.bind(this);
  }

  renderItems() {
    let { items, showPrivate } = this.state;
    const { user } = this.props;
    if(!isJaguar(user)){
      items = items.filter(a=> a.public);
    }
    items = items.filter(item => item.owned_including_variants > 0);
    return items.filter((a) => showPrivate || a.public).sort((a,b) => (a.name < b.name ? -1 : 1)).filter(item=> !item.variant_of_id).map(item => (
      <ItemCard key={`item-card-${item.id}`} item={item} user={user}/>
    ))
  }

  renderShowPrivateCheckbox(){
    const { showPrivate } = this.state;
    const { user } = this.props;
    if(isJaguar(user)){
      return (
        <a href="#" onClick={this.toggleShowPrivate} className="hide-show-private pull-right">
          {showPrivate ? "Hide Internal Items" : "Show Internal Items"}
        </a>
      )
    }
  }

  toggleShowPrivate(){
    const cookies = new Cookies();
    const showPrivate = cookies.set("show_internal_items", !this.state.showPrivate);
    console.log(!this.state.showPrivate)
    this.setState({
      showPrivate: !this.state.showPrivate
    })
  }

  render() {
    const { group } = this.props;
    return (
      <PublicCatalogLayout
        currentPage={`group-${group}`}
        {...this.props}
        currentGroup={group}
        classNames={{container: 'wider-container'}}
        className="category-dashboard"
        subHeader={<CategorySubheader {...this.props}/>}
      >

        {this.renderItems()}
      </PublicCatalogLayout>
    );
  }
}

CategoryDashboard.propTypes = {
  category: PropTypes.object.optional,
  categories: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
};

export default CategoryDashboard;
