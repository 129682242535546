import React,{ Component }  from 'react'
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import Cookies from "universal-cookie";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Button } from "@mui/material";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};


class ItemImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      choiceMade: false,
    }
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.stopPropagation();
    this.setState({
      modalOpen: true,
      photo_preview: this.props.photo ? this.props.photo.image : null,
      photo_uploaded: null,
    });
  }

  closeModal() {
    this.setState({
      modalOpen:false
    });
  }

  renderAccessButton() {
    const { button, outerStyle } = this.props;
    if( button ) {
      return (
        <a style={outerStyle||{}} href="#" onClick={this.openModal}>
          {button}
        </a>
      );
    }
    return (<Button type="button" role="button" onClick={this.openModal} className="btn btn-danger pull-right">Delete</Button>)
  }

  hardDelete(){
    this.setState({
      choiceMade: true,
    })
  }
  render() {
    const { item } = this.props;
    return(
      <React.Fragment>
        {this.renderAccessButton()}
        <Modal
          className="react-modal add-form-modal"
          overlayClassName="react-modal-overlay"
          isOpen={this.state.modalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Delete Item"
        >
          <div className="row">
            <div className="col-xs-12">
              <h1> Are you sure you want to delete this item? </h1>
              <br />
            </div>
            <div className="col-xs-6">
              <a href={`/warehouse/items/${item.id}/soft_delete`} rel="nofollow" data-method="delete">
                <button className="btn btn-primary">
                  Delete
                </button>
              </a>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default ItemImageModal
