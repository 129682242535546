import React, { Component } from 'react'
import WarehouseDeleteItemModal from "../WarehouseDeleteItemModal"
import Cookies from "universal-cookie";

export default class WarehouseItemTableRow extends Component {

  constructor(props) {
    super(props)

    this.state = {
      numOwned: props.item.owned,
    }
  }
  
  updateItem(body) {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    console.log(body);

    fetch(`/warehouse/items/${this.props.item.id}`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify(body),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
    .then(response => {
      return response.json();
    })
  }

  generateItemVariantClass(item) {
    return item.variant_of_id ? "item-variant" : "";
  }

  renderRenter() {
    const {item} = this.props;
    if ("renter_id" in item) {
      if (item.renter_id == null){
        return <td> Moonlite </td>
      }
      if (item.renter){
        return <td>{item.renter.name}</td>
      }
      if (this.props.renters){
        const renter = this.props.renters.find(renter=>renter.id == item.renter_id)
        return <td> {renter.name} </td>
      }
    }
  }

  render() {
    const { item } = this.props;
    if(item) {
      return (
        <React.Fragment>
          <tr className={`${this.generateItemVariantClass(item)}`}  key={`item-row-${item.id}`}>
            <td> {item.name} </td>
            <td> {item.category ? item.category.name : "N/A"}</td>
            {this.renderRenter()}
            <td className="owned">
              {this.state.numOwned}
            </td>
            <td>
              <WarehouseDeleteItemModal item={item} />
              <a href={`/warehouse/items/${item.id}/duplicate`}>
                <button className="btn btn-gray pull-right button-margin-right hidden-sm hidden-xs">Clone</button>
              </a>
              <a href={`/warehouse/items/${item.id}/edit`}>
                <button className="btn btn-gray pull-right button-margin-right">Edit</button>
              </a>
            </td>
          </tr>
        </React.Fragment>
      )
    }
  }
}
